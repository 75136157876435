import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogWorkModalComponent } from '../log-work-modal/log-work-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Subscription, filter, first, switchMap } from 'rxjs';
import { AgreementToLogWork } from 'src/app/models/dtos/agreement-to-log-work';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service'; // Ensure this is correctly imported
import { WorkerService } from 'src/app/data/worker.service';

@Component({
  selector: 'app-log-work',
  templateUrl: './log-work.component.html',
  styleUrls: ['./log-work.component.scss'],
})
export class LogWorkComponent implements OnDestroy, OnInit {
  workerId: number;
  qrCode: string;
  private routeSub: Subscription;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private workerAgreementService: WorkerAgreementService,
    private workerService: WorkerService
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.qrCode = params['code'];
    });
  
    this.authService.userData$
      .pipe(
        filter(user => !!user.userData),
        first(),
        switchMap(user => this.workerService.getWorkerIdByAuthServerUserId(user.userData.authServerUserId)),
        switchMap(workerId => {
          this.workerId = workerId;
          return this.workerAgreementService.getActiveWorkerAgreementsByQrCode(this.workerId, this.qrCode);
        })
      )
      .subscribe(agreements => {
        if (agreements.length === 0) {
          this.redirectToTimesheets();
        } else {
          this.openLogWorkDialog(agreements);
        }
      });
  }

  openLogWorkDialog(agreements: AgreementToLogWork[]): void {
    const dialogRef = this.dialog.open(LogWorkModalComponent, {
      data: { workerId: this.workerId, qrCode: this.qrCode, agreements: agreements }, 
      width: 'auto',
      height: 'auto', 
      maxWidth: '800px',
    });

    const componentInstance = dialogRef.componentInstance;

    if (componentInstance) {
      componentInstance.backdropClicked.subscribe(() => {
        componentInstance.cancel();
      });
    }
  
    dialogRef.backdropClick().subscribe(() => {
      componentInstance?.emitBackdropClick();
    });

    dialogRef.afterClosed().subscribe((isSuccess) => {
      if (isSuccess) {
        this.redirectToTimesheets();
      }
      else {
        this.redirectToProfile();
      }
    });
  }

  redirectToTimesheets(): void {
    this.redirectTo(`profile/${this.workerId}/timesheets`);
  }

  redirectToProfile(): void {
    this.redirectTo(`profile/${this.workerId}`);
  }

  redirectTo(uri: string): void {
    this.router.navigate([uri]);
  }

  ngOnDestroy() {
    this.routeSub && this.routeSub.unsubscribe();
  }
}
