import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Country } from "src/app/models/enums";

export function driverLicenceNumberValidator(country: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (country === Country.Poland) {
      const validPattern = /^([A-Za-z]\d{6}[A-Za-z]?|[A-Za-z]{2}\d{6}|[A-Za-z]\d{7})$/;
      return validPattern.test(control.value) ? null : { invalidFormat: true };
    }
    return null;
  };
}
