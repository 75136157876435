import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, switchMap, takeUntil, tap, pairwise, map, startWith, filter, shareReplay } from 'rxjs/operators';
import { Permission } from 'src/app/common/enums/permissions';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { GenerateBankAccountNumberStatementRequest } from 'src/app/contracts/requests/generate-bank-account-number-statement-request';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerFormService } from 'src/app/data/worker-form.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { PhoneCode } from 'src/app/models/PhoneCode';
import { WorkerFormAdditionalData } from 'src/app/models/worker-form-additional-data';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { FirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { LastNameValidator } from 'src/app/shared/validators/last-name.validator';
import { GenerateBankAccountStatementModalComponent } from '../../worker-profile/documents-section/generate-bank-account-statement.component/generate-bank-account-statement.component';
import { Country } from 'src/app/models/enums';
import { QuestionnaireProcessStep } from 'src/app/common/enums';
import { DocumentDeliveryMethodEnum } from 'src/app/models/enums/document-delivery-method-enum';

@Component({
  selector: 'app-additional-data-step',
  templateUrl: './additional-data-step.component.html',
  styleUrls: ['./additional-data-step.component.scss'],
})
export class AdditionalDataStepComponent implements OnInit, OnDestroy, OnChanges {
  private readonly minimumInputLetters = 2;
  private readonly timeBetweenInput = 300;

  @Input() additionalDataFormGroup: UntypedFormGroup;
  @Input() isBankAccountRequired: boolean;
  @Input() stepper: MatStepper;
  @Input() workerFormAuthServerUserId: string;
  @Input() workerId: number = null;
  @Input() hasDeclarationAddressConfigEnabled: boolean;

  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef<HTMLInputElement>;

  listOfTaxOffices$: Observable<DictionaryItem[]> = null;
  listOfNationalHealthFunds$: Observable<DictionaryItem[]> = this.dictionaryService.getNationalHealthFunds();
  listOfPhoneCodes$: Observable<PhoneCode[]> = this.dictionaryService.getPhoneCodes();
  listOfDocumentDeliveryMethods$: Observable<DictionaryItem[]> = this.dictionaryService.getDocumentDeliveryMethods();
  listOfAddressTypes$: Observable<DictionaryItem[]>;

  private getAuthServerUserId = () => this.authService.getAuthServerUserId();

  workerFormId: number;
  bankAccountInfoChanged: boolean = false;
  private subject = new Subject<void>();

  private unsubscribe$ = new Subject<void>();

  private minLengthValidatorFn: ValidatorFn;
  private maxLengthValidatorFn: ValidatorFn;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private dictionaryService: DictionaryService,
    private workerFormService: WorkerFormService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private pdfViewerService: PdfViewerService,
  ) { }

  get nationalHealthFund() {
    return this.additionalDataFormGroup.get('nationalHealthFund') as UntypedFormControl;
  }
  get taxOffice() {
    return this.additionalDataFormGroup.get('taxOffice') as UntypedFormControl;
  }
  get bankAccountNumber() {
    return this.additionalDataFormGroup.get('bankAccountNumber') as UntypedFormControl;
  }
  get bankAccountOwnerFirstName() {
    return this.additionalDataFormGroup.get('bankAccountOwnerFirstName') as UntypedFormControl;
  }
  get bankAccountOwnerLastName() {
    return this.additionalDataFormGroup.get('bankAccountOwnerLastName') as UntypedFormControl;
  }
  get emergencyContactFirstName() {
    return this.additionalDataFormGroup.get('emergencyContactFirstName') as UntypedFormControl;
  }
  get emergencyContactLastName() {
    return this.additionalDataFormGroup.get('emergencyContactLastName') as UntypedFormControl;
  }
  get emergencyContactPhoneCodeId() {
    return this.additionalDataFormGroup.get('emergencyContactPhoneCodeId') as UntypedFormControl;
  }
  get emergencyContactPhone() {
    return this.additionalDataFormGroup.get('emergencyContactPhone') as UntypedFormControl;
  }
  get emergencyContactAddress() {
    return this.additionalDataFormGroup.get('emergencyContactAddress') as UntypedFormControl;
  }
  get isBankAccountDoesntBelongToWorker() {
    return this.additionalDataFormGroup.get('isBankAccountDoesntBelongToWorker') as UntypedFormControl;
  }
  get emailToSendDeclaration() {
    return this.additionalDataFormGroup.get('emailToSendDeclaration') as UntypedFormControl;
  }
  get declarationAddressType() {
    return this.additionalDataFormGroup.get('declarationAddressType') as UntypedFormControl;
  }
  get documentDeliveryMethod() {
    return this.additionalDataFormGroup.get('documentDeliveryMethod') as UntypedFormControl;
  }
  get addressToSendDeclarationAddressType() {
    return this.additionalDataFormGroup.get('addressToSendDeclarationAddressType') as UntypedFormControl;
  }
  get canEditBankAccountData() {
    return this.workerFormAuthServerUserId === this.getAuthServerUserId() || !this.isBankAccountRequired;
  }

  documentDeclarationMethods = DocumentDeliveryMethodEnum;

  async ngOnInit(): Promise<void> {
    this.workerFormId = +this.route.snapshot.params.id;
    const activeStep$ = this.stepper.selectionChange.asObservable().pipe(filter(res => res.selectedStep.label as any as number === QuestionnaireProcessStep.AdditionalData));

    this.listOfAddressTypes$ = activeStep$.pipe(switchMap(() => this.dictionaryService.getWorkerAddressTypes(this.workerId))).pipe(shareReplay());

    this.onNameChange();
    this.onTaxOfficeChange();
    this.onPhoneCodeChange();
    this.onBankAccountOwnerNameChange();
    this.onBankAccountInfoChanged();
    this.onDocumentDeliveryMethodChange();
    await this.setInitialValues();
  }

  ngOnChanges(): void {
    if (!!this.workerFormAuthServerUserId && !this.canEditBankAccountData) {
      this.disableBankAccountInputs();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submitAdditionalData(): void {
    if ((this.additionalDataFormGroup.invalid || this.additionalDataFormGroup.untouched) && !this.bankAccountInfoChanged) {
      this.documentDeliveryMethod.markAsTouched();
      this.stepper.next();
      return;
    }
    if (this.isBankAccountRequired && this.bankAccountInfoChanged && !!this.bankAccountNumber.value) {
      this.openBankAccountStatementDialog();
    } else {
      this.submit();
    }
  }

  submit(): void {
    const workerFormAdditionalData: WorkerFormAdditionalData = this.createAdditionalDataRequest();

    this.spinner.show();
    this.workerFormService
      .saveWorkerFormAdditionalData(workerFormAdditionalData)
      .pipe(
        first(),
        finalize(() => {
          this.spinner.hide();
          if (this.dialog.openDialogs.length === 0)
            this.stepper.next();
        }))
      .subscribe(
        (_) => {
          this.additionalDataFormGroup.markAsUntouched();
        },
        (err: HttpErrorResponse) => {
          this.stepper.previous();
          if (err.error === ErrorCode.BankAccountOwnerTheSameAsUser) {
            this.bankAccountOwnerFirstName.reset();
            this.bankAccountOwnerLastName.reset();
            this.additionalDataFormGroup.setErrors({ bankAccountOwnerFullNameInvalid: true }, { emitEvent: false });
          }
        },
      );
  }

  displayValue = (value: DictionaryItem): string | undefined => (value ? value.Name : undefined);

  isExternalWorker = (): boolean => this.authService.hasPermission(Permission.ManageAsAExternalWorker);

  onStatementSigned(statementId: number) {
    let firstName: string;
    let lastName: string;

    this.authService.getUser().subscribe((x) => {
      firstName = x.profile.firstName;
      lastName = x.profile.lastName;
    });

    this.pdfViewerService.show({
      Endpoint: `workers/${this.workerId}/files`,
      FileId: statementId,
      FileName: this.setFileName(`${firstName} ${lastName}`),
    });
  }

  private setFileName = (fullName: string): string => `Oświadczenie ${fullName}.pdf`;

  private onTaxOfficeChange() {
    this.listOfTaxOffices$ = this.taxOffice.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (this.taxOffice.value && value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getTaxOffices(value);
        } else {
          return of(null);
        }
      }),
    );
  }

  private onDocumentDeliveryMethodChange() {
    this.documentDeliveryMethod.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged())
      .subscribe(documentDeliveryMethodId => {
        if (documentDeliveryMethodId === DocumentDeliveryMethodEnum.PaperForm) {
          this.addressToSendDeclarationAddressType.enable();
          this.emailToSendDeclaration.disable();
        } else if (documentDeliveryMethodId === DocumentDeliveryMethodEnum.ElectronicForm) {
          this.addressToSendDeclarationAddressType.disable();
          this.emailToSendDeclaration.enable();
        } else {
          this.addressToSendDeclarationAddressType.disable();
          this.emailToSendDeclaration.disable();
        }
      });
  }

  private disableBankAccountInputs(): void {
    this.disableFormControl(this.bankAccountNumber);
    this.disableFormControl(this.isBankAccountDoesntBelongToWorker);
    this.disableFormControl(this.bankAccountOwnerFirstName);
    this.disableFormControl(this.bankAccountOwnerLastName);
  }

  private onNameChange(): void {
    combineLatest([
      this.emergencyContactFirstName.valueChanges.pipe(startWith(this.emergencyContactFirstName.value)),
      this.emergencyContactLastName.valueChanges.pipe(startWith(this.emergencyContactLastName.value))
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([firstName, lastName]) => {
        if (firstName || lastName) {
          this.emergencyContactPhoneCodeId.addValidators(Validators.required);
          this.emergencyContactPhone.addValidators(Validators.required);
        } else {
          this.emergencyContactPhoneCodeId.removeValidators(Validators.required);
          this.emergencyContactPhone.removeValidators(Validators.required);
        }
        this.emergencyContactPhoneCodeId.updateValueAndValidity();
        this.emergencyContactPhone.updateValueAndValidity();
      });
  }

  private onPhoneCodeChange(): void {
    combineLatest([
      this.emergencyContactPhoneCodeId.valueChanges.pipe(startWith(this.emergencyContactPhoneCodeId.value)),
      this.listOfPhoneCodes$])
      .pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$))
      .subscribe(([changedPhoneCode, phoneCodes]) => {
        const phoneCode = phoneCodes.find((phoneCode) => phoneCode.Id == changedPhoneCode);
        this.phoneNumberInput.nativeElement.maxLength = phoneCode?.MaxLength;

        this.emergencyContactPhone.removeValidators([
          this.minLengthValidatorFn,
          this.maxLengthValidatorFn,
        ]);

        this.minLengthValidatorFn = Validators.minLength(phoneCode?.MinLength);
        this.maxLengthValidatorFn = Validators.maxLength(phoneCode?.MaxLength);

        this.emergencyContactPhone.addValidators([
          this.minLengthValidatorFn,
          this.maxLengthValidatorFn,
        ]);
        this.emergencyContactPhone.updateValueAndValidity();
      });
  }

  private onBankAccountOwnerNameChange(): void {
    this.isBankAccountDoesntBelongToWorker.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value: boolean) => {
          if (value) {
            this.enableFormControl(this.bankAccountOwnerFirstName, [Validators.required, Validators.maxLength(50), FirstNameValidator()]);
            this.enableFormControl(this.bankAccountOwnerLastName, [Validators.required, Validators.maxLength(50), LastNameValidator()]);
          } else {
            this.disableAndResetFormControl(this.bankAccountOwnerFirstName);
            this.disableAndResetFormControl(this.bankAccountOwnerLastName);
          }
        }),
      )
      .subscribe();
  }

  private disableFormControl = (fc: UntypedFormControl) => {
    fc.clearValidators();
    fc.disable({ emitEvent: false });
  };

  private disableAndResetFormControl = (fc: UntypedFormControl) => {
    this.disableFormControl(fc);
    fc.reset();
  };

  private enableFormControl = (fc: UntypedFormControl, validators: any[]) => {
    fc.enable({ emitEvent: false });
    fc.setValidators(validators);
  };

  private createAdditionalDataRequest(): WorkerFormAdditionalData {
    const value = this.additionalDataFormGroup.getRawValue();

    return {
      WorkerFormId: this.workerFormId,
      NationalHealthFundId: value.nationalHealthFund,
      TaxOfficeId: value.taxOffice?.Id,
      TaxOfficeName: value.taxOffice?.Name,
      BankAccountNumber: value.bankAccountNumber?.replace(/\s/g, ''),
      BankAccountOwnerFirstName: value.bankAccountOwnerFirstName ? value.bankAccountOwnerFirstName : null,
      BankAccountOwnerLastName: value.bankAccountOwnerLastName ? value.bankAccountOwnerLastName : null,
      EmergencyContactFirstName: value.emergencyContactFirstName ? value.emergencyContactFirstName : null,
      EmergencyContactLastName: value.emergencyContactLastName ? value.emergencyContactLastName : null,
      EmergencyContactPhoneCodeId: value.emergencyContactPhoneCodeId,
      EmergencyContactPhone: value.emergencyContactPhone,
      EmergencyContactAddress: value.emergencyContactAddress,
      DeclarationAddressTypeId: this.hasDeclarationAddressConfigEnabled ? value.declarationAddressType : null,
      DocumentDeliveryMethodId: this.hasDeclarationAddressConfigEnabled ? value.documentDeliveryMethod : null,
      EmailToSendDeclaration: this.hasDeclarationAddressConfigEnabled && value.documentDeliveryMethod === DocumentDeliveryMethodEnum.ElectronicForm ? value.emailToSendDeclaration : null,
      AddressToSendDeclarationAddressTypeId: this.hasDeclarationAddressConfigEnabled && value.documentDeliveryMethod === DocumentDeliveryMethodEnum.PaperForm ? value.addressToSendDeclarationAddressType : null
    };
  }

  openBankAccountStatementDialog(): void {
    if (this.additionalDataFormGroup.invalid)
      return;

    const dialogRef = this.dialog.open(
      GenerateBankAccountStatementModalComponent,
      this.dialogConfig(<GenerateBankAccountNumberStatementRequest>{
        WorkerFormId: this.workerFormId,
        BankAccountNumber: this.bankAccountNumber.value,
        BankAccountOwnerFirstName: this.bankAccountOwnerFirstName.value,
        BankAccountOwnerLastName: this.bankAccountOwnerLastName.value,
      }),
    );

    dialogRef.afterClosed().subscribe((result: { isSuccess: boolean; statementId: number }) => {
      if (result?.isSuccess) {
        this.bankAccountInfoChanged = false;
        this.submit();
        this.subject.next();
        this.onStatementSigned(result.statementId);
        this.onDialogsClose();
      }
    });
  }

  onDialogsClose = () => this.dialog.afterAllClosed.subscribe(() => this.stepper.next());

  onBankAccountInfoChanged(): void {
    this.setBankAccountInfoChangedOnFormControlChange(this.bankAccountNumber);
    this.setBankAccountInfoChangedOnFormControlChange(this.bankAccountOwnerFirstName);
    this.setBankAccountInfoChangedOnFormControlChange(this.bankAccountOwnerLastName);
    this.setBankAccountInfoChangedOnFormControlChange(this.isBankAccountDoesntBelongToWorker);
  }

  setBankAccountInfoChangedOnFormControlChange(fc: UntypedFormControl): void {
    fc.valueChanges.pipe(pairwise(), debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$)).subscribe(([prev, next]) => {
      if (prev !== next) this.bankAccountInfoChanged = true;
    });
  }

  private dialogConfig = <Type>(data: Type): MatDialogConfig<Type> => {
    const dialogConfig = new MatDialogConfig<Type>();
    dialogConfig.data = data;
    return dialogConfig;
  };

  private async setInitialValues() {
    const defaultCode = await firstValueFrom(this.listOfPhoneCodes$.pipe(map(codes => codes.find(code => code.Id === Country.Poland))));
    if (defaultCode && !this.emergencyContactPhoneCodeId.value) {
      this.emergencyContactPhoneCodeId.setValue(defaultCode.Id);
    }
  }
}
