<div *ngIf="!!qrCodeUrl" class="qrcode-container">
  <div class="logo">
    <img src="assets/images/logos/TalentPoint_RGB.png" alt="logo" class="logo-image" />
  </div>
  <div class="qrcode-info">
    {{ 'QrCodeInformation' | translate }}
  </div>
  <qrcode [qrdata]="qrCodeUrl" [width]="size" [errorCorrectionLevel]="level"></qrcode>
  <div *ngIf="timeLeft > 0" class="progress-bar-container">
    <div class="progress-bar" [style.width.%]="loadingProgress"></div>
  </div>
  <span *ngIf="!isProduction()" class="qrcode-text">{{ qrCodeUrl }}</span>
</div>
