<div class="content-card filters">
  <div class="top">
    <div class="top__left">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button mat-icon-button (click)="previousDay()">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <span class="date-label" (click)="picker.open()">
            {{ currentDate | date : 'dd.MM.yyyy' }}
          </span>
          <button mat-icon-button (click)="nextDay()">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <div class="datepicker">
            <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)" readonly />
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="top__right">
      <mat-form-field *ngIf="showFilterPresetSelector" [@inOutAnimation] appearance="outline" class="filter-preset-selector">
        <mat-label>Filtr</mat-label>
        <mat-select [value]="selectedFilterPresetId" (valueChange)="setSelectedFilterPresetId($event)">
          <ng-container *ngFor="let preset of filterPresets">
            <mat-option [value]="preset.Id">{{
              preset.Name === defaultFilterPresetName ? ('DefaultFilterPresetName' | translate) : preset.Name
            }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <button (click)="resetFilters()" class="top__button top__button-clear">
        <mat-icon>close</mat-icon>
        {{ 'Reset' | translate }}
      </button>
      <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
        <mat-icon>filter_alt</mat-icon>
        {{ 'Filter' | translate }}
      </button>
    </div>
  </div>
  <app-presence-per-day-list-filters *ngIf="areFiltersExpanded" [formGroup]="filtersFormGroup" (filterGridData)="filterData($event)">
    <div class="favorite-button-row-container">
      <button (click)="saveFilterPresetButtonClick()" class="favorite-button">
        <ng-container *ngIf="isSavedFilterPreset; then unsaveBtn; else saveBtn"></ng-container>
        <ng-template #saveBtn>
          <mat-icon>bookmark_border</mat-icon>
          {{ 'SaveFilter' | translate }}
        </ng-template>
        <ng-template #unsaveBtn>
          <mat-icon>bookmark</mat-icon>
          {{ 'UnsaveFilter' | translate }}
        </ng-template>
      </button>
    </div>
  </app-presence-per-day-list-filters>
</div>

<div class="content-card">
  <div class="table-wrapper custom-table">
    <mat-table #table matSort matSortActive="company" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource.presences$ | async">
      <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'Company' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.Company" [matTooltipPosition]="'above'">{{ element.Company }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="employerObject">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'EmployerObject' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.EmployerObject" [matTooltipPosition]="'above'">{{
          element.EmployerObject
        }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="workerFullName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="LastName" class="hidden-sort">
          {{ 'PresencePerDayList.WorkerFullName' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><a [routerLink]="['/profile', element.WorkerId, 'timesheets']">{{ element.LastName }} {{ element.FirstName }}</a></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef class="hidden-sort">
          {{ 'PresencePerDayList.StartTime' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.Periods" class="period-wrapper">
            <span class="work-mode-wrapper">{{ item.StartDate | date : 'HH:mm' }}</span>
            <span *ngFor="let workMode of item.WorkModes; let first = first">
              <span *ngIf="!first" class="work-mode-wrapper"></span>
            </span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef class="hidden-sort">
          {{ 'PresencePerDayList.EndTime' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.Periods" class="period-wrapper">
            <span class="work-mode-wrapper">{{ item.EndDate | date : 'HH:mm' }}</span>
            <span *ngFor="let workMode of item.WorkModes; let first = first">
              <span *ngIf="!first" class="work-mode-wrapper"></span>
            </span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalTimespan">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
          {{ 'PresencePerDayList.TotalTimespan' | translate }}
          <app-table-header-sort></app-table-header-sort>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.TotalTimespan ? (element.TotalTimespan | ticksToString) : '' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="workModes">
        <mat-header-cell *matHeaderCellDef class="hidden-sort">
          {{ 'PresencePerDayList.WorkMode' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.Periods" class="period-wrapper">
            <span *ngFor="let workMode of item.WorkModes" class="work-mode-wrapper">
              {{ workMode.NameLang }}
            </span>
            <span *ngIf="!item.WorkModes.length" class="work-mode-wrapper"></span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="absenceType">
        <mat-header-cell *matHeaderCellDef class="hidden-sort">
          {{ 'PresencePerDayList.Absence' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.AbsenceTypes">{{ item }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [length]="dataSource.count$ | async" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
  </div>
</div>
