import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateOrUpdateTimesheetRecordRequest } from '../contracts/requests/CreateOrUpdateTimesheetRecordRequest';
import { DeleteTimesheetRecordRequest } from '../contracts/requests/DeleteTimesheetRecordRequest';
import { GetTimesheetRecordsForSpecificMonthResponse, InvalidTimesheetsResponse } from '../contracts/responses/get-timesheet-records-for-specific-month-reponse';
import { ApiResult } from '../models/ApiResult';
import { WorkerMonthlyTimesheetToBeSettledDto } from '../models/dtos/worker-monthly-timesheet-to-be-settled-dto';
import { WorkerMonthlyTimesheetDto } from '../models/dtos/worker-monthly-timesheet-dto';
import { TimesheetRecord } from '../models/TimesheetRecord';
import { IPagedResult } from '../shared/models/PagedResult';
import { Filter } from '../models/common/filter';
import { WorkModeDto } from '../models/dtos/work-mode-dto';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { SettlementDetailsDto } from '../models/dtos/settlement-details-dto';
import { CachedHttpClient } from '../common/utils';
import { TimesheetsConfigDto } from '../models/dtos/timesheets-config-dto';
import { LogWorkRequest } from '../contracts/requests/log-work.request';

@Injectable({
  providedIn: 'root',
})
export class TimesheetService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly timesheetsPath = '/timesheets';

  private get timesheetsUrl() {
    return this.API_URL + this.timesheetsPath;
  }

  constructor(
    private http: HttpClient,
    private cachedHttp: CachedHttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  createTimesheetRecord(request: CreateOrUpdateTimesheetRecordRequest): Observable<ApiResult<TimesheetRecord>[]> {
    const url = `${this.timesheetsUrl}/timesheetRecords`;
    return this.http.post<ApiResult<TimesheetRecord>[]>(url, request);
  }

  deleteTimesheetRecord(request: DeleteTimesheetRecordRequest): Observable<any> {
    const url = `${this.timesheetsUrl}/timesheetRecords`;
    const httpParams = new HttpParams()
      .set('timesheetRecordId', request.TimesheetRecordId.toString())
      .set('workerId', request.WorkerId.toString())
      .set('workerAgreementId', request.WorkerAgreementId.toString());

    return this.http.delete<any>(url, { params: httpParams });
  }

  getTimesheetRecordsForSpecificMonth(agreementId: number = 0, month: number, year: number): Observable<GetTimesheetRecordsForSpecificMonthResponse> {
    const url = `${this.timesheetsUrl}/getTimesheetRecordsForSpecificMonth`;

    const httpParams = new HttpParams().set('agreementId', agreementId.toString()).set('month', month.toString()).set('year', year.toString());

    return this.http
      .get<GetTimesheetRecordsForSpecificMonthResponse>(url, {
        params: httpParams,
      })
      .pipe(
        map(
          (res) =>
            <GetTimesheetRecordsForSpecificMonthResponse>{
              ...res,
              TimesheetRecords: res.TimesheetRecords.map((t) => ({
                ...t,
                Date: t.Date && new Date(t.Date + 'Z'),
                StartDate: t.StartDate && new Date(t.StartDate + 'Z'),
                EndDate: t.EndDate && new Date(t.EndDate + 'Z'),
              })),
            },
        ),
      );
  }

  updateTimesheetRecord(request: CreateOrUpdateTimesheetRecordRequest): Observable<ApiResult<any>[]> {
    const url = `${this.timesheetsUrl}/updateTimesheetRecords`;
    return this.http.post<ApiResult<any>[]>(url, request);
  }

  unapprove(timesheetId: number): Observable<ApiResult<any>> {
    const url = `${this.timesheetsUrl}/unapprove/` + timesheetId;
    return this.http.post<ApiResult<any>>(url, null);
  }

  approveAsWorker(workerId: number, workerAgreementId: number, year: number, month: number): Observable<ApiResult<any>> {
    let httpParams = new HttpParams()
      .set('workerId', workerId.toString())
      .set('workerAgreementId', workerAgreementId.toString())
      .set('year', year.toString())
      .set('month', month.toString());

    const url = `${this.timesheetsUrl}/approveAsWorker`;
    return this.http.post<ApiResult<any>>(url, null, { params: httpParams });
  }

  approveAsExternalWorker(workerId: number, workerAgreementId: number, year: number, month: number): Observable<ApiResult<any>> {
    let httpParams = new HttpParams()
      .set('workerId', workerId.toString())
      .set('workerAgreementId', workerAgreementId.toString())
      .set('year', year.toString())
      .set('month', month.toString());

    const url = `${this.timesheetsUrl}/approveAsExternalWorker`;
    return this.http.post<ApiResult<any>>(url, null, { params: httpParams });
  }

  approveAsInternalWorker(workerId: number, workerAgreementId: number, year: number, month: number): Observable<ApiResult<any>> {
    let httpParams = new HttpParams()
      .set('workerId', workerId.toString())
      .set('workerAgreementId', workerAgreementId.toString())
      .set('year', year.toString())
      .set('month', month.toString());

    const url = `${this.timesheetsUrl}/approveAsInternalWorker`;
    return this.http.post<ApiResult<any>>(url, null, { params: httpParams });
  }

  getWorkersMonthlyTimesheets(
    month: number,
    year: number,
    page: number,
    count: number,
    workersTimesheetsListTypeId: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<WorkerMonthlyTimesheetDto>> {
    const url = `${this.timesheetsUrl}/monthlyTimesheets`;

    let params = new HttpParams()
      .set('month', month.toString())
      .set('year', year.toString())
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('workersTimesheetsListTypeId', workersTimesheetsListTypeId.toString())
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<WorkerMonthlyTimesheetDto>>(url, httpOptions);
  }

  getWorkersMonthlyTimesheetsToBeSettled(
    month: number,
    year: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<WorkerMonthlyTimesheetToBeSettledDto>> {
    const url = `${this.timesheetsUrl}/monthlyTimesheetsToBeSettled`;

    let params = new HttpParams()
      .set('month', month.toString())
      .set('year', year.toString())
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const httpOptions = {
      params: params,
    };

    return this.http.get<IPagedResult<WorkerMonthlyTimesheetToBeSettledDto>>(url, httpOptions);
  }

  setBonus(timesheetId: number, bonus: number): Observable<any> {
    const url = `${this.timesheetsUrl}/${timesheetId}/setBonus`;
    const params = new HttpParams().set('bonus', (+bonus).toString());

    return this.http.post<any>(url, null, {
      params: params,
    });
  }

  settle(timesheetId: number): Observable<any> {
    const url = `${this.timesheetsUrl}/settle/`+ timesheetId;
    return this.http.post<any>(url, null);
  }

  settleAll(timesheetIds: number[]): Observable<InvalidTimesheetsResponse> {
    const url = `${this.timesheetsUrl}/settleAll`;
    return this.http.post<any>(url, timesheetIds);
  }

  getWorkModes(agreementId: number = 0): Observable<WorkModeDto[]> {
    const url = `${this.timesheetsUrl}/workModes`;
    const httpParams = new HttpParams().set('agreementId', agreementId.toString());

    return this.http.get<WorkModeDto[]>(url, {
      params: httpParams,
    });
  }

  getHomeOfficeOnDemandLimit(agreementId: number, year: number): Observable<number> {
    const url = `${this.timesheetsUrl}/homeOfficeOnDemandLimit`;
    const params = new HttpParams()
      .set('agreementId', agreementId)
      .set('year', year);

    return this.http.get<number>(url, { params });
  }

  getPermissionToManageAnyDay(agreementId: number): Observable<boolean> {
    const url = `${this.timesheetsUrl}/hasPermissionManageAnyDay`;
    const params = new HttpParams()
      .set('agreementId', agreementId);

    return this.http.get<boolean>(url, { params });
  }

  getSettlementDetails(timesheetId: number): Observable<SettlementDetailsDto> {
    const url = `${this.timesheetsUrl}/${timesheetId}/settlementDetails`;
    return this.http.get<SettlementDetailsDto>(url);
  }
  
  getTimesheetsConfig(): Observable<TimesheetsConfigDto> {
    const url = `${this.timesheetsPath}/timesheetsConfig`;
    return this.cachedHttp.get<TimesheetsConfigDto>(url);
  }

  createLogWorkEntry(request: LogWorkRequest): Observable<ApiResult<any>> {
    const url = `${this.timesheetsUrl}/logWork`;
    return this.http.post<ApiResult<any>>(url, request);
  }

  updateLogWorkEntry(request: LogWorkRequest): Observable<ApiResult<any>> {
    const url = `${this.timesheetsUrl}/logWork`;
    return this.http.put<ApiResult<any>>(url, request);
  }
}
