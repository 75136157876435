<!-- Confirmation view similar to the uploaded image -->
<form [formGroup]="formGroup">
  <div class="container">
    <h2 class="employer-name">{{ employerName }}</h2>
  </div>

  <div class="log-work-form container">
    <mat-form-field appearance="outline" class="log-work-select">
      <mat-label>{{ 'DF-Agreement' | translate }}</mat-label>
      <mat-select #matSelect formControlName="activeAgreement">
        <mat-option *ngFor="let agreement of data.agreements" [value]="agreement.Id"
          >{{ agreement.EmploymentDateFrom | date : 'yyyy-MM-dd' }} - {{ agreement.EmploymentDateTo | date : 'yyyy-MM-dd' }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="container">
    <p [innerHTML]="registrationText"></p>
  </div>
  <div class="container">
    <button [disabled]="!selectedAgreement" mat-raised-button color="primary" class="confirmation-button" (click)="submit(false, true)">
      {{ 'LogWork.ConfirmRegistration' | translate }}
    </button>
    <p class="auto-confirm" [innerHTML]="countdownText"></p>
  </div>
  <div class="container">
    <p>{{ 'LogWork.ChangeRegistrationInfo' | translate }}</p>
  </div>
  <div class="container">
    <button [disabled]="!selectedAgreement" mat-raised-button color="accent" class="confirmation-button" (click)="submit(true, true)">
      {{ 'LogWork.ChangeRegistration' | translate }}
    </button>
  </div>
  <div class="container">
    <button mat-raised-button color="accent" class="confirmation-button" (click)="cancel()">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</form>
