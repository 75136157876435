import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';
import { Filter } from 'src/app/models/common/filter';
import { ExternalWorkerGridItemDto } from 'src/app/models/dtos/external-worker-grid-item-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';
import { GridTableDataSource } from 'src/app/virtual-scroll/data-source';

export class WorkersListDataSource extends GridTableDataSource<ExternalWorkerGridItemDto> {
  isLoading$ = new BehaviorSubject<boolean>(false);
  private cancelationSubject = new Subject();
  private countSubject = new BehaviorSubject<number>(0);
  private _absoluteDataLength: number;

  count$ = this.countSubject.asObservable();

  get isLoading() {
    return this.isLoading$.getValue();
  }

  get absoluteDataLength() {
    return this._absoluteDataLength;
  }

  constructor(
    private workerService: WorkerService,
    { viewport }: { viewport?: CdkVirtualScrollViewport } = {}
  ) {
    super([], { viewport });
  }

  disconnect() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
  }

  reset() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
    this.allData = [];
  }

  fetchWorkers(page: number, count: number, sortingField: string, sortingDirection: string, filters: Filter[]) {
    this.isLoading$.next(true);
    this.workerService
      .getAllExternalWorkers(page, count, sortingField, sortingDirection, filters)
      .pipe(
        takeUntil(this.cancelationSubject),
        catchError(() => of([])),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response: IPagedResult<ExternalWorkerGridItemDto>) => {
        this._absoluteDataLength = response.Count;
        this.merge(response.Results, page, count);
        this.countSubject.next(response.Count);
      });
  }

  private merge(data: ExternalWorkerGridItemDto[], page: number, count: number) {
    const tmp = this.allData.filter((item, index) => {
      return (index < count * (page - 1) || index >= count * page) || data.some(incoming => incoming.WorkerId === item.WorkerId)
    });

    data?.map(item => {
      const index = tmp.findIndex(td => td.WorkerId === item.WorkerId)
      if (index > -1) {
        tmp[index] = item;
      } else {
        const index = count * (page - 1) + data.indexOf(item);

        if (index > -1) {
          tmp.splice(index, 0, item);
        } else {
          tmp.push(item);
        }
      }
    },
      (err: any) => console.log(err));

    this.allData = tmp;
  }
}
