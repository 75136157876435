import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { Filter } from 'src/app/models/common/filter';
import { MandateAgreementWorkedDayGridDto } from 'src/app/models/dtos/mandate-agreement-worked-day-grid-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';
import { GridTableDataSource } from 'src/app/virtual-scroll/data-source';

export class MandateAgreementWorkedDayListDataSource extends GridTableDataSource<MandateAgreementWorkedDayGridDto> {
  isLoading$ = new BehaviorSubject<boolean>(false);
  selection = new SelectionModel<MandateAgreementWorkedDayGridDto>(true, []);
  private cancelationSubject = new Subject();
  private countSubject = new BehaviorSubject<number>(0);
  private _absoluteDataLength: number;

  count$ = this.countSubject.asObservable();

  get isLoading() {
    return this.isLoading$.getValue();
  }

  get absoluteDataLength() {
    return this._absoluteDataLength;
  }

  public get isAllSelected() {
    return this.allData?.length && this.selection.selected?.length === this.allData.length;
  }

  public isSelected(item: MandateAgreementWorkedDayGridDto): boolean {
    return this.selection.isSelected(item);
  }

  public selectAll(): void {
    this.selection.select(...this.allData);
  }

  public deselectAll(): void {
    this.selection.clear();
  }

  constructor(
    private service: WorkerAgreementService,
    { viewport }: { viewport?: CdkVirtualScrollViewport } = {}
  ) {
    super([], { viewport });
  }

  disconnect() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
  }

  reset() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
    this.deselectAll();
    this.allData = [];
  }

  fetch(page: number, count: number, sortingField: string, sortingDirection: string, filters: Filter[]) {
    this.isLoading$.next(true);
    this.service
      .getAllMandateAgreementWorkedDayDocuments(page, count, sortingField, sortingDirection, filters)
      .pipe(
        takeUntil(this.cancelationSubject),
        catchError(() => of([])),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response: IPagedResult<MandateAgreementWorkedDayGridDto>) => {
        this._absoluteDataLength = response.Count;
        const isAllSelected = this.isAllSelected;
        this.merge(response.Results);
        this.keepSelectionState(isAllSelected);
        this.countSubject.next(response.Count);
      });
  }

  private keepSelectionState(isAllSelected: boolean) {
    let old = [...this.selection.selected];
    this.selection.clear();
    this.allData?.forEach((a) => (isAllSelected || old.some((r) => r.Id === a.Id)) && this.selection.select(a));
  };

  private merge(data: MandateAgreementWorkedDayGridDto[]) {
    const tmp = this.allData;
    data?.map(item => {
      const index = tmp.findIndex(td => td.Id === item.Id)
      if (index > -1) {
        tmp[index] = item;
      } else {
        tmp.push(item);
      }
    },
      (err: any) => console.log(err));

    this.allData = tmp;
  }
}
