<ng-container *ngIf="!(isAuthenticated$ | async)">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="(isAuthenticated$ | async) && isQrCodesAccount(); else app">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #app>
  <ng-container *ngIf="isAuthenticated$ | async">
    <mat-toolbar class="navbar">
      <ng-container *ngIf="isDesktopView">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <img [src]="'assets/images/home/' + bankHolidayRelPath + 'Talent-Point-logo-color-RGB.jpg'" alt="Logo" class="navbar__logo" />
      </ng-container>

      <div class="navbar__elements">
        <ng-container>
          <div class="navbar__element" *ngIf="isDesktopView">
            <app-navbar-choose-language></app-navbar-choose-language>
          </div>
          <div class="navbar__element">
            <app-navbar-info></app-navbar-info>
          </div>
          <div class="navbar__element navbar__element--menu">
            <app-navbar-user-menu
              [firstName]="(user | async)?.firstName"
              [lastName]="(user | async)?.lastName"
              [lastLoginDate]="(user | async)?.lastLoginDate"
              [lastFailedLogonAttemptDate]="(user | async)?.lastFailedLogonAttemptDate"
            ></app-navbar-user-menu>
          </div>
        </ng-container>
        <button mat-icon-button (click)="sidenav.toggle()" class="navbar__toggle">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <mat-sidenav-container>
      <mat-sidenav #sidenav role="navigation" [opened]="isDesktopView" [mode]="isDesktopView ? 'side' : 'over'">
        <div class="sidebar">
          <img src="assets/images/home/Talent-Point-logo-color-RGB.jpg" alt="Logo" class="sidebar__logo" *ngIf="!isDesktopView" />

          <ul class="sidebar__list">
            <ng-container *ngIf="!(isAuthenticated$ | async)">
              <li class="sidebar__item">
                <a class="sidebar__item-link" (click)="register()">
                  <mat-icon class="icon">meeting_room</mat-icon>
                  <span class="label" *ngIf="showLinkLabel">{{ 'ScheduleADemo' | translate }}</span>
                </a>
              </li>
            </ng-container>
            <li [visibleWithPermissions]="['ManageAllInternalWorkers']" class="sidebar__item">
              <a
                class="sidebar__item-link"
                routerLink="/users/add"
                routerLinkActive="sidebar__item-link--active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <mat-icon class="icon">person_add</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel">{{ 'UF-AddUser' | translate }}</span>
              </a>
            </li>

            <li [visibleWithPermissions]="['ManageAllInternalWorkers']" class="sidebar__item">
              <a
                class="sidebar__item-link"
                routerLink="/users"
                routerLinkActive="sidebar__item-link--active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <mat-icon class="icon">people</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel">{{ 'InternalWorkers' | translate }}</span>
              </a>
            </li>

            <li [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']" class="sidebar__item">
              <a class="sidebar__item-link" routerLink="/addWorker" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">person_add</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel">{{ 'AW-AddWorker' | translate }}</span>
              </a>
            </li>

            <li [visibleWithPermissions]="['ViewWorkersList']" class="sidebar__item">
              <a class="sidebar__item-link" routerLink="/workers" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">supervised_user_circle</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'WorkersList' | translate }}</span>
              </a>
            </li>

            <li [visibleWithPermissions]="['ViewWorkerAgreementsList', 'Supervisor']" class="sidebar__item">
              <a class="sidebar__item-link" routerLink="/workerAgreements" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">description</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'WL-WorkerAgreementList' | translate }}</span>
              </a>
            </li>

            <li *hasPermissionToModule="moduleNames.Timesheets" [visibleWithPermissions]="['ViewTimesheetsList', 'Supervisor']" class="sidebar__item">
              <a class="sidebar__item-link" routerLink="/workers/timesheets" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">timer</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'WTL-WorkerTimesheetsGrid' | translate }}</span>
              </a>
            </li>

            <li
              *hasPermissionToModule="moduleNames.Absences"
              [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList', 'ViewAbsencesList', 'Supervisor']"
              class="sidebar__item"
            >
              <a class="sidebar__item-link" routerLink="/absences" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">event</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'WTL-Absences' | translate }}</span>
              </a>
            </li>

            <li
              *hasPermissionToModule="moduleNames.Presences"
              [visibleWithPermissions]="[
                'ViewAllPresencesPerDay',
                'ViewMyExternalWorkerPresencesPerDay',
                'ViewAllPresencesPerMonth',
                'ViewMyExternalWorkerPresencesPerMonth',
                'Supervisor'
              ]"
              class="sidebar__item"
            >
              <a class="sidebar__item-link" routerLink="/presences" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">event</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel">{{ 'WTL-Presences' | translate }}</span>
              </a>
            </li>

            <li [visibleWithPermissions]="['ShowAllLegalizations']" class="sidebar__item">
              <a class="sidebar__item-link" routerLink="/legalizations" routerLinkActive="sidebar__item-link--active">
                <mat-icon class="icon">event</mat-icon>
                <span class="label" [class.label--collapsed]="showLinkLabel">{{ 'Legalization.Register.Legalizations' | translate }}</span>
              </a>
            </li>

            <ng-container *hasPermissionToModule="moduleNames.Declarations">
              <li [visibleWithPermissions]="['ShowAllDeclarations', 'ShowEmployerObjectDeclarations']" class="sidebar__item">
                <a class="sidebar__item-link" routerLink="/declarations" routerLinkActive="sidebar__item-link--active">
                  <mat-icon class="icon">insert_drive_file</mat-icon>
                  <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'DeclarationsList.DeclarationsList' | translate }}</span>
                </a>
              </li>
            </ng-container>

            <ng-container *hasPermissionToModule="moduleNames.Delegations">
              <li
                [visibleWithPermissions]="['ManageWorkerDelegations', 'HRDelegationManagement', 'AccountingDelegationManagement', 'Supervisor']"
                class="sidebar__item"
              >
                <a class="sidebar__item-link" routerLink="/delegations" routerLinkActive="sidebar__item-link--active">
                  <mat-icon class="icon">event</mat-icon>
                  <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'WTL-Delegations' | translate }}</span>
                </a>
              </li>
            </ng-container>

            <ng-container>
              <li [visibleWithPermissions]="['Talentina']">
                <a class="sidebar__item-link" routerLink="/talentina" routerLinkActive="sidebar__item-link--active">
                  <img src="/assets/icons/talentina.png" width="30" height="30" />
                  <span class="label" [class.label--collapsed]="showLinkLabel">Talentina</span>
                </a>
              </li>
            </ng-container>

            <ng-container *isEmployee>
              <div class="employee-menu">
                <li class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/contactInfo'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon" svgIcon="files-icon"></mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Files' | translate }}</span>
                  </a>
                </li>
                <li class="sidebar__item">
                  <a
                    class="sidebar__item-link"
                    [routerLink]="'/profile/' + workerId + '/workerAgreements'"
                    routerLinkActive="sidebar__item-link--active"
                  >
                    <mat-icon class="icon" svgIcon="agreements-icon"></mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Agreements' | translate }}</span>
                  </a>
                </li>
                <li *hasPermissionToModule="moduleNames.Timesheets" class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/timesheets'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon" svgIcon="timesheet-icon"></mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Timesheet' | translate }}</span>
                  </a>
                </li>

                <li class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/documents'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon" svgIcon="documents-icon"></mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Documents' | translate }}</span>
                  </a>
                </li>
                <li *hasPermissionToModule="moduleNames.Delegations" class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/delegations'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon" svgIcon="delegations-icon"></mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Delegations' | translate }}</span>
                  </a>
                </li>
                <li *hasPermissionToModule="moduleNames.Absences" class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/absences'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon" svgIcon="absences-icon"></mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Absences' | translate }}</span>
                  </a>
                </li>
                <li *hasPermissionToModule="moduleNames.Declarations" class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/declarations'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon">insert_drive_file</mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-Declarations' | translate }}</span>
                  </a>
                </li>
                <li *hasPermissionToModule="moduleNames.Declarations" class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/profile/' + workerId + '/declarations'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon">account_balance</mat-icon>
                    <span class="label" [class.label--collapsed]="showLinkLabel"> {{ 'ESM-YourPit11' | translate }}</span>
                  </a>
                </li>
                <li *ngIf="hasAccessToRegulationsAndProcedures" class="sidebar__item">
                  <a class="sidebar__item-link" [routerLink]="'/regulationsandprocedures'" routerLinkActive="sidebar__item-link--active">
                    <mat-icon class="icon">wysiwyg</mat-icon>
                    <span class="label">{{ 'RegulationsAndProcedures.Title' | translate }}</span>
                  </a>
                </li>
              </div>
            </ng-container>
            <!-- <li class="sidebar__item">
                  <a class="sidebar__item-link" (click)="getExpirationDate()">
                    <mat-icon class="icon">login</mat-icon>
                    <span class="label">{{ 'Data wygaśnięcia tokenu' | translate }}</span>
                  </a>
                </li> -->
            <li class="sidebar__item" *ngIf="!isDesktopView">
              <app-navbar-choose-language></app-navbar-choose-language>
            </li>
          </ul>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <main>
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</ng-template>

<ngx-spinner id="pacman" type="pacman"></ngx-spinner>
