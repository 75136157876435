import { ValidatorFn, Validators } from '@angular/forms';
import { minDateValidator } from 'src/app/shared/validators/min-date.validator';
import { IdentityDocumentTypeEnum } from '../enums/IdentityDocumentTypeEnum';

interface IdentityDocumentValidationProperties {
  serialValidation: ValidatorFn[];
  numberValidation: ValidatorFn[];
  expirationDateValidation: ValidatorFn[];
  serialLength?: number;
  numberLength?: number;
}

export class IdentityDocumentConstants {
  static getDocumentValidatorsMap(): Map<IdentityDocumentTypeEnum, IdentityDocumentValidationProperties> {
    const defaultSerialLength = 4;
    const defaultNumberLength = 6;

    return new Map<IdentityDocumentTypeEnum, IdentityDocumentValidationProperties>([
      [
        IdentityDocumentTypeEnum.Undefined,
        {
          serialValidation: [],
          numberValidation: [],
          expirationDateValidation: [],
          serialLength: defaultSerialLength,
          numberLength: defaultNumberLength,
        },
      ],
      [
        IdentityDocumentTypeEnum.Passport,
        {
          serialValidation: [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.maxLength(8)],
          numberValidation: [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.maxLength(8)],
          expirationDateValidation: [Validators.required, minDateValidator(new Date())],
          serialLength: 8,
          numberLength: 8,
        },
      ],
    ]);
  }
}
