<div class="workers-list container">
  <h1 class="header">{{ 'WorkersList' | translate }}</h1>

  <div class="content">
    <div class="content-card filters">
      <div class="top">
        <div class="top__right">
          <mat-form-field *ngIf="showFilterPresetSelector" [@inOutAnimation] appearance="outline" class="filter-preset-selector">
            <mat-label>Filtr</mat-label>
            <mat-select [value]="selectedFilterPresetId" (valueChange)="setSelectedFilterPresetId($event)">
              <ng-container *ngFor="let preset of filterPresets">
                <mat-option [value]="preset.Id">{{
                  preset.Name === defaultFilterPresetName ? ('DefaultFilterPresetName' | translate) : preset.Name
                }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <a
            [routerLink]="['/addWorker']"
            [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList', 'CreateExternalWorker']"
            mat-raised-button
            color="primary"
            class="add-worker-button"
            >{{ 'WL-CreateWorkerButton' | translate }}</a
          >
          <button (click)="resetFilters()" class="top__button top__button-clear">
            <mat-icon>close</mat-icon>
            {{ 'Reset' | translate }}
          </button>
          <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
            <mat-icon>filter_alt</mat-icon>
            {{ 'Filter' | translate }}
          </button>
        </div>
      </div>
      <app-workers-list-filters *ngIf="areFiltersExpanded" [formGroup]="filtersFormGroup" (filterGridData)="filterData($event)">
        <div class="favorite-button-row-container">
          <button (click)="saveFilterPresetButtonClick()" class="favorite-button">
            <ng-container *ngIf="isSavedFilterPreset; then unsaveBtn; else saveBtn"></ng-container>
            <ng-template #saveBtn>
              <mat-icon>bookmark_border</mat-icon>
              {{ 'SaveFilter' | translate }}
            </ng-template>
            <ng-template #unsaveBtn>
              <mat-icon>bookmark</mat-icon>
              {{ 'UnsaveFilter' | translate }}
            </ng-template>
          </button>
        </div>
      </app-workers-list-filters>
    </div>

    <div class="content-card" [ngClass]="{ 'filters-opened': areFiltersExpanded }">
      <div #tableContainer class="wrapper custom-table">
        <cdk-virtual-scroll-viewport gridTableVirtualScroll (scrolledIndexChange)="nextBatch($event)">
          <mat-table
            class="table"
            matSort
            [matSortActive]="defaultSortColumn"
            [matSortDirection]="defaultSortDirection"
            matSortDisableClear
            [dataSource]="dataSource"
            (matSortChange)="onSortChange()"
            [trackBy]="trackBy"
          >
            <ng-container matColumnDef="FirstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'FirstName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a
                  class="user"
                  [routerLink]="['/profile/' + element.WorkerId]"
                  [matTooltip]="'WL-ShowWokerProfile' | translate"
                  matTooltipPosition="above"
                  >{{ element.FirstName }}
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'LastName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a
                  class="user"
                  [routerLink]="['/profile/' + element.WorkerId]"
                  [matTooltip]="'WL-ShowWokerProfile' | translate"
                  matTooltipPosition="above"
                  >{{ element.LastName }}
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Citizenship">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'Citizenship' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.Citizenship">{{ element.Citizenship }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Pesel">
              <mat-header-cell *matHeaderCellDef mat-sort-header="DateOfBirth" class="hidden-sort">
                {{ 'Pesel' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.Pesel">{{ element.Pesel }}</span></mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="Email">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'Email' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.Email">{{ element.Email }}</span></mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="PhoneNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'PhoneNumber' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.PhoneNumber">{{ element.PhoneNumber }}</span></mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="WorkerStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'Status' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="label"
                  [class.label--green]="element.WorkerStatusId === workerStatusEnum.Activated"
                  [class.label--orange]="element.WorkerStatusId === workerStatusEnum.ActivationInProgress"
                  [class.label--yellow]="element.WorkerStatusId === workerStatusEnum.Created"
                >
                  <span class="label__dot"></span>
                  <span class="label__name">{{ element.WorkerStatus }}</span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div fxLayout="row">
                  <ng-container *ifHasPermissions="['ManageMyExternalWorkersList', 'ViewAllExternalWorkers']">
                    <button
                      [routerLink]="['/profile/' + element.WorkerId]"
                      matTooltip="{{ 'WL-ShowWokerProfile' | translate }}"
                      mat-icon-button
                      matTooltipPosition="above"
                    >
                      <mat-icon class="material-icons-outlined">visibility</mat-icon>
                    </button>
                  </ng-container>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="progress">
              <mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
                <mat-progress-bar [mode]="dataSource.isLoading ? 'indeterminate' : 'determinate'" class="table-progress" color="accent">
                </mat-progress-bar>
              </mat-header-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky" [style.top.px]="offset$ | async"></mat-header-row>
            <mat-header-row *matHeaderRowDef="['progress']; sticky: sticky" class="progress" [style.top.px]="(offset$ | async) + 56">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <app-item-counter class="item-counter" [position]="scrollItemPosition" [length]="dataSource.absoluteDataLength"></app-item-counter>
  </div>
</div>
