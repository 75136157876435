<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Company' | translate }}</mat-label>
      <input
        matInput
        formControlName="company"
        placeholder="{{ 'Company' | translate }}"
        [matAutocomplete]="autocompleteEmployer"
        [errorStateMatcher]="matcher"
        tabindex="1"
      />
      <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let company of companies$ | async" [value]="company">
          {{ company.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WorkingTimeStart' | translate }}</mat-label>
        <input
          matInput
          [ngxMatTimepicker]="timeStartPicker"
          formControlName="timeStart"
          format="24"
          placeholder="16:00"
          [max]="timeEnd.value"
          readonly
          tabindex="5"
        />
        <ngx-mat-timepicker #timeStartPicker color="accent"></ngx-mat-timepicker>
        <mat-icon matSuffix (click)="timeStartPicker.open()"> watch_later</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
      <input
        matInput
        formControlName="employerObject"
        [placeholder]="'EmployerObject' | translate"
        [matAutocomplete]="autocompleteEmployerObject"
        [errorStateMatcher]="matcher"
        tabindex="2"
      />
      <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
          {{ employerObject.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WorkingTimeEnd' | translate }}</mat-label>
        <input
          matInput
          [ngxMatTimepicker]="timeEndPicker"
          formControlName="timeEnd"
          format="24"
          placeholder="16:00"
          [min]="timeStart.value"
          readonly
          tabindex="5"
        />
        <ngx-mat-timepicker #timeEndPicker color="accent"></ngx-mat-timepicker>
        <mat-icon matSuffix (click)="timeEndPicker.open()"> watch_later</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="3" />
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'WorkMode' | translate }}</mat-label>
        <mat-select formControlName="workModeId" multiple tabindex="7">
          <mat-option #matOption *ngFor="let mode of workModes$ | async" [value]="mode.Id">{{ mode.Name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Four column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="4" />
    </mat-form-field>

    <div class="control">
      <ng-content></ng-content>
    </div>
  </div>
</div>
