import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[toUppercaseInput]',
})
export class UppercaseInputDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const value = event.target.value.toUpperCase();
    this.ngControl.control.setValue(value, { emitEvent: false });
  }
}
