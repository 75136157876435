import { Component, OnInit, ViewChild } from '@angular/core';
import { PresenceListDataSource } from '../presence-per-month-list/presence-list.datasource';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { Permission } from 'src/app/common/enums';

export enum PresenceListType {
  PresencePerDayList = 'PresencePerDayList',
  PresencePerMonthList = 'PresencePerMonthList'
}

@Component({
  selector: 'app-presence-list',
  templateUrl: './presence-list.component.html',
  styleUrls: ['./presence-list.component.scss'],
})
export class PresenceListComponent implements OnInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  navigation = [
    {
      listType: PresenceListType.PresencePerDayList,
      link: 'presences',
      linkTemplate: new RegExp('(\\d{4}\\/\\d{2}\\/\\d{2})$'),
      permissions: [Permission.ViewAllPresencesPerDay, Permission.ViewMyExternalWorkerPresencesPerDay, Permission.Supervisor]
    },
    {
      listType: PresenceListType.PresencePerMonthList,
      link: `presences/${DateTime.now().toFormat('yyyy/MM')}`,
      linkTemplate: new RegExp('(\\d{4}\\/\\d{2})$'),
      permissions: [Permission.ViewAllPresencesPerMonth, Permission.ViewMyExternalWorkerPresencesPerMonth, Permission.Supervisor]
    }
  ];

  constructor(
    public dataSource: PresenceListDataSource,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tabGroup.selectedIndex = this.navigation.findIndex(n => n.linkTemplate.test(this.router.url));
  }

  onTabChanged(event: MatTabChangeEvent): void {
    this.router.navigateByUrl(this.navigation[event.index].link);
  }
}
