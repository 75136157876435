<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
  <div
    *ngIf="hasPermissionToEmploymentConditionsConfirmations() || hasPermissionToMandateAgreementWorkedDayList(); else onlyWorkerAgreements"
    class="container"
    fxLayout="column"
    fxFlex="100"
    fxLayoutGap="20px"
  >
    <h1 class="header">{{ 'WL-WorkerAgreementList' | translate }}</h1>
    <div class="content-card">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)" mat-stretch-tabs="false">
        <mat-tab [label]="'WL-WorkerAgreement' | translate"> </mat-tab>
        <mat-tab
          *ngIf="hasPermissionToEmploymentConditionsConfirmations()"
          [label]="'EmploymentConditionsConfirmationList.EmploymentConditionsConfirmationList' | translate"
        >
        </mat-tab>
        <mat-tab
          *ngIf="hasPermissionToMandateAgreementWorkedDayList()"
          [label]="'MandateAgreementWorkedDayList.MandateAgreementWorkedDayList' | translate"
        >
        </mat-tab>
      </mat-tab-group>
    </div>

    <ng-container [ngSwitch]="listType">
      <app-worker-agreements-list *ngSwitchCase="listTypes.WorkerAgreements"></app-worker-agreements-list>
      <ng-container *ngSwitchCase="listTypes.EmploymentConditionsConfirmations">
        <app-employment-conditions-confirmation-list
          *ngIf="hasPermissionToEmploymentConditionsConfirmations()"
        ></app-employment-conditions-confirmation-list>
      </ng-container>
      <ng-container *ngSwitchCase="listTypes.MandateAgreementWorkedDays">
        <app-mandate-agreement-worked-day-list *ngIf="hasPermissionToMandateAgreementWorkedDayList()"></app-mandate-agreement-worked-day-list>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #onlyWorkerAgreements>
  <div class="container" fxLayout="column" fxFlex="100" fxLayoutGap="20px">
    <h1 class="header">{{ 'WL-WorkerAgreementList' | translate }}</h1>
    <app-worker-agreements-list></app-worker-agreements-list>
  </div>
</ng-template>
