<div fxLayout="column" *ngIf="vm$ | async as vm">
  <mat-card appearance="outlined" class="mat-space-bottom" *ngIf="vm?.agreements?.length">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 fxLayout="row" fxLayoutAlign="space-around center" class="date-heading">
        <button mat-icon-button (click)="previousMonth()" class="date-btn">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        {{ startMonthDate | date : 'dd.MM.yyyy' }} -
        {{ endMonthDate | date : 'dd.MM.yyyy' }}
        <button mat-icon-button (click)="nextMonth()" class="date-btn">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </h2>
    </div>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="second-row" [formGroup]="activeAgreementFormGroup">
        <mat-form-field appearance="outline" fxFlex="50" class="second-row__input">
          <mat-label>{{ 'TSG-Agreement' | translate }}</mat-label>
          <mat-select formControlName="activeAgreement">
            <mat-option #matOption *ngFor="let agreement of vm.agreements | filter : filterAgreements" [value]="agreement">{{
              agreement.EmployerName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          [visibleWithPermissions]="['ManageAsAExternalWorker']"
          mat-raised-button
          color="primary"
          fxFlex="10"
          *ngIf="!(isApprovedByWorker$ | async) && canManageTimesheet()"
          (click)="approveTimesheet()"
          class="accept-btn second-row__btn"
        >
          {{ 'ApproveTimesheet' | translate }}
        </button>
        <div fxFlex="40" *ngIf="activeAgreement != null" class="second-row__text">
          <h4 class="date-text">
            {{ 'TSG-AgreementDateRange' | translate }}: <br />{{ activeAgreement.EmploymentDateFrom | date : 'yyyy-MM-dd' }} -
            {{ activeAgreement.EmploymentDateTo | date : 'yyyy-MM-dd' }}
          </h4>
          <h4 class="termination date-text" *ngIf="!!activeAgreement.TerminationEndDate">
            {{ 'TSG-TerminationEndDate' | translate }}: {{ activeAgreement.TerminationEndDate | date : 'yyyy-MM-dd' }}
          </h4>
        </div>
      </div>
      <div class="table-wrapper table-wrapper--desktop">
        <table mat-table [dataSource]="vm.timesheetRecords" class="mat-elevation-z1">
          <!-- Date Column -->
          <ng-container matColumnDef="date" class="date-column">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-Date' | translate }}</th>
            <td
              mat-cell
              *matCellDef="let record"
              [ngClass]="{
                'current-day': isCurrentDate(record.Date),
                saturday: isSaturday(record.Date),
                sunday: isSunday(record.Date)
              }"
            >
              {{ record.Date | date : 'yyyy-MM-dd' }}, {{ record.Date | dayOfWeek | translate }}
            </td>
          </ng-container>

          <!-- Absence Column -->
          <ng-container matColumnDef="absence">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-Absence' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.Absence?.AbsenceSubtype }}</td>
          </ng-container>

          <!-- Start Column -->
          <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-StartDate' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.StartDate | date : 'HH:mm' }}</td>
          </ng-container>

          <!-- End Column -->
          <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef>{{ 'TSG-EndDate' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.EndDate | date : 'HH:mm' }}</td>
          </ng-container>

          <!-- Timespan Column -->
          <ng-container matColumnDef="timespan">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TSG-Timespan' | translate }} | {{ 'TSG-Total' | translate }} : {{ totalTimespan$ | async | timespanToString }}
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Timespan | timespanToString }}</td>
          </ng-container>

          <!-- Timespan Day Column -->
          <ng-container *ngIf="activeAgreement?.EmploymentTypeId === EmploymentType.MandateAgreement" matColumnDef="timespanDay">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TimespanDay' | translate }}
            </th>
            <td mat-cell *matCellDef="let record">{{ record.TimespanDay | timespanToString }}</td>
          </ng-container>

          <!-- Timespan Night Column -->
          <ng-container *ngIf="activeAgreement?.EmploymentTypeId === EmploymentType.MandateAgreement" matColumnDef="timespanNight">
            <th mat-header-cell *matHeaderCellDef>{{ 'TimespanNight' | translate }}</th>
            <td mat-cell *matCellDef="let record">{{ record.TimespanNight | timespanToString }}</td>
          </ng-container>

          <!-- WorkMode Column -->
          <ng-container matColumnDef="workMode">
            <th mat-header-cell *matHeaderCellDef>{{ 'WorkMode' | translate }}</th>
            <td class="work-mode-cell" mat-cell *matCellDef="let record">
              <ul class="work-mode-list">
                <li class="work-mode-label" *ngFor="let item of record.WorkModes">
                  <span>{{ item.ShortNameLang }}</span>
                </li>
              </ul>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container *ngIf="!isReadonly()" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let record">
              <span *ngIf="isCreateButtonVisible(record.Date)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="addRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">add</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isEditButtonVisible(record.Date)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="editRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">create</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isCopyButtonVisible(record)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="copyRecord()">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">content_copy</mat-icon>
                </button></span
              >
              <span *ngIf="record.Id !== 0 && isDeleteButtonVisible(record.Date)"
                ><button mat-icon-button [disabled]="isApproved$ | async" (click)="deleteRecord(record.Id)">
                  <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">delete</mat-icon>
                </button></span
              >
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (mouseenter)="onMouseEnterSelectedRow(row)"
            (mouseleave)="onMouseLeaveSelectedRow(row)"
            [ngClass]="{ selected: row === selectedRow }"
          ></tr>
        </table>
      </div>
      <div class="table-wrapper table-wrapper--mobile">
        <table class="responsive-table">
          <tbody *ngFor="let record of vm.timesheetRecords">
            <!-- Date Column -->
            <tr>
              <td>{{ 'TSG-Date' | translate }}</td>
              <td
                [ngClass]="{
                  'current-day': isCurrentDate(record.Date),
                  saturday: isSaturday(record.Date),
                  sunday: isSunday(record.Date)
                }"
              >
                {{ record.Date | date : 'yyyy-MM-dd' }}, {{ record.Date | dayOfWeek | translate }}
              </td>
            </tr>

            <!-- Date Column -->
            <tr>
              <td>{{ 'TSG-WorkTime' | translate }}</td>
              <td>{{ record.StartDate | date : 'HH:mm' }} - {{ record.EndDate | date : 'HH:mm' }}</td>
            </tr>

            <!-- Timespan -->
            <tr *ngIf="activeAgreement?.EmploymentTypeId === EmploymentType.MandateAgreement">
              <td>
                {{ 'TimespanDay' | translate }}
              </td>
              <td>{{ record.TimespanDay | timespanToString }}</td>
              <td>{{ 'TimespanNight' | translate }}</td>
              <td>{{ record.TimespanNight | timespanToString }}</td>
            </tr>

            <!-- WorkModes -->
            <tr *ngIf="record.WorkModes && record.WorkModes.length">
              <td>
                <ul class="work-mode-list">
                  <li class="work-mode-label" *ngFor="let item of record.WorkModes">
                    <span>{{ item.ShortNameLang }}</span>
                  </li>
                </ul>
              </td>
            </tr>

            <!-- Actions  -->
            <tr *ngIf="!isReadonly()" (mouseenter)="onMouseEnterSelectedRow(record)" (mouseleave)="onMouseLeaveSelectedRow(record)">
              <td class="actions-btn">
                <span *ngIf="isCreateButtonVisible(record.Date)"
                  ><button mat-icon-button [disabled]="isApproved$ | async" (click)="addRecord()">
                    <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">add</mat-icon>
                  </button></span
                >
                <span *ngIf="record.Id !== 0 && isEditButtonVisible(record.Date)"
                  ><button mat-icon-button [disabled]="isApproved$ | async" (click)="editRecord()">
                    <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">create</mat-icon>
                  </button></span
                >
                <span *ngIf="record.Id !== 0 && isCopyButtonVisible(record)"
                  ><button mat-icon-button [disabled]="isApproved$ | async" (click)="copyRecord()">
                    <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">content_copy</mat-icon>
                  </button></span
                >
                <span *ngIf="record.Id !== 0 && isDeleteButtonVisible(record.Date)"
                  ><button mat-icon-button [disabled]="isApproved$ | async" (click)="deleteRecord(record.Id)">
                    <mat-icon [matTooltip]="(isApproved$ | async) ? ('TimesheetIsAlreadyApproved' | translate) : ''">delete</mat-icon>
                  </button></span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <tr>
        <div class="summary">
          <div>{{ 'WF-Summary' | translate }}:</div>
          <div>{{ 'TSG-Total' | translate }}: {{ totalTimespan$ | async | timespanToString }}</div>
          <div *ngIf="isConcludedHomeOfficeRemoteAvailable">
            {{ 'ConcludedHomeOfficeRemoteMonthAmount' | translate : { daysAmount: concludedHomeOfficeRemoteMonthAmount } }}
          </div>
          <div *ngIf="isHomeOfficeOnDemandAvailable">
            {{
              'HomeOfficeOnDemandMonthAmount'
                | translate : { daysAmount: homeOfficeOnDemandMonthAmount, yearDaysAmount: homeOfficeOnDemandYearAmount }
            }}
          </div>
        </div>
      </tr>
    </mat-card-content>
  </mat-card>
</div>

<app-agreement-not-available *ngIf="isActiveAgreementsListEmpty()"></app-agreement-not-available>
