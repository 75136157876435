import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { AgreementTypeEnum } from 'src/app/models/enums/worker-agreement-type-enum';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, firstValueFrom, interval, Observable, Subject } from 'rxjs';
import { debounceTime, filter, finalize, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { download } from 'src/app/common/utils/downloadFile';
import { SendAgreementExtensionConsentRequest } from 'src/app/contracts/requests/send-agreement-extension-consent-request';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DownloadService } from 'src/app/data/download.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { Filter } from 'src/app/models/common/filter';
import { AllWorkerAgreementGridDto } from 'src/app/models/dtos/AllWorkerAgreementGridDto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerFormStatusEnum } from 'src/app/models/enums/WorkerFormStatusEnum';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SendMultipleWorkerAgreementsToApprovalSignaturePadComponent } from '../send-multiple-worker-agreements-to-approval-signature-pad/send-multiple-worker-agreements-to-approval-signature-pad.component';
import { WorkerAgreementsListFiltersComponent } from '../worker-agreements-list-filters/worker-agreements-list-filters.component';
import { AllWorkerAgreementsGridDataSource } from './worker-agreements-list.datasource';
import { isAgreementFileReady } from 'src/app/common/utils/agreement-utils';
import { DatePipe } from '@angular/common';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { ReportService } from 'src/app/data/report.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { LegalizationService } from 'src/app/data/legalization.service';
import { LegalizationStatusEnum } from 'src/app/models/enums/legalization-status-enum';
import { getKeyByValue } from 'src/app/common/utils/enum-utils';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { MultipleWorkerAgreementsActionConfigDto } from 'src/app/models/dtos/multiple-worker-agreements-action-config-dto';
import { MultipleWorkerAgreementsActionSelectedWorkersDto } from 'src/app/models/dtos/multiple-worker-agreements-action-selected-workers-dto';
import { UserService } from 'src/app/data/user.service';
import { FilterTypeEnum } from 'src/app/models/enums/filter-type-enum';
import { FilterPresetNameFormDialogComponent } from 'src/app/shared/components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { FilterPresetDto } from 'src/app/models/dtos/filter-preset-dto';
import { animate, style, transition, trigger } from '@angular/animations';
import Comparator from 'src/app/common/comparators/comparator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { TerminateAgreementModalComponent } from 'src/app/worker-agreement-termination/terminate-agreement-modal/terminate-agreement-modal.component';
import { MultipleWorkerAgreementsActionSelectedWorkerAgreementDto } from 'src/app/models/dtos/multiple-worker-agreements-action-selected-worker-agreement-dto';
import { EmploymentConditionsConfirmationStatusEnum } from 'src/app/models/enums/employment-conditions-confirmation-status-enum';

const legalizationStatusTranslaionPrefix = 'Legalization.Status'
const employmentConditionsConfirmationStatusTranslaionPrefix = 'EmploymentConditionsConfirmationStatus'

@Component({
  selector: 'app-worker-agreements-list',
  templateUrl: './worker-agreements-list.component.html',
  styleUrls: ['./worker-agreements-list.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class WorkerAgreementsListComponent implements OnInit, OnDestroy {
  sticky = false;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('tableContainer', { static: true }) tableContainerRef: ElementRef;

  visibleColumns: any[];

  page = 1;
  pageSize = 30;
  offset$: Observable<number>;

  WorkerAgreementStatusEnum = WorkerAgreementStatusEnum;
  WorkerStatusEnum = WorkerStatusEnum;

  filtersFormGroup: UntypedFormGroup;
  displayedColumns: string[] = [
    'select',
    'LastName',
    'WorkerStatus',
    'WorkerFormStatus',
    'EmployerName',
    'EmploymentTypeId',
    'AgreementType',
    'AgreementStartDate',
    'AgreementEndDate',
    'AgreementConclusionDate',
    'AgreementTerminationEndDate',
    'AgreementStatus',
    'Actions'
  ];

  private _areFiltersExpanded: boolean;
  public get areFiltersExpanded(): boolean {
    return this._areFiltersExpanded;
  }
  public set areFiltersExpanded(value: boolean) {
    if (this._areFiltersExpanded !== value) {
      this._areFiltersExpanded = value;
      this.checkViewportSize();
    }
  }

  dataSource: AllWorkerAgreementsGridDataSource;
  hasLegalization: boolean;

  isRefreshing$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultFilterPresetName = 'default';
  public filterPresets: FilterPresetDto[];
  private _selectedFilterPresetId: number;
  public set selectedFilterPresetId(value: number) {
    if (this._selectedFilterPresetId !== value) {
      this._selectedFilterPresetId = value;

      if (value) {
        this.applyFilterPreset(this.filterPresets.find(fp => fp.Id === value));
      }
    }
  }
  public get selectedFilterPresetId(): number {
    return this._selectedFilterPresetId;
  }
  public get selectedFilterPreset(): FilterPresetDto {
    return this.filterPresets.find(fp => fp.Id === this.selectedFilterPresetId);
  }
  public get showFilterPresetSelector(): boolean {
    return this.filterPresets && (this.filterPresets.length > 1 || (this.filterPresets.length === 1 && this.filterPresets[0].Name !== this.defaultFilterPresetName));
  }
  public get isSavedFilterPreset(): boolean {
    return this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName;
  }

  public get scrollItemPosition(): number {
    return this.viewport.getRenderedRange().end;
  }

  public async setSelectedFilterPresetId(value: number): Promise<void> {
    this.selectedFilterPresetId = value;
    await this.filterData(buildFilterArray(this.filtersFormGroup, WorkerAgreementsListFiltersComponent.operatorsMap));
    await firstValueFrom(this.userService.selectFilterPreset(value));
  }

  private multipleActionsConfig: MultipleWorkerAgreementsActionConfigDto;
  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 1000;
  private readonly selectionLengthLimitWarning: number = 50;
  private readonly defaultSortColumn: string = 'CreatedOn';
  private readonly defaultSortDirection: string = 'desc';
  private readonly notAllowedToEditWorkerAgreementMessage: string = 'NotAllowedToEditWorkerAgreementMessage';
  private readonly employeeIsInLegalizationProcess: string = 'EmployeeIsInLegalizationProcess';
  private readonly unsubscribe$ = new Subject<void>();

  private filters: Filter[] = [];

  public readonly workerAgreementStatusEnum = WorkerAgreementStatusEnum;
  public readonly workerStatusEnum = WorkerStatusEnum;
  public readonly workerFormStatusEnum = WorkerFormStatusEnum;
  public readonly legalizationStatusEnum = LegalizationStatusEnum;
  public readonly employmentConditionsConfirmationStatusEnum = EmploymentConditionsConfirmationStatusEnum;

  constructor(
    private workerAgreementService: WorkerAgreementService,
    private employmentAgreementService: EmploymentAgreementService,
    private translateService: TranslateService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private downloadService: DownloadService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackbarService: SnackBarService,
    private pdfViewerService: PdfViewerService,
    private reportService: ReportService,
    public datepipe: DatePipe,
    private snackbar: SnackBarService,
    private legalizationService: LegalizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService
  ) {
    if (this.employmentConditionConfirmationStatusVisible()) {
      let index = this.displayedColumns.findIndex(item => item === 'AgreementStartDate');

      if (index !== -1) {
        this.displayedColumns.splice(index, 0, 'EmploymentConditionsConfirmationStatusId');
      }
    }
  }

  employmentConditionConfirmationStatusVisible = () => this.authService.hasPermission(Permission.ViewAllExternalWorkerEmploymentConditionsConfirmation) || this.authService.hasPermission(Permission.ViewMyExternalWorkerEmploymentConditionsConfirmation) || this.authService.isAdmin();

  async ngOnInit(): Promise<void> {
    this.init();
    this.buildFormGroup();
    await this.restoreSavedFiltersPreset();
    this.initFiltersFormChangeObserver();
    this.filters = buildFilterArray(this.filtersFormGroup, WorkerAgreementsListFiltersComponent.operatorsMap);
    this.fetchData();
    this.initDatRefresher();
    this.initLangChanegeObserver();

    this.hasLegalization = await firstValueFrom(this.legalizationService.hasAccessToLegalization());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  hasAnyAction(workerAgreement: AllWorkerAgreementGridDto): boolean {
    return this.isAgreementFileButtonVisible(workerAgreement) ||
      this.isExtendAgreementButtonVisible(workerAgreement.AgreementStatusId, workerAgreement.AgreementTypeId) ||
      this.isReplaceAgreementButtonVisible(workerAgreement.AgreementStatusId, workerAgreement.AgreementTypeId) ||
      this.isEditAgreementButtonVisible(workerAgreement.AgreementStatusId) ||
      this.isTerminateButtonVisible(workerAgreement) ||
      this.isDetailsButtonVisible(workerAgreement.AgreementStatusId);
  }

  hasLegalizationStatus(legalizationStatusEnum: LegalizationStatusEnum, legalizationStatusId: number): boolean {
    return legalizationStatusId === legalizationStatusEnum
  };

  getLegalizationTooltip(legalizationStatusId: number): string {
    return `${legalizationStatusTranslaionPrefix}.${getKeyByValue(this.legalizationStatusEnum, legalizationStatusId)}`
  }

  // Cache the tooltip classes and enums
  private employmentConditionsConfirmationStatusClassMap = {
    [EmploymentConditionsConfirmationStatusEnum.Generated]: 'tooltip--pink',
    [EmploymentConditionsConfirmationStatusEnum.Signed]: 'tooltip--blue',
    [EmploymentConditionsConfirmationStatusEnum.Read]: 'tooltip--darkgreen',
    default: '',
  };

  hasEmploymentConditionsConfirmationStatus(employmentConditionsConfirmationStatusEnum: EmploymentConditionsConfirmationStatusEnum, employmentConditionsConfirmationStatusId: number): boolean {
    return employmentConditionsConfirmationStatusId === employmentConditionsConfirmationStatusEnum;
  }

  getEmploymentConditionsConfirmationStatusEnum(employmentConditionsConfirmationStatusId: number): string {
    const key = getKeyByValue(this.employmentConditionsConfirmationStatusEnum, employmentConditionsConfirmationStatusId);
    return `${employmentConditionsConfirmationStatusTranslaionPrefix}.${key}`;
  }

  getEmploymentConditionsConfirmationTooltipClass(employmentConditionsConfirmationStatusId: number): string {
    return this.employmentConditionsConfirmationStatusClassMap[employmentConditionsConfirmationStatusId] || this.employmentConditionsConfirmationStatusClassMap.default;
  }

  isDetailsButtonVisible = (statusId: number): boolean =>
    statusId !== WorkerAgreementStatusEnum.WaitingForGeneration && this.authService.hasAnyPermission([Permission.ManageMyExternalWorkersList, Permission.ViewAllExternalWorkers]);

  isAgreementFileButtonVisible = (workerAgreement: AllWorkerAgreementGridDto): boolean =>
    isAgreementFileReady(workerAgreement.AgreementStatusId) && !workerAgreement.IsImported &&
    (this.authService.hasAnyPermission([Permission.AgreementPreview, Permission.AgreementPreviewAllExternalWorkers, Permission.AgreementPreviewMyExternalWorkers]));

  async sendAgreementExtensionConsent() {
    const selectedWorkers = await this.getSelectedWorkers();

    if (selectedWorkers.length <= this.selectionLengthLimitWarning || await this.openConfirmDialog(this.translateService.instant('WL-SendAgreementExtensionConsentConfirmation', { selectionLength: selectedWorkers.length }))) {

      const requests = selectedWorkers.map((sw): SendAgreementExtensionConsentRequest =>
      ({
        WorkerId: sw.Id,
        WorkerFormId: sw.WorkerFormId,
      })
      );

      this.spinner.show();

      this.workerAgreementService
        .sendAgreementExtensionConsent(requests)
        .pipe(
          first(),
          finalize(() => this.spinner.hide()),
        )
        .subscribe((_) => this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySentExtensionConsents));
    }
  }

  async createEmploymentConditionsConfirmations() {
    const selectedWorkerAgreements = await this.getSelectedWorkerAgreements();
    const existingEmploymentConditionLength = selectedWorkerAgreements.filter(sw => sw.HasEmploymentConditionsConfirmation).length;

    const openDialog = async () => await this.openConfirmDialog(
      existingEmploymentConditionLength
        ? this.translateService.instant('WL-CreateEmploymentConditionsConfirmationsWhenSomeAlreadyExistMessage', {
          selectionLength: selectedWorkerAgreements.length,
          existingEmploymentConditionLength: existingEmploymentConditionLength,
          notExistingEmploymentConditionLength: selectedWorkerAgreements.length - existingEmploymentConditionLength
        })
        : undefined,
      this.translateService.instant('WL-CreateEmploymentConditionsConfirmationsDialogTitle', { selectionLength: selectedWorkerAgreements.length }),
      this.translateService.instant('Continue'),
      this.translateService.instant('Cancel'));

    if (await openDialog()) {
      this.workerAgreementService
        .createEmploymentConditionsConfirmations(selectedWorkerAgreements.filter(sw => !sw.HasEmploymentConditionsConfirmation).map(sw => sw.Id))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((_) => this.snackbar.openSuccessSnackBar(Messages.StartedEmploymentConditionsConfirmationsGeneration));
    }
  }

  onExtendAgreementClick(agreementId: number, workerId: number, employmentTypeId: number) {
    this.spinner.show();

    const action$ =
      employmentTypeId === EmploymentType.MandateAgreement
        ? this.workerAgreementService.extendWorkerMandateAgreement(agreementId)
        : this.employmentAgreementService.extendEmploymentAgreement(agreementId);

    this.legalizationService
      .verifyWorkerLegalizationStatus(workerId)
      .pipe(
        first(),
        switchMap(() =>
          action$
            .pipe(
              first()
            )
        ),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((agreementId) => this.router.navigate(['/workers', workerId, 'employmentType', employmentTypeId, 'agreements', agreementId]));
  }

  isExtendAgreementButtonVisible = (agreementStatusId: number, agreementTypeId: number): boolean =>
    (agreementStatusId === WorkerAgreementStatusEnum.Accepted || agreementStatusId === WorkerAgreementStatusEnum.Active) &&
    agreementTypeId !== AgreementTypeEnum.Permanent && this.authService.hasAnyPermission([Permission.ManageAllExternalWorkersList, Permission.ManageMyExternalWorkersList]);

  async replaceAgreement(agreement: AllWorkerAgreementGridDto) {
    let agreementId: number;
    if (agreement.WaitingForGenerateReplacementWorkerAgreementId) {
      agreementId = agreement.WaitingForGenerateReplacementWorkerAgreementId;
    } else {
      this.spinner.show();
      this.legalizationService
        .verifyWorkerLegalizationStatus(agreement.WorkerId)
        .pipe(
          first(),
          switchMap(async () => {
            agreementId = await this.workerAgreementService
              .replaceWorkerAgreement(agreement.AgreementId)
              .pipe(first())
              .toPromise();

            return agreementId;
          }),
          finalize(() => this.spinner.hide()),
        )
        .subscribe(() => this.router.navigate(['/workers', agreement.WorkerId, 'employmentType', agreement.EmploymentTypeId, 'agreements', agreementId]))
    }
  }

  isReplaceAgreementButtonVisible = (agreementStatusId: number, employmentTypeId: number): boolean =>
    (agreementStatusId === WorkerAgreementStatusEnum.Accepted || agreementStatusId === WorkerAgreementStatusEnum.Active) &&
    employmentTypeId === EmploymentType.MandateAgreement;

  onShowAgreementClick(agreementId: number, firstName: string, lastName: string) {
    this.pdfViewerService.show({
      Endpoint: 'workerAgreements/workerAgreementFile',
      FileId: agreementId,
      FileName: this.setFileName(`${firstName} ${lastName}`),
    });
  }

  onDownloadAgreementClick(agreementId: number, firstName: string, lastName: string) {
    const fileName = this.setFileName(`${firstName} ${lastName}`);
    this.downloadService
      .getFileAsBlob('workerAgreements/workerAgreementFile', agreementId, fileName)
      .subscribe((srcUrl) => download(srcUrl, fileName));
  }

  isEditAgreementButtonVisible = (statusId: number): boolean =>
    (this.authService.hasPermission(Permission.ManageAllExternalWorkersList) ||
      this.authService.hasPermission(Permission.ManageMyExternalWorkersList)) &&
    statusId === WorkerAgreementStatusEnum.WaitingForGeneration;

  onEditAgreementClick(workerAgreement: AllWorkerAgreementGridDto): void {
    if (!!workerAgreement.LegalizationStatusId && workerAgreement.LegalizationStatusId !== LegalizationStatusEnum.Approved) {
      this.openAlertDialog(this.employeeIsInLegalizationProcess);
      return;
    }

    if (workerAgreement.WorkerFormStatusId != WorkerFormStatusEnum.Approved) {
      this.openAlertDialog(this.notAllowedToEditWorkerAgreementMessage);
      return;
    }

    if (workerAgreement.AgreementStatusId !== WorkerAgreementStatusEnum.WaitingForGeneration) {
      return;
    }

    this.router.navigate([
      '/workers',
      workerAgreement.WorkerId,
      'employmentType',
      workerAgreement.EmploymentTypeId,
      'agreements',
      workerAgreement.AgreementId,
    ]);
  }

  isSendAgreementExtensionConsentButtonEnabled = (): boolean => this.isAllSelected()
    ? this.multipleActionsConfig?.CanSendAgreementsExtensionsConsent
    : this.dataSource.selection.selected.length &&
    this.dataSource.selection.selected.every(
      (v) => v.AgreementStatusId === WorkerAgreementStatusEnum.Accepted || v.AgreementStatusId === WorkerAgreementStatusEnum.Active,
    );

  isCreateEmploymentConditionsConfirmationsButtonEnabled = (): boolean => this.isAllSelected()
    ? this.multipleActionsConfig?.CanCreateEmploymentConditionsConfirmations
    : this.dataSource.selection.selected.length &&
    this.dataSource.selection.selected.every(
      (v) =>
        v.AgreementStatusId === WorkerAgreementStatusEnum.Active ||
        v.AgreementStatusId === WorkerAgreementStatusEnum.Inactive ||
        v.AgreementStatusId === WorkerAgreementStatusEnum.Terminated ||
        v.AgreementStatusId === WorkerAgreementStatusEnum.PendingSigning
    )
    && this.dataSource.selection.selected.some((v) => !v.HasEmploymentConditionsConfirmation);

  isTerminateButtonVisible = (workerAgreement: AllWorkerAgreementGridDto): boolean =>
    ((this.authService.authServerUserId == workerAgreement.AuthServerUserId && this.authService.hasPermission(Permission.TerminateMyAgreement)) || this.authService.hasPermission(Permission.TerminateMandateAgreement)) &&
    workerAgreement.AgreementStatusId === WorkerAgreementStatusEnum.Active &&
    workerAgreement.EmploymentTypeId === EmploymentType.MandateAgreement;

  onTerminateClick(
    workerId: number,
    workerFullName: string,
    employer: string,
    agreementId: number,
    noticePeriodId: number,
    employmentDateFrom: Date,
    employmentDateTo: Date,
    employmentTypeId: number,
    hasAgreementTerminationReason: boolean
  ) {
    this.dialog.open(TerminateAgreementModalComponent, {
      data: {
        WorkerId: workerId,
        WorkerFullName: workerFullName,
        Employer: employer,
        WorkerAgreementId: agreementId,
        NoticePeriodId: noticePeriodId,
        EmploymentDateFrom: employmentDateFrom,
        EmploymentDateTo: employmentDateTo,
        EmploymentTypeId: employmentTypeId,
        HasAgreementTerminationReason: hasAgreementTerminationReason
      },
    });
  }

  async filterData(filters: Filter[]): Promise<void> {
    this.filters = filters;
    this.resetData();
    this.fetchData();
    await this.getMultipleActionsConfig();
  }

  onSortChange() {
    this.resetData();
    this.fetchData();
  }

  onRowChxChange(event: MatCheckboxChange, row: AllWorkerAgreementGridDto) {
    if (event) {
      this.dataSource.selection.toggle(row);
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  isAllSelected = () => this.dataSource.isAllSelected;

  async onSelectAllChxChange(): Promise<void> {
    if (this.isAllSelected()) {
      this.dataSource.deselectAll();
    } else {
      this.dataSource.selectAll();
      await this.getMultipleActionsConfig();
    }
  }

  async resetFilters(): Promise<void> {
    this.filtersFormGroup.reset(undefined, { emitEvent: false });
    this.filterData([]);

    if (this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName) {
      await this.deselectFilterPreset();
    }

    this.selectedFilterPresetId = undefined;
    this.upsertFilterPreset();
  }

  isGenerationOnMultipleAgreementsEnabled = () => this.isAllSelected()
    ? this.multipleActionsConfig?.CanGenerateAgreements
    : !!this.dataSource.selection.selected.length &&
    !this.dataSource.selection.selected.some((v, i, a) => a.some((t) => t.EmploymentTypeId !== v.EmploymentTypeId)) &&
    this.dataSource.selection.selected.every((s) => s.WorkerFormStatusId === WorkerFormStatusEnum.Approved && (!s.LegalizationStatusId || !!s.LegalizationStatusId && s.LegalizationStatusId === LegalizationStatusEnum.Approved));

  isExtensionOnMultipleAgreementsEnabled = () => this.isAllSelected()
    ? this.multipleActionsConfig?.CanExtendAgreements
    : !!this.dataSource.selection.selected.length &&
    !this.dataSource.selection.selected.some((v, i, a) => a.some((t) => t.EmploymentTypeId !== v.EmploymentTypeId)) &&
    this.dataSource.selection.selected.every(
      (s) =>
        (s.AgreementStatusId === WorkerAgreementStatusEnum.Accepted || s.AgreementStatusId === WorkerAgreementStatusEnum.Active) &&
        s.AgreementTypeId !== AgreementTypeEnum.Permanent && (!s.LegalizationStatusId || !!s.LegalizationStatusId && s.LegalizationStatusId === LegalizationStatusEnum.Approved),
    );

  generateMultipleAgreements = async () => {
    if (!this.isGenerationOnMultipleAgreementsEnabled()) return;

    const selectedWithoutDuplicates = await this.getSelectedWorkers();

    if (selectedWithoutDuplicates.length <= this.selectionLengthLimitWarning || await this.openConfirmDialog(this.translateService.instant('WL-GenerateMultipleAgreementsConfirmation', { selectionLength: selectedWithoutDuplicates.length }))) {
      const employmentTypeId = this.dataSource.selection.selected[0].EmploymentTypeId;

      this.router.navigate(['/workers', 'multiple', 'employmentType', employmentTypeId, 'agreements'], {
        state: { workers: selectedWithoutDuplicates },
      });
    }
  };

  extendMultipleAgreements = async () => {
    if (!this.isExtensionOnMultipleAgreementsEnabled()) return;

    const selectedWithoutDuplicates = await this.getSelectedWorkers();

    if (selectedWithoutDuplicates.length <= this.selectionLengthLimitWarning || await this.openConfirmDialog(this.translateService.instant('WL-ExtendMultipleAgreementsConfirmation', { selectionLength: selectedWithoutDuplicates.length }))) {
      const employmentTypeId = this.dataSource.selection.selected[0].EmploymentTypeId;
      this.router.navigate(['/workers', 'multiple', 'employmentType', employmentTypeId, 'agreements'], {
        state: { workers: selectedWithoutDuplicates, extension: true },
      });
    }
  };

  isSendingMultipleAgreementsToApprovalEnabled = () => this.isAllSelected()
    ? this.multipleActionsConfig?.CanSendAgreementsToApproval
    : (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
      this.authService.hasPermission(Permission.ManageAllExternalWorkersList)) &&
    this.authService.hasPermission(Permission.SignAgreementAsEmployerRepresentative) &&
    !!this.dataSource.selection.selected.length &&
    !this.dataSource.selection.selected.some((v, i, a) => a.some((t) => t.EmploymentTypeId !== v.EmploymentTypeId)) &&
    this.dataSource.selection.selected.every(
      (s) => s.AgreementStatusId === WorkerAgreementStatusEnum.Generated && s.WorkerStatusId === WorkerStatusEnum.Activated,
    );

  sendMultipleAgreementsToApproval = async () => {
    if (!this.isSendingMultipleAgreementsToApprovalEnabled()) return;

    const refreshData = async () => {
      var { page, pageSize } = this.getActualPage();

      this.fetchData(page, pageSize);
      await this.getMultipleActionsConfig();
    }
    const selectedWorkerAgreement = await this.getSelectedWorkerAgreements();

    if (selectedWorkerAgreement.length <= this.selectionLengthLimitWarning || await this.openConfirmDialog(this.translateService.instant('WL-SendMultipleAgreementsToApprovalConfirmation', { selectionLength: selectedWorkerAgreement.length }))) {
      if (this.dataSource.selection.selected[0].EmploymentTypeId == EmploymentType.MandateAgreement) {
        this.spinner.show().then((_) =>
          this.workerAgreementService
            .sendMultipleWorkerAgreementsToApproval({
              WorkerAgreementIds: selectedWorkerAgreement.map(sw => sw.Id),
              EmploymentTypeId: this.dataSource.selection.selected[0].EmploymentTypeId,
              SignatureFileBase64: null,
            })
            .pipe(
              first(),
              finalize(async () => {
                this.spinner.hide();
                this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySentMultipleAgreeemntsToApproval);
                await refreshData();
              }),
            )
            .subscribe(),
        );
      } else {
        this.dialog
          .open(SendMultipleWorkerAgreementsToApprovalSignaturePadComponent, {
            data: {
              WorkerAgreementIds: selectedWorkerAgreement.map(agreement => agreement.Id),
              EmploymentTypeId: this.dataSource.selection.selected[0].EmploymentTypeId,
            },
          })
          .afterClosed()
          .pipe(first())
          .subscribe((isSuccess) => isSuccess && refreshData());
      }
    }
  };

  private async getSelectedWorkers(): Promise<MultipleWorkerAgreementsActionSelectedWorkersDto[]> {
    return this.isAllSelected() ? await this.fetchMultipleWorkerAgreementsActionSelectedWorkers() : this.selectWithoutDuplicates();
  }

  private async getSelectedWorkerAgreements(): Promise<MultipleWorkerAgreementsActionSelectedWorkerAgreementDto[]> {
    return this.isAllSelected() ? await this.fetchMultipleWorkerAgreementsActionSelectedWorkerAgreements() : this.dataSource.selection.selected.map((s) => ({ Id: s.AgreementId, HasEmploymentConditionsConfirmation: s.HasEmploymentConditionsConfirmation } as MultipleWorkerAgreementsActionSelectedWorkerAgreementDto));
  }

  createAgreementEndDateTooltipText(element: AllWorkerAgreementGridDto) {
    const datepipe: DatePipe = new DatePipe('en-US');

    let tooltipText = `${this.translateService.instant('TerminationSubmissionDate')}: ${datepipe.transform(element.AgreementTerminationSubmissionDate, 'yyyy-MM-dd',)}
    ${this.translateService.instant('TerminationStartDate')}: ${datepipe.transform(element.AgreementTerminationStartDate, 'yyyy-MM-dd')}
    ${this.translateService.instant('AgreementTerminationEndDate')}: ${datepipe.transform(element.AgreementTerminationEndDate, 'yyyy-MM-dd')}`;

    if (!!element.LastDayOfWork) {
      tooltipText += `\n${this.translateService.instant('LastDayOfWork')}: ${datepipe.transform(element.LastDayOfWork, 'yyyy-MM-dd')}`
    }

    return tooltipText
  }

  private setFileName = (fullName: string): string => `Umowa ${fullName}.pdf`;

  private openAlertDialog = (message: string) =>
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });

  private openConfirmDialog(message: string, title: string = null, confirmButtonLabel: string = null, dismissButtonLabel: string = null): Promise<boolean> {
    return firstValueFrom(this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogData(title, message, confirmButtonLabel, dismissButtonLabel),
      panelClass: 'confirm-dialog'
    }).afterClosed());
  }

  private selectWithoutDuplicates(): MultipleWorkerAgreementsActionSelectedWorkersDto[] {
    return this.dataSource.selection.selected
      .map((s) => ({
        Id: s.WorkerId,
        Name: s.FirstName + ' ' + s.LastName,
        AgreementId: s.AgreementId,
        WorkerFormId: s.WorkerFormId
      }))
      .filter((v, i, a) => a.findIndex((t) => t.Id === v.Id) === i);
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      employerId: [null],
      employer: [null, [autocompleteValidator]],
      employerObjectId: [null],
      employerObject: [null, [autocompleteValidator]],
      mpk: [{ value: null, disabled: true }],
      locationId: [null],
      location: [null],
      agreementTypeId: [null],
      employmentDateFrom: [null],
      employmentDateTo: [null],
      workerStatusId: [null],
      agreementStatusId: [null],
      pesel: [null, [Validators.pattern('^[0-9]{11}$')]],
      employmentTypeId: [null],
      document: [null, [Validators.pattern('^[a-zA-Z0-9 ]+$')]],
      documentFilter: [null],
      isInternshipAgreement: [null],
      legalizationStatusId: [null],
      employmentConditionsConfirmationStatusId: [null]
    });
  }

  private async restoreSavedFiltersPreset() {
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.WorkerAgreementsList));

    if (this.filterPresets && this.filterPresets.length) {
      const selectedPreset = this.filterPresets.find(fp => fp.IsSelected);

      this.selectedFilterPresetId = selectedPreset?.Id;
    }
  }

  private initFiltersFormChangeObserver() {
    const comparator = new Comparator<any>();

    this.filtersFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe(async () => {
        if (this.filtersFormGroup.invalid) {
          return;
        }

        const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

        const selectedFilterPresetId = this.filterPresets.find(fp => comparator.equals(JSON.parse(fp.Object), filters))?.Id;

        if (!selectedFilterPresetId) {
          this.selectedFilterPresetId = this.filterPresets.find(fp => fp.Name === this.defaultFilterPresetName)?.Id;

          await this.upsertFilterPreset();
        } else {
          this.selectedFilterPresetId = selectedFilterPresetId;
        }
      });
  }

  private applyFilterPreset(filterPreset) {
    const filters = JSON.parse(filterPreset?.Object);

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.reset(undefined, { emitEvent: false });
      this.filtersFormGroup.patchValue(filters, { emitEvent: false });
      this.areFiltersExpanded = this.areFiltersExpanded || this.selectedFilterPreset.Name === this.defaultFilterPresetName;
    }
  }

  private initDatRefresher() {
    this.dataSource.isLoading$
      .pipe(filter(isLoading => !isLoading))
      .pipe(debounceTime(15000))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async _ => {
        var { page, pageSize } = this.getActualPage();

        this.fetchData(page, pageSize);

        await this.getMultipleActionsConfig();
      });
  }

  private getActualPage() {
    const start = this.viewport.getRenderedRange().start + 1;
    const end = this.viewport.getRenderedRange().end;

    const pageStart = Math.ceil(start / this.pageSize);
    const pageEnd = Math.ceil(end / this.pageSize);

    const pageMultiplier = pageEnd - pageStart + 1;

    let pageSize = this.pageSize * pageMultiplier;

    const page = Math.ceil(pageStart / pageMultiplier === 0 ? this.defaultPageSize : pageMultiplier);

    if (pageSize * page < end) {
      pageSize += Math.ceil((end - pageSize * page) / this.pageSize) * this.pageSize;
    }

    return { page, pageSize };
  }

  private initLangChanegeObserver() {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        var { page, pageSize } = this.getActualPage();

        this.fetchData(page, pageSize);
        await this.getMultipleActionsConfig();
      });
  }

  onReportGenerateButtonClick() {
    this.snackbar.openSuccessSnackBar(Messages.StartedReportGeneration)

    this.reportService
      .getEmployeesAssignedToMyObjectsReportCSV(
        this.translateService.instant('reportFileNames.employeesAssignedToMyObjects', { date: this.datepipe.transform(Date.now(), 'dd_MM_yyyy') }),
      )
      .subscribe({
        error: () => this.snackbar.openErrorSnackBar(Messages.SomethingWentWrong),
        complete: () => this.snackbar.openSuccessSnackBar(Messages.SuccessfullyGeneratedDocument),
      });
  }

  async nextBatch(event) {
    if (!this.sticky) {
      this.sticky = true;
    }
    const buffer = 20;
    const range = this.viewport.getRenderedRange();
    const end = range.end;
    if (true) {
      if (!this.dataSource.isLoading && end + buffer > this.page * this.pageSize) {
        this.fetchData(++this.page);
      }
    }
  }

  private init() {
    if (this.dataSource) {
      return;
    }

    this.dataSource = new AllWorkerAgreementsGridDataSource(
      this.workerAgreementService,
      {
        viewport: this.viewport
      });

    this.offset$ = this.viewport.renderedRangeStream.pipe(
      map(() => -this.viewport.getOffsetToRenderedContentStart())
    );
  }

  trackBy(index: number, item: AllWorkerAgreementGridDto) {
    return `${item.AgreementId}${item.AgreementStatusId}${item.WorkerStatusId}${item.WorkerFormStatusId}`;
  }

  private fetchData(page?: number, pageSize?: number) {
    this.dataSource.fetchWorkerAgreements(
      page ?? this.page,
      pageSize ?? this.pageSize ?? this.defaultPageSize,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection,
      this.filters
    );
  }

  private async getMultipleActionsConfig() {
    if (this.isAllSelected()) {
      this.multipleActionsConfig = await firstValueFrom(this.workerAgreementService.getMultipleWorkerAgreementsActionConfig(this.filters));
    }
  }

  private async fetchMultipleWorkerAgreementsActionSelectedWorkers(): Promise<MultipleWorkerAgreementsActionSelectedWorkersDto[]> {
    return await firstValueFrom(this.workerAgreementService.getMultipleWorkerAgreementsActionSelectedWorkers(
      this.filters,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection
    ));
  }

  private async fetchMultipleWorkerAgreementsActionSelectedWorkerAgreements(): Promise<MultipleWorkerAgreementsActionSelectedWorkerAgreementDto[]> {
    return await firstValueFrom(this.workerAgreementService.getMultipleWorkerAgreementsActionSelectedWorkerAgreementIds(this.filters))
  }

  private resetData() {
    this.page = 1;
    this.dataSource.reset();
  }

  private checkViewportSize() {
    if (this.dataSource) {
      this.changeDetectorRef.detectChanges();
      this.viewport.checkViewportSize();
    }
  }

  private async upsertFilterPreset(): Promise<void> {
    const selectedPreset = this.filterPresets.find(fp => (this.selectedFilterPresetId && this.selectedFilterPresetId === fp.Id) || fp.Name === this.defaultFilterPresetName);

    const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

    if (selectedPreset) {
      if (filters) {
        await firstValueFrom(this.userService.updateFilterPreset(selectedPreset.Id, {
          Name: selectedPreset.Name,
          FilterPresetObject: filters
        }));
      } else {
        await firstValueFrom(this.userService.deleteFilterPreset(selectedPreset.Id));
      }
    } else if (filters) {
      await firstValueFrom(this.userService.addFilterPreset({
        Name: this.defaultFilterPresetName,
        FilterTypeId: FilterTypeEnum.WorkerAgreementsList,
        FilterPresetObject: filters
      }));
    } else {
      return;
    }
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.WorkerAgreementsList));
    this.selectedFilterPresetId = this.filterPresets?.find(fp => fp.IsSelected)?.Id;
  }

  private nonNullValues(obj): { [key: string]: unknown } {
    const properties = Object.entries(obj).filter(([key, value]) => value !== null && value && (!(value instanceof Array) || value.length));
    if (properties.length) {
      return Object.fromEntries(properties);
    }
  }

  public async saveFilterPresetButtonClick(): Promise<void> {
    if (this.isSavedFilterPreset) {

      const existingDefaultPreset = this.filterPresets.find(fp => fp.Name == this.defaultFilterPresetName && this.selectedFilterPresetId !== fp.Id);

      if (existingDefaultPreset) {
        await firstValueFrom(this.userService.deleteFilterPreset(existingDefaultPreset.Id));
      }

      this.selectedFilterPreset.Name = this.defaultFilterPresetName;
      await this.upsertFilterPreset();
    } else {
      var res = await firstValueFrom(this.dialog
        .open(FilterPresetNameFormDialogComponent, { panelClass: 'form-dialog' })
        .afterClosed());

      if (res) {
        if (this.selectedFilterPreset.Name === this.defaultFilterPresetName) {
          this.selectedFilterPreset.Name = res;
        }
        await this.upsertFilterPreset();
      }
    }
  }

  private async deselectFilterPreset(): Promise<void> {
    await firstValueFrom(this.userService.deselectFilterPreset(this.selectedFilterPresetId));
    this.selectedFilterPreset.IsSelected = false;
  }
}