import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MandateAgreementWorkedDayListFiltersComponent } from './mandate-agreement-worked-day-list-filters/mandate-agreement-worked-day-list-filters.component';
import { MandateAgreementWorkedDayListComponent } from './mandate-agreement-worked-day-list/mandate-agreement-worked-day-list.component';
import { SharedModule } from '../shared/shared.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VirtualScrollModule
  ],
  declarations: [
    MandateAgreementWorkedDayListComponent,
    MandateAgreementWorkedDayListFiltersComponent
  ],
  exports: [
    MandateAgreementWorkedDayListComponent
  ]
})
export class MandateAgreementWorkedDayListModule { }
