import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SuccessfullyApprovedAgreementComponent } from '../worker-agreements/successfully-approved-agreement/successfully-approved-agreement.component';
import { WorkerAgreementDetailsComponent } from '../worker-agreements/worker-agreement-details/worker-agreement-details.component';
import { WorkerAgreementsListComponent } from '../worker-agreements/worker-agreements-list/worker-agreements-list.component';
import { AgreementNotReadyComponent } from './agreement-not-ready/agreement-not-ready.component';
import { VoidAgreementModalComponent } from './worker-agreement-details/void-agreement-modal/void-agreement-modal.component';
import { ApproveAgreementVoidingComponent } from './approve-agreement-voiding/approve-agreement-voiding.component';
import { WorkerAgreementsListFiltersComponent } from './worker-agreements-list-filters/worker-agreements-list-filters.component';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { SuccessfullySendToApprovalComponent } from './successfully-send-to-approval/successfully-send-to-approval.component';
import { WorkerAgreementTerminationModule } from '../worker-agreement-termination/worker-agreement-termination.module';
import { AgreementFormComponent } from './agreement-form/agreement-form.component';
import { AgreementPickerComponent } from './agreement-picker/agreement-picker.component';
import { MultipleAgreementFormComponent } from './multiple-agreement-form/multiple-agreement-form.component';
import { SendMultipleWorkerAgreementsToApprovalSignaturePadComponent } from './send-multiple-worker-agreements-to-approval-signature-pad/send-multiple-worker-agreements-to-approval-signature-pad.component';
import { WorkerAgreementListWithWorkedDaysComponent } from './worker-agreement-list-with-worked-days/worker-agreement-list-with-worked-days.component';
import { WorkerAgreementListWithWorkedDaysDataSource } from './worker-agreement-list-with-worked-days/worker-agreement-list-with-worked-days.datasource';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { WorkerAgreementListTabsContainerComponent } from './worker-agreement-list-tabs-container/worker-agreement-list-tabs-container.component';
import { EmploymentConditionsConfirmationModule } from '../employment-conditions-confirmation';
import { MandateAgreementWorkedDayListModule } from '../mandate-agreement-worked-day-list/mandate-agreement-worked-day-list.module';

@NgModule({
  imports: [
    SharedModule,
    CustomControlsModule,
    WorkerAgreementTerminationModule,
    VirtualScrollModule,
    EmploymentConditionsConfirmationModule,
    MandateAgreementWorkedDayListModule
  ],
  declarations: [
    AgreementFormComponent,
    WorkerAgreementDetailsComponent,
    WorkerAgreementsListComponent,
    AgreementNotReadyComponent,
    VoidAgreementModalComponent,
    ApproveAgreementVoidingComponent,
    WorkerAgreementsListFiltersComponent,
    SuccessfullySendToApprovalComponent,
    SuccessfullyApprovedAgreementComponent,
    AgreementPickerComponent,
    MultipleAgreementFormComponent,
    SendMultipleWorkerAgreementsToApprovalSignaturePadComponent,
    WorkerAgreementListWithWorkedDaysComponent,
    WorkerAgreementListTabsContainerComponent,
  ],
  exports: [AgreementNotReadyComponent, AgreementPickerComponent, WorkerAgreementListWithWorkedDaysComponent],
  providers: [WorkerAgreementListWithWorkedDaysDataSource],
})
export class WorkerAgreementsModule { }
