<div class="declarations-list container">
  <h1 class="header">{{ 'DeclarationsList.DeclarationsList' | translate }}</h1>

  <div class="content">
    <div #filters class="content-card filters">
      <div class="top">
        <div class="top__left">
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">{{ 'DeclarationsList.MultipleActions' | translate }}</button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="shareSelectedDeclarations()"
              [disabled]="!isShareSelectedDeclarationsEnabled()"
              [visibleWithPermissions]="['ShareAllDeclarations', 'ShareEmployerObjectDeclarations']"
              [matTooltip]="'DeclarationsList.ShareSelectedDeclarationsTooltip' | translate"
            >
              <span>{{ 'DeclarationsList.ShareSelectedDeclarations' | translate }}</span>
            </button>
            <button
              mat-menu-item
              (click)="sendByPostSelectedDeclarations()"
              [disabled]="!isSendByPostSelectedDeclarationsEnabled()"
              [visibleWithPermissions]="['SendByPostAllDeclarations', 'SendByPostEmployerObjectDeclarations']"
              [matTooltip]="'DeclarationsList.SendByPostTooltip' | translate"
            >
              <span>{{ 'DeclarationsList.SendByPost' | translate }} </span>
            </button>
          </mat-menu>
        </div>
        <div class="top__right">
          <mat-form-field *ngIf="showFilterPresetSelector" [@inOutAnimation] appearance="outline" class="filter-preset-selector">
            <mat-label>Filtr</mat-label>
            <mat-select [value]="selectedFilterPresetId" (valueChange)="setSelectedFilterPresetId($event)">
              <ng-container *ngFor="let preset of filterPresets">
                <mat-option [value]="preset.Id">{{
                  preset.Name === defaultFilterPresetName ? ('DefaultFilterPresetName' | translate) : preset.Name
                }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <button (click)="resetFilters()" class="top__button top__button-clear">
            <mat-icon>close</mat-icon>
            {{ 'Reset' | translate }}
          </button>
          <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
            <mat-icon>filter_alt</mat-icon>
            {{ 'Filter' | translate }}
          </button>
        </div>
      </div>
      <app-declarations-list-filters *ngIf="areFiltersExpanded" [formGroup]="filtersFormGroup" (filterGridData)="filterData($event)">
        <div class="favorite-button-row-container">
          <button (click)="saveFilterPresetButtonClick()" class="favorite-button">
            <ng-container *ngIf="isSavedFilterPreset; then unsaveBtn; else saveBtn"></ng-container>
            <ng-template #saveBtn>
              <mat-icon>bookmark_border</mat-icon>
              {{ 'SaveFilter' | translate }}
            </ng-template>
            <ng-template #unsaveBtn>
              <mat-icon>bookmark</mat-icon>
              {{ 'UnsaveFilter' | translate }}
            </ng-template>
          </button>
        </div>
      </app-declarations-list-filters>
    </div>

    <div class="content-card" [ngClass]="{ 'filters-opened': areFiltersExpanded }">
      <div #tableContainer class="wrapper custom-table">
        <cdk-virtual-scroll-viewport gridTableVirtualScroll (scrolledIndexChange)="nextBatch($event)">
          <mat-table
            class="table"
            matSort
            [matSortActive]="defaultSortColumn"
            [matSortDirection]="defaultSortDirection"
            matSortDisableClear
            [dataSource]="dataSource"
            (matSortChange)="onSortChange()"
          >
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  appearance="outline"
                  (change)="onSelectAllChxChange()"
                  [checked]="dataSource.selection.hasValue() && isAllSelected()"
                  [indeterminate]="dataSource.selection.hasValue() && !isAllSelected()"
                  [matTooltip]="'SelectAll' | translate"
                  matTooltipPosition="above"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="onRowChxChange($event, row)"
                  [checked]="dataSource.selection.isSelected(row)"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Company">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'Company' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.Company">{{ element.Company }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Employers">
              <mat-header-cell *matHeaderCellDef mat-sort-header="Employer" class="hidden-sort">
                {{ 'Employer' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span *ngIf="element.Employers" [matTooltip]="element.Employers.join(' | ')">{{ element.Employers.join(' | ') }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="FullName">
              <mat-header-cell *matHeaderCellDef mat-sort-header="LastName" class="hidden-sort">
                {{ 'RegistryFullUserName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a
                  class="user"
                  [routerLink]="['/profile/' + element.WorkerId]"
                  [matTooltip]="'WL-ShowWokerProfile' | translate"
                  matTooltipPosition="above"
                  >{{ element.LastName + ' ' + element.FirstName }}
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Pesel">
              <mat-header-cell *matHeaderCellDef mat-sort-header="DateOfBirth" class="hidden-sort">
                {{ 'Pesel' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.Pesel">{{ element.Pesel }}</span></mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="WorkerStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WorkerStatus' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="label"
                  [class.label--green]="element.WorkerStatusId === workerStatusEnum.Activated"
                  [class.label--orange]="element.WorkerStatusId === workerStatusEnum.ActivationInProgress"
                  [class.label--yellow]="element.WorkerStatusId === workerStatusEnum.Created"
                >
                  <span class="label__dot"></span>
                  <span class="label__name">{{ element.WorkerStatus }}</span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DeclarationType">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'DeclarationsList.DeclarationType' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.DeclarationType">{{ element.DeclarationType }}</span></mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="DeclarationStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'DeclarationsList.DeclarationStatus' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="label"
                  [class.label--green]="element.DeclarationStatusId === declarationStatusEnum.Shared"
                  [class.label--orange]="element.DeclarationStatusId === declarationStatusEnum.Draft"
                  [class.label--yellow]="element.DeclarationStatusId === declarationStatusEnum.Corrected"
                  [class.label--blue]="element.DeclarationStatusId === declarationStatusEnum.SentByPost"
                >
                  <span class="label__dot"></span>
                  <span class="label__name">{{ element.DeclarationStatus }}</span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Year">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'Year' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.Year">{{ element.Year }}</span></mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="DownloadedDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="FirstDownload" class="hidden-sort">
                {{ 'DeclarationsList.DownloadedDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span [matTooltip]="element.FirstDownloadTooltip">{{ element.FirstDownload | date : 'yyyy-MM-dd' }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef>{{ 'DeclarationsList.Actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div fxLayout="row">
                  <ng-container>
                    <button
                      mat-icon-button
                      (click)="onShowDeclarationBtnClick(element.DeclarationId, element.FileName)"
                      [matTooltip]="'DeclarationsList.FilePreviewTooltip' | translate"
                      matTooltipPosition="above"
                    >
                      <mat-icon class="material-icons-outlined">find_in_page</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="onDownloadDeclarationBtnClick(element.DeclarationId, element.FileName)"
                      [matTooltip]="'DeclarationsList.FileDownloadTooltip' | translate"
                      matTooltipPosition="above"
                    >
                      <mat-icon class="material-icons-outlined">file_download</mat-icon>
                    </button>
                  </ng-container>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="progress">
              <mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
                <mat-progress-bar [mode]="dataSource.isLoading ? 'indeterminate' : 'determinate'" class="table-progress" color="accent">
                </mat-progress-bar>
              </mat-header-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky" [style.top.px]="offset$ | async"></mat-header-row>
            <mat-header-row *matHeaderRowDef="['progress']; sticky: sticky" class="progress" [style.top.px]="(offset$ | async) + 56">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <app-item-counter class="item-counter" [position]="scrollItemPosition" [length]="dataSource.absoluteDataLength"></app-item-counter>
  </div>
</div>
