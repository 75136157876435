import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, first, firstValueFrom, map, Observable, of, shareReplay, timeout } from 'rxjs';
import { Permission } from 'src/app/common/enums';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerProfileAgreementsPageConfigDto } from 'src/app/models/dtos/worker-profile-agreements-page-config-dto';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { SubSink } from 'SubSink'

enum ListType {
  WorkerAgreements = 'WP-Agreements',
  EmploymentConditionsConfirmations = 'WP-EmploymentConditionsConfirmation',
  MandateTemporaryCertificates = 'MandateAgreementWorkedDayList.MandateAgreementWorkedDayList'
}

interface Navigation {
  listType: ListType,
  link: string,
  canActivate$: Observable<boolean>
}

@Component({
  selector: 'worker-agreements',
  templateUrl: './worker-agreements.component.html',
})
export class WorkerAgreementsComponent implements OnInit, OnDestroy {
  profile: WorkerProfile;
  hasAnyEmploymentConditionConfirmation: boolean;
  hasAnyMandateAgreementWorkedDay: boolean;
  config$: Observable<WorkerProfileAgreementsPageConfigDto>;
  showNavigation$: Observable<boolean>;

  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;
  navigation: Navigation[];

  private readonly subs = new SubSink();

  constructor(
    private route: ActivatedRoute,
    private workerService: WorkerService,
    private authService: AuthService,
    private router: Router
  ) {
    this.profile = this.route.snapshot.data.workerProfile;
  }

  ngOnInit(): void {
    this.getConfig();
    this.initNavigation();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async onTabChanged(event) {
    const navigation = await firstValueFrom(combineLatest(this.navigation.map(n => n.canActivate$.pipe(map(canActivate => ({ canActivate, link: n.link }))))).pipe(map(ns => ns.filter(n => n.canActivate))));

    this.router.navigateByUrl(navigation[event.index].link);
  }

  private getConfig() {
    this.config$ = this.workerService
      .getWorkerProfileAgreementsPageConfig(this.profile.WorkerId)
      .pipe(first())
      .pipe(shareReplay());
  }

  private initNavigation() {
    this.navigation = [
      {
        listType: ListType.WorkerAgreements,
        link: `profile/${this.profile.WorkerId}/workerAgreements`,
        canActivate$: of(true)
      },
      {
        listType: ListType.EmploymentConditionsConfirmations,
        link: `profile/${this.profile.WorkerId}/workerAgreements/employmentConditionsConfirmations`,
        canActivate$: this.config$.pipe(map(config => config.HasAnyEmploymentConditionConfirmation && (this.profile.AuthServerUserId == this.authService.authServerUserId || this.authService.hasPermission(Permission.ViewMyExternalWorkerEmploymentConditionsConfirmation) || this.authService.hasPermission(Permission.ViewAllExternalWorkerEmploymentConditionsConfirmation))))
      },
      {
        listType: ListType.MandateTemporaryCertificates,
        link: `profile/${this.profile.WorkerId}/workerAgreements/mandateTemporaryCertificates`,
        canActivate$: this.config$.pipe(map(config => config.HasAnyMandateAgreementWorkedDay && (this.profile.AuthServerUserId == this.authService.authServerUserId || this.authService.hasPermission(Permission.ViewMyExternalWorkerMandateAgreementWorkedDays) || this.authService.hasPermission(Permission.ViewAllMandateAgreementWorkedDays))))
      }
    ];

    this.showNavigation$ = combineLatest(this.navigation.map(n => n.canActivate$))
      .pipe(map(activates => activates.filter(activate => activate).length > 1))
      .pipe(shareReplay());

    this.subs.sink = this.showNavigation$.subscribe(() => {
      setTimeout(() => {
        if (this.tabGroup) {
          this.tabGroup.selectedIndex = this.navigation.findIndex(n => this.router.url.endsWith(n.link));
        }
      });
    })
  }
}