import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { getBirthDateFromPesel } from 'src/app/common/utils/get-birth-date-from-pesel';
import { setFormControlValidators } from 'src/app/common/utils/set-form-control-validators';
import { AssignWorkerToEmployerObjectRequest } from 'src/app/contracts/requests/assign-worker-to-employer-object-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { IdentityDocumentItem } from 'src/app/models/IdentityDocumentItem';
import { EmployerObjectDictionaryDto } from 'src/app/models/dtos/employer-object-dictionary-dto';
import { IdentityDocumentTypeEnum } from 'src/app/models/enums/IdentityDocumentTypeEnum';
import { ExternalWorker } from 'src/app/models/ExternalWorker';
import { Language } from 'src/app/models/Language';
import { PhoneCode } from 'src/app/models/PhoneCode';
import { CreateExternalWorkerResult } from 'src/app/models/results/CreateExternalWorkerResult';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { FirstNameValidator, SimplifiedFirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { LastNameValidator, SimplifiedLastNameValidator } from 'src/app/shared/validators/last-name.validator';
import { maxDateValidator } from 'src/app/shared/validators/max-date.validator';
import { peselValidator } from 'src/app/workers/worker-form/pesel.validator';
import { ModuleName, ModulePermissionService } from 'src/app/subscription-package';
import { Country } from 'src/app/models/enums';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { animate, style, transition, trigger } from '@angular/animations';
import { CompanyDto } from 'src/app/models/dtos/CompanyDto';
import { LegalizationDocumentDto } from 'src/app/models/dtos/legalization-document-files-dto';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { AuthService } from 'src/app/core/authentication/auth.service';
import * as moment from 'moment';
import { EmployerDictionaryDto } from 'src/app/models/dtos/employer-dictionary-dto';

@Component({
  selector: 'app-add-worker',
  templateUrl: './add-worker.component.html',
  styleUrls: ['./add-worker.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ maxHeight: 0, opacity: 0 }), animate('1s ease-out', style({ maxHeight: 300, opacity: 1 }))]),
      transition(':leave', [style({ maxHeight: 300, opacity: 1 }), animate('1s ease-in', style({ maxHeight: 0, opacity: 0 }))]),
    ]),
  ],
})
export class AddWorkerComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly moduleNames = ModuleName;
  worker: ExternalWorker = null;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  recruiterModuleEnabled: boolean;
  timesheetModuleEnabled: boolean;
  hasLegalization: boolean;
  legalizationDocuments: LegalizationDocumentDto[] = [];

  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef<HTMLInputElement>;

  listOfEmployers$: Observable<EmployerDictionaryDto[]> = this.dictionaryService.getEmployersList();
  listOfCompanies$: Observable<CompanyDto[]> = this.dictionaryService.getCompaniesList();
  listOfEmployerObjects$: Observable<EmployerObjectDictionaryDto[]> = null;
  listOfRecruiters$: Observable<DictionaryItem[]> = this.dictionaryService.getRecruiters();
  listOfPhoneCodes$: Observable<PhoneCode[]> = this.dictionaryService.getPhoneCodes();
  listOfEmploymentTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getActiveEmploymentTypes();

  identityDocumentTypes$: Observable<IdentityDocumentItem[]> = this.dictionaryService
    .getIdentityDocumentTypes()
    .pipe(tap((items) => (this.identityDocumentTypes = items)));
  private identityDocumentTypes: IdentityDocumentItem[] = [];

  selectedCompany: CompanyDto;
  selectedEmployer: EmployerDictionaryDto;
  public selectedEmployerObject: EmployerObjectDictionaryDto;
  selectedRecruiter: DictionaryItem;
  selectedPhoneCode: PhoneCode;
  isMpkActive: boolean;
  employerHasDrivers: boolean;

  employmentTypes: DictionaryItem[];
  companiesList: DictionaryItem[];

  isOptionalNipOrPeselInfoVisible = false;
  showDecisionButton = false;
  canDeleteUnsavedLegalizationDocuments = true;

  public displayValue = (value: DictionaryItem): string | undefined => value?.Name;

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('employerObjectInput') protected employerObjectSelectRef: ElementRef;
  @ViewChild('employerObjectInput', { read: MatAutocompleteTrigger }) employerObjectSelect: MatAutocompleteTrigger;

  private readonly timeBetweenInput = 300;
  private readonly unsubscribe$ = new Subject<void>();

  private readonly emailValidators = [Validators.required, Validators.maxLength(50), Validators.email];
  private readonly phoneCodeValidators = [Validators.required];
  private readonly phoneNumberValidators = [Validators.required, Validators.maxLength(9)];

  private readonly firstNameValidators = [Validators.required, Validators.maxLength(50), FirstNameValidator()]
  private readonly secondNameValidators = [Validators.maxLength(50), FirstNameValidator()];
  private readonly lastNameValidators = [Validators.required, Validators.maxLength(50), LastNameValidator()];

  private readonly simplifiedFirstNameValidators = [Validators.required, Validators.maxLength(50), SimplifiedFirstNameValidator()]
  private readonly simplifiedSecondNameValidators = [Validators.maxLength(50), SimplifiedFirstNameValidator()];
  private readonly simplifiedLastNameValidators = [Validators.required, Validators.maxLength(50), SimplifiedLastNameValidator()];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workerService: WorkerService,
    private dictionaryService: DictionaryService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
    private modulePermissionService: ModulePermissionService,
    private authService: AuthService,
  ) { }

  get Language() {
    return Language;
  }
  get email() {
    return this.secondFormGroup.get('email') as UntypedFormControl;
  }
  get phoneNumber() {
    return this.secondFormGroup.get('phoneNumber') as UntypedFormControl;
  }
  get phoneCode() {
    return this.secondFormGroup.get('phoneCode') as UntypedFormControl;
  }
  get firstName() {
    return this.firstFormGroup.get('firstName') as UntypedFormControl;
  }
  get secondName() {
    return this.firstFormGroup.get('secondName') as UntypedFormControl;
  }
  get lastName() {
    return this.firstFormGroup.get('lastName') as UntypedFormControl;
  }
  get fullName() {
    return this.firstName.value + ' ' + (this.secondName.value ?? '') + ' ' + this.lastName.value;
  }
  get employmentTypeId() {
    return this.firstFormGroup.get('employmentTypeId') as UntypedFormControl;
  }
  get employmentType(): string {
    return !!this.employmentTypeId.value ? this.employmentTypes.find((et) => et.Id === this.employmentTypeId.value)?.Name : '';
  }
  get employer() {
    return this.secondFormGroup.get('employer') as UntypedFormControl;
  }
  get company() {
    return this.secondFormGroup.get('company') as UntypedFormControl;
  }
  get employerObject() {
    return this.secondFormGroup.get('employerObject') as UntypedFormControl;
  }
  get isForeigner() {
    return this.firstFormGroup.get('isForeigner') as UntypedFormControl;
  }
  get language() {
    return this.firstFormGroup.get('language') as UntypedFormControl;
  }
  get recruiterId() {
    return this.secondFormGroup.get('recruiterId') as UntypedFormControl;
  }
  get isInvitedToFillWorkerForm() {
    return this.secondFormGroup.get('isInvitedToFillWorkerForm') as UntypedFormControl;
  }
  get noAccount() {
    return this.secondFormGroup.get('noAccount') as UntypedFormControl;
  }
  get dateOfBirth() {
    return this.firstFormGroup.get('dateOfBirth') as UntypedFormControl;
  }
  get pesel() {
    return this.firstFormGroup.get('pesel') as UntypedFormControl;
  }
  get nip() {
    return this.firstFormGroup.get('nip') as UntypedFormControl;
  }
  get identityDocumentTypeId() {
    return this.firstFormGroup.get('identityDocumentTypeId') as UntypedFormControl;
  }
  get documentSerial() {
    return this.firstFormGroup.get('documentSerial') as UntypedFormControl;
  }
  get documentNumber() {
    return this.firstFormGroup.get('documentNumber') as UntypedFormControl;
  }
  get selectedIdentityDocumentType() {
    return this.identityDocumentTypes?.find((i) => i.Id === this.identityDocumentTypeId.value);
  }
  get numberLength(): number {
    return this.firstFormGroup.get('identityDocumentTypeId').value === IdentityDocumentTypeEnum.Passport ? 8 : 6;
  }
  get serialLength(): number {
    return this.firstFormGroup.get('identityDocumentTypeId').value === IdentityDocumentTypeEnum.Passport ? 8 : 3;
  }
  get isTimesheetSettledIndependently() {
    return this.secondFormGroup.get('isTimesheetSettledIndependently') as UntypedFormControl;
  }
  get mpk() {
    return this.secondFormGroup.get('mpk') as UntypedFormControl;
  }
  get disabledNameValidation() {
    return this.firstFormGroup.get('disabledNameValidation') as UntypedFormControl;
  }

  get isDriver() {
    return this.secondFormGroup.get('isDriver') as UntypedFormControl;
  }

  async ngOnInit(): Promise<void> {
    this.buildFormGroup();
    this.onIsForeignerChange();
    this.onEmploymentTypeChange();
    this.onEmployerChange();
    this.onPhoneCodeChange();
    this.onNoAccountChange();
    this.onIsTimesheetSettlementIndependentlyChange();
    this.onPeselChange();
    this.onNipChange();
    this.onIdentityDocumentTypeChange();
    this.onDropdownSelection();
    this.onPassportDocumentSerialChange();
    this.onDisableNameValidationChange()
    await this.setInitialValues();
  }

  ngAfterViewInit() {
    this.stepper.selectionChange.subscribe((event: StepperSelectionEvent) => {
      if (event.previouslySelectedIndex === 0 && event.selectedIndex === 1 && !this.worker) {
        this.getExistingExternalWorker();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  setSelectedEmployer(employer: DictionaryItem): void {
    if (employer) {
      this.employerObject.enable({ emitEvent: false });
      this.employerObject.reset(null, { emitEvent: false });
    }
  }

  identityDocumentTypeFilter(identityDocumentTypes: DictionaryItem[]) {
    return this.isForeigner.value && !!identityDocumentTypes ? identityDocumentTypes.filter((i) => i.Id == IdentityDocumentTypeEnum.Passport) : null;
  }

  addLegalizationDocument(legalizationDocument: LegalizationDocumentDto): void {
    legalizationDocument.CreatedOn = moment().toDate();
    legalizationDocument.StartDate = legalizationDocument.StartDate ? moment(legalizationDocument.StartDate).toDate() : null;
    legalizationDocument.EndDate = legalizationDocument.EndDate ? moment(legalizationDocument.EndDate).toDate() : null;
    var alreadyAddedDocument = this.getAlreadyAddedDocument(legalizationDocument);

    if (!!alreadyAddedDocument) {
      alreadyAddedDocument.Files.push(...legalizationDocument.Files)
      alreadyAddedDocument.Description = !!legalizationDocument.Description ?
        alreadyAddedDocument.Description + ` ${legalizationDocument.Description}` :
        alreadyAddedDocument.Description;
    }
    else {
      this.legalizationDocuments.push(legalizationDocument);
    }
  }

  deleteLegalizationDocument(legalizationDocument: LegalizationDocumentDto) {
    var alreadyAddedDocument = this.getAlreadyAddedDocument(legalizationDocument);
    var index = alreadyAddedDocument.Files.indexOf(legalizationDocument.Files[0]);
    if (index > -1) {
      alreadyAddedDocument.Files.splice(index, 1);
    }

    if (!alreadyAddedDocument.Files.length) {
      var documentIndex = this.legalizationDocuments.indexOf(alreadyAddedDocument);
      if (documentIndex > -1) {
        this.legalizationDocuments.splice(documentIndex, 1);
      }
    }
  }

  onSubmit(): void {
    this.firstFormGroup.markAllAsTouched();
    this.secondFormGroup.markAllAsTouched();

    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid) return;
    if (!this.pesel && !this.nip) return;

    this.spinner.show();

    if (!this.worker) {
      this.createWorker();
    } else {
      this.assignToEmployerObject();
    }
  }

  private createWorker() {
    const secondName: string = this.secondName.value !== '' ? this.secondName.value : null;
    const externalWorker: ExternalWorker = {
      Email: this.email.value,
      PhoneCodeId: this.phoneCode.value,
      PhoneNumber: this.phoneNumber.value,
      FirstName: this.firstName.value,
      SecondName: secondName,
      LastName: this.lastName.value,
      EmploymentTypeId: this.employmentTypeId.value,
      EmployerObjectId: this.employerObject.value.Id,
      CompanyId: this.company.value,
      EmployerId: this.employer.value,
      Language: this.language.value,
      RecruiterId: this.recruiterModuleEnabled ? this.recruiterId.value : undefined,
      IsForeigner: this.isForeigner.value,
      NoAccount: this.noAccount.value,
      DateOfBirth: this.dateOfBirth.value,
      Pesel: this.pesel.value,
      Nip: this.nip.value,
      IdentityDocumentTypeId: this.identityDocumentTypeId.value,
      DocumentSerial: this.documentSerial.value,
      DocumentNumber: this.documentNumber.value,
      IsTimesheetSettledIndependently: this.timesheetModuleEnabled && this.isTimesheetSettledIndependently.value,
      HasLegalization: this.hasLegalization,
      LegalizationDocuments: this.legalizationDocuments,
      DisabledNameValidation: this.disabledNameValidation.value,
      IsDriver: this.isDriver.value
    };

    this.workerService
      .addExternalWorker(externalWorker)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res: CreateExternalWorkerResult) => {
        this.router.navigate(['/successfullyAddedWorker/' + res.Id], {
          queryParams: { fullname: res.FullName, workerFormId: res.WorkerFormId, hasLegalization: this.hasLegalization },
        });
      });
  }

  private assignToEmployerObject() {
    const request: AssignWorkerToEmployerObjectRequest = {
      EmploymentTypeId: this.employmentTypeId.value,
      EmployerId: this.employer.value,
      EmployerObjectId: this.employerObject.value.Id,
      Email: this.email.value,
      PhoneCodeId: this.phoneCode.value,
      PhoneNumber: this.phoneNumber.value,
      NoAccount: this.noAccount.value,
      IsTimesheetSettledIndependently: this.isTimesheetSettledIndependently.value,
    };

    this.workerService
      .assignWorkerToEmployerObject(this.worker.Id, request)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((_) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyAssignedWorkerToEmployerObject);
        this.router.navigate(['/workerAgreements']);
      });
  }

  private buildFormGroup() {
    this.firstFormGroup = this.formBuilder.group({
      firstName: ['', this.firstNameValidators],
      secondName: ['', this.secondNameValidators],
      lastName: ['', this.lastNameValidators],
      isForeigner: [false, [Validators.required]],
      language: [this.Language.Polish],
      dateOfBirth: [{ value: null, disabled: true }, [maxDateValidator(new Date())]],
      pesel: [null, [Validators.required, peselValidator]],
      nip: [null, [Validators.required]],
      identityDocumentTypeId: [null],
      documentSerial: [null],
      documentNumber: [null],
      employmentTypeId: [null, [Validators.required]],
      disabledNameValidation: [false],
    });
    this.secondFormGroup = this.formBuilder.group({
      email: ['', this.emailValidators],
      phoneCode: [null, this.phoneCodeValidators],
      phoneNumber: [null, this.phoneNumberValidators],
      employer: [null, [Validators.required]],
      employerObject: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
      company: [null],
      mpk: [{ value: null, disabled: true }],
      recruiterId: [null],
      isInvitedToFillWorkerForm: [{ value: true, disabled: true }],
      noAccount: [{ value: false, disabled: true }],
      isTimesheetSettledIndependently: [true],
      isDriver: [false]
    });
  }

  private getExistingExternalWorker() {
    this.spinner.show();

    const firstFormValues = this.firstFormGroup.value;
    const request = {
      Pesel: firstFormValues.pesel,
      Nip: firstFormValues.nip,
      IdentityDocumentTypeId: firstFormValues.identityDocumentTypeId,
      DocumentSerial: firstFormValues.documentSerial,
      DocumentNumber: firstFormValues.documentNumber,
    };

    this.workerService
      .getExistingExternalWorker(request)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((worker: ExternalWorker) => {
        if (!worker) return;

        this.openAlertDialog(Messages.WorkerAlreadyExists);
        this.setExistingWorkerData(worker);
      });
  }

  private setExistingWorkerData(worker: ExternalWorker) {
    this.worker = worker;
    this.firstFormGroup.patchValue(
      {
        firstName: worker.FirstName,
        secondName: worker.SecondName,
        lastName: worker.LastName,
        pesel: worker.Pesel,
        nip: worker.Nip,
        identityDocumentTypeId: worker.IdentityDocumentTypeId,
        documentSerial: worker.DocumentSerial,
        documentNumber: worker.DocumentNumber,
        isForeigner: worker.IsForeigner,
        language: worker.Language,
        disabledNameValidation: worker.DisabledNameValidation
      },
      { emitEvent: false },
    );

    this.firstFormGroup.disable({ emitEvent: false });
    this.secondFormGroup.patchValue({
      email: worker.Email,
      phoneCode: worker.PhoneCodeId,
      phoneNumber: worker.PhoneNumber,
      recruiterId: worker.RecruiterId,
      noAccount: worker.NoAccount,
      isTimesheetSettledIndependently: worker.IsTimesheetSettledIndependently,
      isDriver: worker.IsDriver,
    });

    if (!worker.NoAccount) this.noAccount.enable({ emitEvent: false });
    if (worker.Email) this.email.disable({ emitEvent: false });
    if (worker.PhoneNumber) this.phoneNumber.disable({ emitEvent: false });
    if (worker.RecruiterId) this.recruiterId.disable({ emitEvent: false });
    if (worker.Email && worker.PhoneNumber) {
      this.noAccount.disable({ emitEvent: false });
      this.isTimesheetSettledIndependently.disable({ emitEvent: false });
    }
  }

  private onPhoneCodeChange() {
    combineLatest([this.phoneCode.valueChanges, this.listOfPhoneCodes$])
      .pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$))
      .subscribe(([changedPhoneCode, phoneCodes]) => {
        const phoneCode = phoneCodes.find((phoneCode) => phoneCode.Id == changedPhoneCode);
        this.phoneNumberInput.nativeElement.maxLength = phoneCode?.MaxLength;
        this.phoneNumber.clearValidators();
        this.phoneNumber.setValidators([Validators.required, Validators.minLength(phoneCode?.MinLength), Validators.maxLength(phoneCode?.MaxLength)]);
        this.phoneNumber.updateValueAndValidity();
      });
  }

  private onDisableNameValidationChange() {
    this.disabledNameValidation.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isChecked: boolean) => {

      if (isChecked) {
        setFormControlValidators(this.firstName, this.simplifiedFirstNameValidators);
        setFormControlValidators(this.secondName, this.simplifiedSecondNameValidators);
        setFormControlValidators(this.lastName, this.simplifiedLastNameValidators);
      } else {
        setFormControlValidators(this.firstName, this.firstNameValidators);
        setFormControlValidators(this.secondName, this.secondNameValidators);
        setFormControlValidators(this.lastName, this.lastNameValidators);
      }
    });
  }

  private onIsForeignerChange() {
    this.isForeigner.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isForeigner: boolean) => {
      this.language.setValue(isForeigner ? this.Language.Ukrainian : this.Language.Polish);

      if (isForeigner) {
        setFormControlValidators(this.identityDocumentTypeId, [
          Validators.required,
          Validators.pattern(IdentityDocumentTypeEnum.Passport.toString()),
        ]);
        setFormControlValidators(this.company, [Validators.required]);
        this.identityDocumentTypeId.setValue(IdentityDocumentTypeEnum.Passport)
      } else {
        setFormControlValidators(this.identityDocumentTypeId, []);
        setFormControlValidators(this.company, []);
        this.company.setValue(null)
        this.identityDocumentTypeId.setValue(null);
      }

      this.setPeselValidator();
      this.setNipValidator();
      this.setIdentityDocumentValidators();
      this.setOptionalNipOrPeselInfoVisibilty();
    });
  }

  private onEmploymentTypeChange() {
    this.employmentTypeId.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((employmentTypeId: number) => {
      this.setPeselValidator();
      this.setNipValidator();
      this.setIdentityDocumentValidators();
      this.setOptionalNipOrPeselInfoVisibilty();
    });
  }

  private onEmployerChange() {
    this.employer.statusChanges.pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.employer.valid) {
        this.employerObject.enable({ emitEvent: false });
      } else {
        this.employerObject.reset(null, { emitEvent: false });
        this.employerObject.disable({ emitEvent: false });
      }
    });

    this.listOfEmployerObjects$ = this.employer.valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      switchMap((_) => {
        if (this.employer.value) {
          return this.dictionaryService.getEmployerObjectsByEmployerId(this.employer.value).pipe(
            tap((items) => {
              if (items.length === 1) {
                this.employerObject.enable();
                this.employerObject.setValue(items[0]);
                this.mpk.setValue(items[0].Mpk);
                this.isMpkActive = items[0].IsMpkActive;
              } else if (!items.length) {
                this.openAlertDialog(Messages.ThereIsNoEmployerObjectForThisEmployer);
              }
              else {
                this.employerObjectSelectRef?.nativeElement.focus();
                this.employerObjectSelect.openPanel();
              }
            }),
          );
        } else {
          return of([]);
        }
      }),
      tap(employerObjects => {
        if (!employerObjects.length) {
          this.openAlertDialog(Messages.ThereIsNoEmployerObjectForThisEmployer);
        }
      }),
      switchMap(employerObjects => this.employerObject.valueChanges.pipe(
        startWith(undefined),
        debounceTime(this.timeBetweenInput),
        takeUntil(this.unsubscribe$),
        map(_ => (!this.employerObject.value?.toLowerCase || employerObjects === null || employerObjects.length === 1)
          ? employerObjects
          : employerObjects.filter(item => item.Name?.toLowerCase().includes(this.employerObject.value?.toLowerCase()))),
        tap(employerObjects => {
          const res = employerObjects.find(employerObject => this.employerObject.value?.toLowerCase && employerObject.Name?.toLowerCase() === this.employerObject.value?.toLowerCase());

          if (res) {
            this.employerObject.setValue(res);
            this.employerObjectSelect.closePanel();
          }
        })
      ))
    );
  }

  private onNoAccountChange() {
    this.noAccount.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((noAccount: boolean) => {
      if (noAccount) {
        this.disableFormControl(this.email);
        this.disableFormControl(this.phoneCode);
        this.disableFormControl(this.phoneNumber);
        this.openAlertDialog(Messages.EmployeeWillNotFillTimesheetIndependently);
        if (typeof noAccount === 'boolean') {
          this.isTimesheetSettledIndependently.setValue(false);
          this.isTimesheetSettledIndependently.disable({ onlySelf: true, emitEvent: false });
        }
      } else {
        this.enableFormControl(this.email, this.emailValidators);
        this.enableFormControl(this.phoneCode, this.phoneCodeValidators);
        this.enableFormControl(this.phoneNumber, this.phoneNumberValidators);
        if (typeof noAccount === 'boolean') {
          this.isTimesheetSettledIndependently.enable({ onlySelf: true, emitEvent: false });
        }
      }
    });
  }

  private onIsTimesheetSettlementIndependentlyChange() {
    this.isTimesheetSettledIndependently.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isTimesheetSettledIndependently: boolean) => {
      if (typeof isTimesheetSettledIndependently !== 'boolean' || this.worker?.Email) return;

      if (isTimesheetSettledIndependently) {
        this.noAccount.disable({ onlySelf: true, emitEvent: false });
      } else {
        this.noAccount.enable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  private onPeselChange() {
    this.pesel.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((pesel: string) => {
      this.setNipValidator();
      this.setDateOfBirthControlState();

      if (pesel?.length < 6) {
        return;
      }

      this.dateOfBirth.setValue(getBirthDateFromPesel(pesel));
    });
  }

  private onNipChange() {
    this.nip.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setPeselValidator();
      this.setDateOfBirthControlState();
    });
  }

  private setDateOfBirthControlState() {
    if (!!this.nip.value && !this.pesel.value) {
      this.dateOfBirth.enable();
    } else {
      this.dateOfBirth.disable();
    }
  }

  private onIdentityDocumentTypeChange() {
    this.identityDocumentTypeId.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((identityDocumentTypeId: IdentityDocumentTypeEnum) => this.setIdentityDocumentValidators());
  }

  private setIdentityDocumentValidators() {
    const numberValidators = [Validators.maxLength(this.numberLength)];
    const identityDocumentTypeId = this.identityDocumentTypeId.value;

    if (identityDocumentTypeId === IdentityDocumentTypeEnum.Passport) {
      setFormControlValidators(this.documentNumber, numberValidators.concat(Validators.required));
      if (this.isForeigner.value) {
        setFormControlValidators(this.documentSerial, []);
      }
      this.documentSerial.updateValueAndValidity();
    } else {
      this.documentNumber.reset();
      this.documentSerial.reset();
      setFormControlValidators(this.documentNumber, []);
      setFormControlValidators(this.documentSerial, []);
    }
  }

  private onPassportDocumentSerialChange() {
    this.documentSerial.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((documentSerial: string) => {
      if (this.identityDocumentTypeId.value === IdentityDocumentTypeEnum.Passport) {
        const length = documentSerial && documentSerial.length;
        const validators = [Validators.maxLength(9 - length)];
        setFormControlValidators(this.documentNumber, validators.concat(Validators.required));
      }
    });
  }

  private setPeselValidator() {
    if (!!this.nip.value || (this.isForeigner.value && this.employmentTypeId.value === EmploymentType.MandateAgreement)) {
      if (!!this.pesel.validator) {
        setFormControlValidators(this.pesel, [peselValidator]);
        this.pesel.updateValueAndValidity();
      }
    } else if (!this.pesel.hasValidator(Validators.required)) {
      setFormControlValidators(this.pesel, [Validators.required, peselValidator]);
      this.pesel.updateValueAndValidity();
    }
  }

  private setNipValidator() {
    if (!!this.pesel.value || (this.isForeigner.value && this.employmentTypeId.value === EmploymentType.MandateAgreement)) {
      if (!!this.nip.validator) {
        setFormControlValidators(this.nip, [Validators.maxLength(10), Validators.minLength(10)]);
        this.nip.updateValueAndValidity();
      }
    } else if (!this.nip.hasValidator(Validators.required)) {
      setFormControlValidators(this.nip, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
      this.nip.updateValueAndValidity();
    }
  }

  private setOptionalNipOrPeselInfoVisibilty() {
    this.isOptionalNipOrPeselInfoVisible =
      this.isForeigner.value && this.employmentTypeId.value && this.employmentTypeId.value !== EmploymentType.MandateAgreement;
  }

  private openAlertDialog(message: string): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });
  }

  private onDropdownSelection() {
    combineLatest([this.listOfEmployers$, this.employer.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([employers, employerId]) => {
        this.selectedEmployer = employers.find((p) => p.Id == employerId);
        this.employerHasDrivers = this.selectedEmployer.HasDrivers;
      });

    combineLatest([this.listOfEmployerObjects$, this.employerObject.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([employerObjects, employerObject]) => {
        if (!employerObject?.Id || isNaN(employerObject.Id)) return;

        this.selectedEmployerObject = employerObjects.find(p => p.Id === employerObject.Id);

        if (!this.selectedEmployerObject) return;

        this.isMpkActive = this.selectedEmployerObject.IsMpkActive;

        if (this.selectedEmployerObject.IsMpkActive) {
          if (!this.selectedEmployerObject.Mpk) {
            this.openAlertDialog(Messages.EmployerObjectHasNoMpk);
            this.employerObject.setErrors({ employerObjectHasNoMpk: true });
            this.mpk.reset(null, { emitEvent: false });
          } else {
            this.mpk.setValue(this.selectedEmployerObject.Mpk);
          }
        }
      });

    combineLatest([this.listOfRecruiters$, this.recruiterId.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([recruiters, recruiterId]) => {
        this.selectedRecruiter = recruiters.find((p) => p.Id == recruiterId);
      });

    combineLatest([this.listOfPhoneCodes$, this.phoneCode.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([phoneCodes, phoneCodeId]) => {
        this.selectedPhoneCode = phoneCodes.find((p) => p.Id == phoneCodeId);
      });

    combineLatest([this.listOfCompanies$, this.company.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([companies, companyId]) => {
        this.selectedCompany = companies.find((p) => p.Id == companyId);
        this.hasLegalization = this.selectedCompany?.HasLegalization;
      });
  }

  private disableFormControl = (fc: UntypedFormControl) => {
    fc.clearValidators();
    fc.reset();
    fc.disable();
    fc.updateValueAndValidity();
  };

  private enableFormControl = (fc: UntypedFormControl, validators: any[]) => {
    fc.clearValidators();
    fc.enable();
    fc.setValidators(validators);
    fc.updateValueAndValidity();
  };

  private async setInitialValues() {
    const defaultCode = await firstValueFrom(this.listOfPhoneCodes$.pipe(map((codes) => codes.find((code) => code.Id === Country.Poland))));
    if (defaultCode && !this.phoneCode.value) {
      this.phoneCode.setValue(defaultCode.Id);
    }

    this.timesheetModuleEnabled = await this.modulePermissionService.isAllowedModule(this.moduleNames.Timesheets);
    this.recruiterModuleEnabled = await this.modulePermissionService.isAllowedModule(this.moduleNames.Recruiters);

    this.isTimesheetSettledIndependently.setValue(
      await this.dictionaryService.getIsTimesheetSettledIndependentlyDefaultChecked({
        institutionId: Number(this.authService.institutions[0]),
      }),
    );

    this.employmentTypes = await firstValueFrom(this.listOfEmploymentTypes$);
    this.companiesList = await firstValueFrom(this.listOfCompanies$);

    if (this.employmentTypes.length === 1) {
      this.employmentTypeId.setValue(this.employmentTypes[0].Id);
    }

    if (this.companiesList.length === 1) {
      this.company.setValue(this.companiesList[0].Id);
    }
  }

  private getAlreadyAddedDocument = (legalizationDocument: LegalizationDocumentDto): LegalizationDocumentDto =>
    this.legalizationDocuments.find(x =>
      x.LegalizationDocumentTypeId === legalizationDocument.LegalizationDocumentTypeId &&
      ((!x.StartDate && !legalizationDocument.StartDate) || moment(x.StartDate).isSame(legalizationDocument.StartDate)) &&
      ((!x.EndDate && !legalizationDocument.EndDate) || moment(x.EndDate).isSame(legalizationDocument.EndDate))
    );
}
