export enum ErrorCode {
  AccessDenied = 'AccessDenied',
  StreetIsInvalid = 'StreetIsInvalid',
  AgreementStatusIsInvalid = 'AgreementStatusIsInvalid',
  EmailAlreadyExists = 'EmailAlreadyExists',
  EmailAndPhoneNumberAlreadyExists = 'EmailAndPhoneNumberAlreadyExists',
  PhoneNumberAlreadyExists = 'PhoneNumberAlreadyExists',
  PhoneNumberHasIncorrectLength = 'PhoneNumberHasIncorrectLength',
  PhoneNumberHasIncorrectCharacter = 'PhoneNumberHasIncorrectCharacter',
  ThatPrefixIsNotInDb = 'ThatPrefixIsNotInDb',
  TwoEventsOverlap = 'TwoEventsOverlap',
  TimesheetRecordOutsideAgreementDateRange = 'TimesheetRecordOutsideAgreementDateRange',
  WorkerLimitForFileTypeExceeded = 'WorkerLimitForFileTypeExceeded',
  FileAlreadyExists = 'FileAlreadyExists',
  TokenIsIncorrect = 'TokenIsIncorrect',
  ThereIsNoGeneratedFileOnWorkerAgreement = 'ThereIsNoGeneratedFileOnWorkerAgreement',
  VerificationCodeHasExpired = 'VerificationCodeHasExpired',
  VerificationCodeIsNotCorrect = 'VerificationCodeIsNotCorrect',
  YouAreNotAllowedToAcceptThisAgreement = 'YouAreNotAllowedToAcceptThisAgreement',
  YouAreNotAllowedToDoThis = 'YouAreNotAllowedToDoThis',
  VerificationAgreementCodeErrorMessage = 'VerificationAgreementCodeErrorMessage',
  Failure = 'Failure',
  SomethingWentWrong = 'SomethingWentWrong',
  NoPermission = 'NoPermission',
  TimespanCannotBeEqualZero = 'TimespanCannotBeEqualZero',
  DocumentIsNotPdf = 'DocumentIsNotPdf',
  CannotDeleteApprovedTimesheetRecord = 'CannotDeleteApprovedTimesheetRecord',
  ThereIsNoRequiredDataForAgreementGeneration = 'ThereIsNoRequiredDataForAgreementGeneration',
  OldPasswordIsIncorrect = 'OldPasswordIsIncorrect',
  UserAccountHasNotBeenConfirmedYet = 'UserAccountHasNotBeenConfirmedYet',
  UserAccountIsAlreadyConfirmed = 'UserAccountIsAlreadyConfirmed',
  MaxFileCountExceeded = 'MaxFileCountExceeded',
  EmailNotConfirmed = 'EmailNotConfirmed',
  EmailNotConfirmedExtraMessage = 'EmailNotConfirmedExtraMessage',
  WrongTab = 'WrongTab',
  SomebodyElseHaveAlreadyApproved = 'SomebodyElseHaveAlreadyApproved',
  TimesheetMustBeApprovedByInternalWorkerFirst = 'TimesheetMustBeApprovedByInternalWorkerFirst',
  NoPermissionToApprove = 'NoPermissionToApprove',
  YouCannotUnapproveTimesheetBecauseItIsAlreadySettled = 'YouCannotUnapproveTimesheetBecauseItIsAlreadySettled',
  YouCannotModifyTimesheetBecauseItIsAlreadySettled = 'YouCannotModifyTimesheetBecauseItIsAlreadySettled',
  ActivationTokenIsInvalid = 'ActivationTokenIsInvalid',
  UserIsAlreadyActivated = 'UserIsAlreadyActivated',
  BankAccountOwnerTheSameAsUser = 'BankAccountOwnerTheSameAsUser',
  ChooseWorkMode = 'ChooseWorkMode',
  WorkerMustHaveRegisteredAddress = 'WorkerMustHaveRegisteredAddress',
  UserDoesNotHaveWorkerProfile = 'UserDoesNotHaveWorkerProfile',
  DelegationInvoicesReachedMaximumCount = 'DelegationInvoicesReachedMaximumCount',
  CannotPreviewFile = 'CannotPreviewFile',
  CouldNotAcceptAbsence = 'CouldNotAcceptAbsence',
  PresetDoesNotExists = 'PresetDoesNotExists',
  ThereIsNoWorkingTimeGroupOnAgreement = 'ThereIsNoWorkingTimeGroupOnAgreement',
  QrCodeExpired = "QrCodeExpired",
  QrCodeInvalid = "QrCodeInvalid",
  NoAgreementToLogWork = "NoAgreementToLogWork",
}
