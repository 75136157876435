import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { AgreementTypeDto } from 'src/app/models/dtos/agreement-type-dto';
import { EmployerObjectDictionaryDto } from 'src/app/models/dtos/employer-object-dictionary-dto';
import { WageTypeDto } from 'src/app/models/dtos/wage-type-dto';
import { CurrencyEnum } from 'src/app/models/enums/currency-enum';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WageDescription } from 'src/app/models/enums/WageDescription';
import { Wage, WorkerAgreement } from 'src/app/models/WorkerAgreement';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { SupervisorsService } from '../data/supervisors.service';
import { SupervisorDto } from '../models/supervisor.dto';
import { WageValidator } from '../shared/validators/wage.validator';
import { AuthService } from '../core/authentication/auth.service';
import { Permission } from '../common/enums/permissions';
import { ModuleName, ModulePermissionService } from '../subscription-package';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NoticePeriodDto } from '../models/dtos/notice-period-dto';

@Component({ template: '' })
export abstract class AgreementFormBaseComponent {
  protected abstract readonly employmentTypeId: EmploymentType;
  public readonly moduleNames = ModuleName;
  public readonly miminumWages = 1;
  public wageCounter = 0;

  private readonly decrementMultiplier = -1;
  private readonly maxWageDescriptionLength = 2000;
  private readonly minWageDescriptionLength = 2;

  protected readonly timeBetweenInput = 300;
  protected readonly minimumInputLetters = 2;
  protected readonly permittedNumberOfDays = 540;
  protected readonly maxDaysBefore = 7;
  protected readonly wageDescriptionsThatCannotOccurrSimultanously: Map<WageDescription, WageDescription[]> = new Map<
    WageDescription,
    WageDescription[]
  >([
    [WageDescription.Daily, [WageDescription.Daily]],
    [WageDescription.Night, [WageDescription.Night]],
  ]);

  protected workerAgreement: WorkerAgreement;
  workerAgreementId: number;
  externalWorkerId: number;
  isMpkActive: boolean;

  public listOfCompanies$: Observable<DictionaryItem[]> = this.dictionaryService.getCompaniesList();
  public listOfEmployers$: Observable<DictionaryItem[]> = this.dictionaryService.getEmployersList();
  public listOfEmployerObjects$: Observable<EmployerObjectDictionaryDto[]> = of([]);
  public listOfLocations$: Observable<DictionaryItem[]>;
  public listOfSupervisors$: Observable<SupervisorDto[]> = of([]);
  public listOfAgreementTemplates$: Observable<DictionaryItem[]>;
  public listOfPaymentDeadlines$: Observable<DictionaryItem[]> = this.dictionaryService.getPaymentDeadlines();
  public listOfResponsibilities$: Observable<DictionaryItem[]>;
  public listOfNoticePeriods$: Observable<NoticePeriodDto[]> = this.dictionaryService.getNoticePeriods();
  public listOfAgreementTypes$: Observable<AgreementTypeDto[]> = this.dictionaryService.getAgreementTypes([EmploymentType.MandateAgreement]);
  public listOfWorkPatterns$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkPatterns();

  public listOfWageTypesSubject$ = new BehaviorSubject<number>(null);
  listOfWageTypes$: Observable<WageTypeDto[]> = this.listOfWageTypesSubject$.asObservable().pipe(
    switchMap((agreementTypeId: number) => {
      if (!agreementTypeId) return of([]);
      return this.dictionaryService.getWageTypesByAgreementTypeId(agreementTypeId);
    }),
  );

  public listOfWageDescriptions$: Observable<DictionaryItem[]> = this.dictionaryService.getWageDescriptions();
  public listOfCurrencies$: Observable<DictionaryItem[]> = this.dictionaryService.getCurrencies();

  protected selectedEmployerObject: EmployerObjectDictionaryDto;

  protected agreementFormGroup: UntypedFormGroup;

  public companiesList: DictionaryItem[];
  public employersList: DictionaryItem[];
  public agreementTypesList: DictionaryItem[];

  protected displayedColumns: string[] = ['wageId', 'wage', 'wageType', 'description', 'actions'];
  public dataSource = new MatTableDataSource();

  @ViewChild('employerSelect') protected employerSelect: MatSelect;
  @ViewChild('employerObjectInput') protected employerObjectSelectRef: ElementRef;
  @ViewChild('employerObjectInput', { read: MatAutocompleteTrigger }) employerObjectSelect: MatAutocompleteTrigger;

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(
    protected dictionaryService: DictionaryService,
    protected workerAgreementService: WorkerAgreementService,
    protected supervisorsService: SupervisorsService,
    protected snackbarService: SnackBarService,
    protected spinner: NgxSpinnerService,
    protected formBuilder: UntypedFormBuilder,
    protected router: Router,
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected modulePermissionService: ModulePermissionService
  ) {
    this.buildFormGroup();
  }

  get company() {
    return this.agreementFormGroup.get('company') as UntypedFormControl;
  }

  get employer() {
    return this.agreementFormGroup.get('employer') as UntypedFormControl;
  }

  get location() {
    return this.agreementFormGroup.get('location') as UntypedFormControl;
  }

  get employerObject() {
    return this.agreementFormGroup.get('employerObject') as UntypedFormControl;
  }

  get agreementType() {
    return this.agreementFormGroup.get('agreementType') as UntypedFormControl;
  }

  get conclusionDate() {
    return this.agreementFormGroup.get('conclusionDate') as UntypedFormControl;
  }

  get employmentDateFrom() {
    return this.agreementFormGroup.get('employmentDateFrom') as UntypedFormControl;
  }

  get employmentDateTo() {
    return this.agreementFormGroup.get('employmentDateTo') as UntypedFormControl;
  }

  get wages() {
    return this.agreementFormGroup.get('wages') as UntypedFormArray;
  }

  get agreementTemplate() {
    return this.agreementFormGroup.get('agreementTemplate') as UntypedFormControl;
  }

  get noticePeriod() {
    return this.agreementFormGroup.get('noticePeriod') as UntypedFormControl;
  }

  get responsibilities() {
    return this.agreementFormGroup.get('responsibilities') as UntypedFormControl;
  }

  get paymentDeadline() {
    return this.agreementFormGroup.get('paymentDeadline') as UntypedFormControl;
  }

  get isForIndefinitePeriod() {
    return this.agreementFormGroup.get('isForIndefinitePeriod') as UntypedFormControl;
  }

  get mpk() {
    return this.agreementFormGroup.get('mpk') as UntypedFormControl;
  }

  get supervisor() {
    return this.agreementFormGroup.get('supervisor') as UntypedFormControl;
  }

  get workPattern(): FormControl {
    return this.agreementFormGroup.get('workPattern') as FormControl;
  }

  get disableAgreementDateLimit(): boolean {
    return this.authService.hasPermission(Permission.DisableAgreementDateLimit);
  }

  private emailValidators = [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.maxLength(50)];
  private _listOfResponsibilities: DictionaryItem[];

  protected get listOfResponsibilities(): DictionaryItem[] {
    return this._listOfResponsibilities;
  }
  protected set listOfResponsibilities(values: DictionaryItem[]) {
    this._listOfResponsibilities = values;
    this.setResponsibilities(this.workerAgreement?.Responsibilities);
  }

  public filterWageDescriptions(wageDescriptions: DictionaryItem[], index: number) {
    if (!wageDescriptions || !this.wages.value) {
      return wageDescriptions;
    }

    const isItemAvailable = (item: DictionaryItem): boolean => {
      return this.wages.value.every((wage) => {
        if (!this.wageDescriptionsThatCannotOccurrSimultanously.has(wage.wageDescriptionId)) {
          return true;
        }
        return !this.wageDescriptionsThatCannotOccurrSimultanously
          .get(wage.wageDescriptionId)
          .some((sw) => sw == item.Id && this.wages.value.indexOf(wage) !== index);
      });
    };

    return wageDescriptions.filter((item: DictionaryItem) => isItemAvailable(item));
  }

  public addNewWage(wage: Wage = { Value: null, WageTypeId: null, WageDescriptionId: null } as Wage) {
    this.wages.push(this.newWage(wage));
    this.dataSource.data = this.wages.controls;
    this.wageCounter++;
  }

  public deleteWage(index: number) {
    this.wages.removeAt(index);
    this.dataSource.data = this.wages.controls;
    this.wageCounter--;
  }

  public displayValue = (value: DictionaryItem): string | undefined => value?.Name;

  public checkDays(type: string, event: MatDatepickerInputEvent<Date>): void {
    // if (!this.isForIndefinitePeriod.value && this.employmentDateTo.value) {
    //   const diff = this.employmentDateTo.value.valueOf() - this.employmentDateFrom.value.valueOf();
    //   const diffOfDays = Math.ceil(diff / (1000 * 3600 * 24));
    //   if (diffOfDays > this.permittedNumberOfDays) {
    //     this.openAlertDialog(Messages.Exceeded540DaysMessage);
    //   }
    // }
  }

  public conclusionDateFilter = (date: Date): boolean => (this.employmentDateTo.value ? date <= this.employmentDateTo.value : true);
  public employmentDateFromFilter = (date: Moment): boolean =>
    (this.disableAgreementDateLimit || date?.isSameOrAfter(moment().subtract(this.maxDaysBefore, 'days').startOf('day'))) &&
    (this.employmentDateTo.value ? date <= this.employmentDateTo.value : true);
  public employmentDateToFilter = (date: Date): boolean =>
    this.employmentDateFrom.value && this.conclusionDate.value ? date >= this.employmentDateFrom.value && date >= this.conclusionDate.value : true;

  public onGenerateDocument(): void {
    if (this.agreementFormGroup.invalid) return;
    this.spinner.show();

    const data = this.agreementFormGroup.getRawValue();
    const payload: WorkerAgreement = this.createPayload(data);

    const callback = (overlappingArray: number[]) => {
      if (overlappingArray.length) {
        this.spinner.hide();
        return this.openConfirmDialog(Messages.ConfirmActionOnOverlappingAgreementTitle, Messages.ConfirmActionOnOverlappingAgreementMessage).pipe(
          switchMap((isConfirmed) => {
            if (!isConfirmed) return EMPTY;

            return this.createOrUpdateWorkerAgreement(payload);
          }),
        );
      } else {
        return this.createOrUpdateWorkerAgreement(payload);
      }
    };

    this.workerAgreementService
      .getOverlappingWorkerAgreements(
        this.externalWorkerId,
        this.workerAgreementId,
        data.employerObject.Id,
        moment(data.employmentDateFrom),
        data.employmentDateTo ? moment(data.employmentDateTo) : null,
      )
      .pipe(
        first(),
        switchMap((overlappingArray) => {
          return callback(overlappingArray);
        }),
      )
      .subscribe();
  }

  protected onSupervisorChange(): void {
    this.listOfSupervisors$ = this.supervisor.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters && !!this.employerObject && this.employerObject.value && this.employerObject.value.Id) {
          return this.supervisorsService.getPotentialSupervisors(this.employerObject.value.Id, value);
        } else {
          return of(null);
        }
      }),
    );
  }

  protected createOrUpdateWorkerAgreement(payload: WorkerAgreement): Observable<number> {
    this.spinner.show();

    const action$ =
      this.workerAgreementId === 0
        ? this.workerAgreementService.createWorkerAgreement(payload)
        : this.workerAgreementService.updateWorkerAgreement(this.workerAgreementId, payload);

    return action$.pipe(
      finalize(() => this.spinner.hide()),
      tap((id: number) => {
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullySendAgreementToGeneration);
        this.router.navigate(['/workers', this.externalWorkerId, 'employmentType', EmploymentType.MandateAgreement, 'agreements', id, 'details']);
      }),
    );
  }

  protected createPayload = (data: any): WorkerAgreement => {
    return {
      ExternalWorkerId: this.externalWorkerId,
      CompanyId: data.company,
      EmployerId: data.employer,
      EmployerObjectId: data.employerObject.Id,
      LocationId: this.selectedEmployerObject.HasLocalization ? data.location.Id : null,
      AgreementTypeId: data.agreementType,
      Responsibilities: data.responsibilities,
      ConclusionDate: data.conclusionDate,
      EmploymentDateFrom: data.employmentDateFrom,
      EmploymentDateTo: data.employmentDateTo,
      IsPreliminaryMedicalExaminationRequired: this.selectedEmployerObject.HasPreliminaryMedicalExaminationRequirements && data.preliminaryMedicalExamination,
      Wages: data.wages,
      AgreementTemplateId: data.agreementTemplate,
      NoticePeriodId: data.noticePeriod,
      PaymentDeadlineId: data.paymentDeadline,
      PotentialSupervisorId: this.selectedEmployerObject.RequireSupervisorOnMultiAgreementsForm ? data.supervisor?.Id : null,
      PotentialSupervisorName: this.selectedEmployerObject.RequireSupervisorOnMultiAgreementsForm ? data.subject : null,
      WorkPatternId: this.selectedEmployerObject.HasWorkPattern ? data.workPattern : null,
      WorkEmail: data.workEmail
    };
  };

  protected buildFormGroup(): void {
    this.agreementFormGroup = this.formBuilder.group({
      company: [null, [Validators.required]],
      employer: [null, [Validators.required]],
      employerObject: [{ value: null, disabled: true }, [Validators.required]],
      mpk: [{ value: null, disabled: true }],
      location: [null, [autocompleteValidator]],
      agreementType: [null, [Validators.required]],
      conclusionDate: [null, [Validators.required]],
      employmentDateFrom: [null, [Validators.required]],
      employmentDateTo: [null],
      wages: this.formBuilder.array([], [Validators.required]),
      preliminaryMedicalExamination: [false, [Validators.required]],
      agreementTemplate: [null, [Validators.required]],
      noticePeriod: [null, [Validators.required]],
      responsibilities: [null, [Validators.required]],
      paymentDeadline: [null, [Validators.required]],
      isForIndefinitePeriod: [false],
      supervisor: [null, [autocompleteValidator]],
      workPattern: [null],
      workEmail: ['', this.emailValidators],
    });
  }

  protected newWage(wage: Wage = null) {
    const wageForm = this.formBuilder.group({
      value: [
        wage?.Value,
        [Validators.required],
        [
          (control: AbstractControl) => WageValidator.maxWage(this.listOfWageTypes$, control),
          (control: AbstractControl) => WageValidator.minWage(this.listOfWageTypes$, control),
        ],
      ],
      currencyId: [wage?.CurrencyId ?? CurrencyEnum.PLN, Validators.required],
      wageTypeId: [wage?.WageTypeId, Validators.required],
      wageDescriptionId: [wage?.WageDescriptionId],
      name: [wage?.Name, [Validators.required, Validators.minLength(this.minWageDescriptionLength), Validators.maxLength(this.maxWageDescriptionLength)]],
      calculationDescription: [wage?.CalculationDescription, [Validators.required, Validators.minLength(this.minWageDescriptionLength), Validators.maxLength(this.maxWageDescriptionLength)]],
    });

    const wageTypeControl = () => wageForm.get('wageTypeId');
    const wageValueControl = () => wageForm.get('value');

    combineLatest([wageForm.valueChanges, this.listOfWageTypes$])
      .pipe(debounceTime(this.timeBetweenInput), takeUntil(this.unsubscribe$))
      .subscribe(([changedWage, wageTypes]) => {
        const wageType = wageTypes.find((wt) => wt.Id == changedWage.wageTypeId);
        if (!wageType?.IsDescriptiveType) {
          const wageTypeMax = wageType?.MaxWageValue;
          const wageTypeMin = wageType?.MinWageValue;
          if (!!wageTypeMax && wageTypeMax <= changedWage.value) {
            this.openAlertDialog(Messages.ExceededMaxWageMessage);
          }
          if (!!wageTypeMin && wageTypeMin > changedWage.value) {
            this.openAlertDialog(Messages.NotExceededMinWageMessage);
          }
          wageValueControl().updateValueAndValidity({ emitEvent: false });
        }
      });

    combineLatest([
      wageTypeControl().valueChanges.pipe(startWith(wageTypeControl().value)),
      this.listOfWageTypes$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([changedWageTypeId, wageTypes]) => {
        const wageType = wageTypes.find((wt) => wt.Id == changedWageTypeId);
        if (!wageType?.IsDescriptiveType) {
          this.setValueWageTypeValidators(wageForm);
        } else {
          this.setDescriptiveWageTypeValidators(wageForm);
        }
      });

    return wageForm;
  }

  setValueWageTypeValidators(wageForm: UntypedFormGroup) {
    wageForm.get('value').enable();
    wageForm.get('currencyId').enable();
    wageForm.get('name').reset();
    wageForm.get('calculationDescription').reset();
    wageForm.get('name').disable();
    wageForm.get('calculationDescription').disable();

    wageForm.updateValueAndValidity({ emitEvent: false });
  }

  setDescriptiveWageTypeValidators(wageForm: UntypedFormGroup) {
    wageForm.get('value').reset();
    wageForm.get('currencyId').reset();
    wageForm.get('value').disable();
    wageForm.get('currencyId').disable();
    wageForm.get('name').enable();
    wageForm.get('calculationDescription').enable();

    wageForm.updateValueAndValidity({ emitEvent: false });
  }

  protected openAlertDialog(message: string): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });
  }

  protected openConfirmDialog(title: string, message: string): Observable<any> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: new ConfirmDialogData(title, message),
      })
      .afterClosed();
  }

  protected setWorkerAgreement(workerAgreement: WorkerAgreement): void {
    this.workerAgreement = workerAgreement;

    this.agreementFormGroup.patchValue({
      employer: workerAgreement.EmployerId,
      employerObject: { Id: workerAgreement.EmployerObjectId },
      company: workerAgreement.CompanyId,
      location: workerAgreement.LocationId
        ? ({
          Id: workerAgreement.LocationId,
          Name: workerAgreement.LocationName,
        } as DictionaryItem)
        : null,
      agreementType: workerAgreement.AgreementTypeId,
      preliminaryMedicalExamination: workerAgreement.IsPreliminaryMedicalExaminationRequired,
      agreementTemplate: workerAgreement.AgreementTemplateId,
      noticePeriod: workerAgreement.NoticePeriodId,
      paymentDeadline: workerAgreement.PaymentDeadlineId,
      supervisor: workerAgreement.PotentialSupervisorId
        ? ({
          Id: workerAgreement.PotentialSupervisorId,
          Name: workerAgreement.PotentialSupervisorName,
        } as DictionaryItem)
        : null,
      workPattern: workerAgreement.WorkPatternId,
      workEmail: workerAgreement.WorkEmail
    });

    this.agreementFormGroup.patchValue(
      {
        conclusionDate: workerAgreement.ConclusionDate,
        employmentDateFrom: workerAgreement.EmploymentDateFrom,
        employmentDateTo: workerAgreement.EmploymentDateTo,
      },
      { emitEvent: false },
    );

    this.employmentDateFrom.updateValueAndValidity({ emitEvent: true, onlySelf: true });

    this.setResponsibilities(workerAgreement.Responsibilities);

    workerAgreement.Wages.forEach((c: Wage) => {
      this.addNewWage(c);
    });

    if (!!workerAgreement.EmploymentDateFrom) this.employmentDateTo.enable();
    if (!!workerAgreement.EmployerObjectId) this.employerObject.enable();
  }

  protected getCurrentSupervisor(): void {
    if (!this.workerAgreementId) {
      this.supervisorsService.getDirectSupervisor(this.externalWorkerId).subscribe((supervisor: DictionaryItem) => {
        this.supervisor.setValue(<DictionaryItem>{ Id: supervisor.Id, Name: supervisor.Name });
      });
    }
  }

  protected async initEventHandlers(): Promise<void> {
    this.handleEmploymentDatesChange();
    this.handleEmployerStatusChange();
    await this.handleEmployerChange();
    this.handleEmployerObjectChange();
    this.handleAgreementTypeChange();
    this.handleLocationChange();
    this.onSupervisorChange();
    await this.handleSubscriptionModules();
    this.handleNoticePeriodValidity();
  }

  protected async setInitialValues(prefetchedAgreement: WorkerAgreement) {
    !prefetchedAgreement?.CompanyId && await this.setInitialValue(this.listOfCompanies$, this.company);
    !prefetchedAgreement?.EmployerId && await this.setInitialValue(this.listOfEmployers$, this.employer);
    !prefetchedAgreement?.AgreementTypeId && await this.setInitialValue(this.listOfAgreementTypes$, this.agreementType);
  }

  public handleNoticePeriodValidity(): void {
    combineLatest([
      this.noticePeriod.valueChanges.pipe(distinctUntilChanged()),
      this.employmentDateFrom.valueChanges.pipe(distinctUntilChanged()),
      this.employmentDateTo.valueChanges.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([noticePeriodId, employmentDateFrom, employmentDateTo]) =>
        this.listOfNoticePeriods$.pipe(
          map(noticePeriods => ({
            noticePeriod: noticePeriods.find(np => np.Id === noticePeriodId),
            employmentDateFrom: moment(employmentDateFrom),
            employmentDateTo: moment(employmentDateTo)
          }))
        )
      ),
      takeUntil(this.unsubscribe$)
    ).subscribe(({ noticePeriod, employmentDateFrom, employmentDateTo }) => {
      if (this.isForIndefinitePeriod.value) {
        this.noticePeriod.setErrors(null);

        return;
      }

      if (noticePeriod && employmentDateFrom && employmentDateTo) {
        const firstNoticePeriodDay = employmentDateTo.toDate()
          .addDays(this.decrementMultiplier * noticePeriod.Days - noticePeriod.DaysAfterSubmission)
          .addWeeksImmutable(this.decrementMultiplier * noticePeriod.Weeks)
          .addMonthsImmutable(this.decrementMultiplier * noticePeriod.Months);

        if (moment(firstNoticePeriodDay).isBefore(employmentDateFrom)) {
          this.openAlertDialog(Messages.AgreementHasInvalidNoticePeriod);
          this.noticePeriod.setErrors({ noticePeriodBeforeStart: true });
          this.noticePeriod.markAsTouched();
        } else {
          this.noticePeriod.setErrors(null);
        }
      }
    });
  }

  private handleEmploymentDatesChange = () => {
    this.employmentDateTo.disable();
    this.employmentDateFrom.valueChanges.subscribe((date) => {
      if (date) {
        this.employmentDateTo.enable();
      } else {
        this.employmentDateTo.reset();
        this.employmentDateTo.disable();
      }
    });
  };

  private handleEmployerStatusChange = () =>
    this.employer.statusChanges.pipe(
      tap((_) => {
        if (this.employer.valid) {
          this.employerObject.enable({ emitEvent: false });
        } else {
          this.employerObject.reset(null, { emitEvent: false });
          this.employerObject.disable({ emitEvent: false });
        }
      }),
    );

  private async handleEmployerChange(): Promise<void> {
    const employerChanges$ = this.employer.valueChanges.pipe(
      startWith(null),
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ) as Observable<number>;

    this.listOfEmployerObjects$ = employerChanges$.pipe(
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap(() => {
        if (this.employer.value) {
          return this.dictionaryService.getEmployerObjectsByEmployerId(this.employer.value).pipe(
            tap((items) => {
              if (items.length === 1) {
                this.employerObject.setValue(items[0]);
              } else {
                if (!this.employerObject.value?.Id || (items.length && !items.some((item) => item.Id === this.employerObject.value.Id))) {
                  this.employerObject.reset();
                  this.employerObjectSelectRef?.nativeElement.focus();
                  this.employerObjectSelect.openPanel();
                } else {
                  this.employerObject.setValue(items.find((item) => item.Id === this.employerObject.value.Id));
                }

                this.employerObject.enable({ emitEvent: false });
              }
            }),
          );
        } else {
          return of([]);
        }
      }),
      tap((employerObjects) => {
        if (!!this.employer.value && !employerObjects.length) {
          this.openAlertDialog(Messages.ThereIsNoEmployerObjectForThisEmployer);
        }
      }),
      switchMap((employerObjects) =>
        this.employerObject.valueChanges.pipe(
          startWith(undefined),
          debounceTime(this.timeBetweenInput),
          takeUntil(this.unsubscribe$),
          map((_) =>
            !this.employerObject.value?.toLowerCase || employerObjects === null || employerObjects.length === 1
              ? employerObjects
              : employerObjects.filter((item) => item.Name?.toLowerCase().includes(this.employerObject.value?.toLowerCase())),
          ),
          tap((employerObjects) => {
            const res = employerObjects.find(
              (employerObject) =>
                this.employerObject.value?.toLowerCase && employerObject.Name?.toLowerCase() === this.employerObject.value?.toLowerCase(),
            );

            if (res) {
              this.employerObject.setValue(res);
              this.employerObjectSelect.closePanel();
            }
          }),
        ),
      ),
    );

    this.listOfResponsibilities$ = employerChanges$
      .pipe(
        switchMap(employerId => this.dictionaryService.getResponsibilities(employerId ?? this.employer.value)),
        tap(responsibilities => this.listOfResponsibilities = responsibilities)
      );

    this.listOfAgreementTemplates$ = employerChanges$.pipe(
      switchMap((employerId) => this.dictionaryService.getAgreementTemplates(employerId ?? this.employer.value, this.employmentTypeId)),
      tap(async (items) => {
        if (items.length === 1) {
          this.agreementTemplate.setValue(items[0].Id)
        }
      }));
  }

  private handleEmployerObjectChange = () =>
    combineLatest([this.listOfEmployerObjects$, this.employerObject.valueChanges])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([employerObjects, employerObject]) => {
        if (isNaN(employerObject?.Id)) return;

        this.selectedEmployerObject = employerObjects.find((p) => p.Id == employerObject.Id);

        if (!this.selectedEmployerObject) return;

        this.isMpkActive = this.selectedEmployerObject.IsMpkActive;

        if (this.selectedEmployerObject.IsMpkActive) {
          if (!this.selectedEmployerObject.Mpk) {
            this.openAlertDialog(Messages.EmployerObjectHasNoMpk);
            this.employerObject.setErrors({ employerObjectHasNoMpk: true });
            this.mpk.reset(null, { emitEvent: false });
          } else {
            this.mpk.setValue(this.selectedEmployerObject.Mpk);
          }
        }

        if (this.selectedEmployerObject.HasLocalization) {
          this.location.enable();
        } else {
          this.location.disable();
        }

        if (this.selectedEmployerObject.HasWorkPattern && this.workPattern.disabled) {
          this.workPattern.enable();
          this.workPattern.setValidators(Validators.required);
          this.workPattern.updateValueAndValidity();
        } else if (!this.selectedEmployerObject.HasWorkPattern && this.workPattern.enabled) {
          this.workPattern.disable();
          this.workPattern.removeValidators(Validators.required);
          this.workPattern.updateValueAndValidity();
        }

        this.configureSupervisor();
      });

  private handleAgreementTypeChange = () =>
    this.agreementType.valueChanges
      .pipe(startWith(null), takeUntil(this.unsubscribe$))
      .subscribe((value: number) => {
        this.listOfWageTypesSubject$.next(value ?? this.agreementType.value);
      });

  protected handleLocationChange(): void {
    this.listOfLocations$ = this.location.valueChanges.pipe(
      debounceTime(this.timeBetweenInput),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (value && value.length > this.minimumInputLetters) {
          return this.dictionaryService.getLocations(value);
        } else {
          return of(null);
        }
      }),
    );
  }

  protected handleEmployerSelectionChange() {
    this.employerSelect.selectionChange.pipe(takeUntil(this.unsubscribe$)).subscribe((employerChange) => {
      if (employerChange.value) {
        this.employerObject.enable();
        this.employerObject.reset(null, { emitEvent: false });
      }
    });
  }

  protected handleIsForIndefinitePeriodChange() {
    this.isForIndefinitePeriod.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isForIndefinitePeriod: boolean) => {
      if (isForIndefinitePeriod) {
        this.employmentDateTo.reset(null, { emitEvent: false });
        this.employmentDateTo.disable({ emitEvent: false });
      } else {
        this.employmentDateTo.enable({ emitEvent: false });
      }
    });
  }

  private setResponsibilities(responsibilities: number[]) {
    this.agreementFormGroup.patchValue({
      responsibilities: this.listOfResponsibilities?.map(rs => rs.Id).filter(id => responsibilities?.includes(id)),
    });
  }

  private async setInitialValue(observableList$: Observable<any>, formControl: UntypedFormControl) {
    var list = await firstValueFrom(observableList$);
    if (list.length === 1 && !formControl.value) {
      formControl.patchValue(list[0].Id);
    }
  }

  private async handleSubscriptionModules(): Promise<void> {
    if (!await this.modulePermissionService.isAllowedModule(this.moduleNames.Supervisors, this.externalWorkerId)) {
      this.supervisor.clearValidators();
      this.supervisor.updateValueAndValidity();
    }
  }

  protected configureSupervisor() { }
}
