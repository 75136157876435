import { WorkerFormStatementHistory } from './../models/worker-form-statement-summary';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AddEducationHistoryRecordFilesRequest } from '../contracts/requests/add-education-history-record-files-request';
import { AddWorkerFamilyMemberFilesRequest } from '../contracts/requests/add-worker-family-member-files-request';
import { CreateOrUpdateWorkerEducationHistoryRecordRequest } from '../contracts/requests/create-or-update-education-history-record-request';
import { CreateOrUpdateWorkerEmploymentHistoryRecordRequest } from '../contracts/requests/create-or-update-worker-employment-history-record-request';
import { CreateOrUpdateWorkerFamilyMemberRequest } from '../contracts/requests/create-or-update-worker-family-member-request';
import { GetExistingExternalWorkerRequest } from '../contracts/requests/get-existing-external-worker-request';
import { InsureWorkerFamilyMemberRequest } from '../contracts/requests/insure-worker-family-member-request';
import { ToggleToBeAddedRequest } from '../contracts/requests/toggle-to-be-added-request';
import { UpdateInternalWorkerEmployerObjectsRequest } from '../contracts/requests/UpdateInternalWorkerEmployerObjectsRequest';
import { WorkerProfileSettingsRequest } from '../contracts/requests/worker-profile-settings-request';
import { ApiResult } from '../models/ApiResult';
import { CreateWorkerFormPersonalData } from '../models/create-worker-form-personal-data';
import { AllInternalWorkersGridDto } from '../models/dtos/AllInternalWorkersGridDto';
import { EducationHistoryGridDto } from '../models/dtos/education-history-grid-dto';
import { EducationHistoryRecordDto } from '../models/dtos/education-history-record-dto';
import { EmploymentHistoryRecordDto } from '../models/dtos/employment-history-record-dto';
import { InternalWorkerActiveAgreementDto } from '../models/dtos/InternalWorkerActiveAgreementDto';
import { WorkerContactInfoDto } from '../models/dtos/worker-contact-info-dto';
import { WorkerFamilyMemberDto } from '../models/dtos/worker-family-member-dto';
import { WorkerFamilyMemberGridDto } from '../models/dtos/worker-family-member-grid-dto';
import { WorkerProfileDocumentsDto } from '../models/dtos/worker-profile-documents-dto';
import { WorkerSettingsDto } from '../models/dtos/worker-settings-dto';
import { ExternalWorker } from '../models/ExternalWorker';
import { FormQuestion } from '../models/forms/FormQuestion';
import { InternalWorkerDto } from '../models/dtos/internal-worker-dto';
import { CreateExternalWorkerResult } from '../models/results/CreateExternalWorkerResult';
import { CreateInternalWorkerResult } from '../models/results/CreateInternalWorkerResult';
import { WorkerAddress } from '../models/WorkerAddress';
import { WorkerFileDto } from '../models/worker-file-dto';
import { WorkerFormStatementSummary } from '../models/worker-form-statement-summary';
import { WorkerProfile } from '../models/WorkerProfile';
import { IPagedResult } from '../shared/models/PagedResult';
import { DictionaryItem } from '../models/DictionaryItem';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { LegalizationDocumentDto } from '../models/dtos/legalization-document-files-dto';
import { CachedHttpClient } from '../common/utils';
import { Filter } from '../models/common/filter';
import { ExternalWorkerGridItemDto } from '../models/dtos/external-worker-grid-item-dto';
import { WorkerAddressResponse } from '../contracts/responses/worker-address-response';
import { WorkerProfileAgreementsPageConfigDto } from '../models/dtos/worker-profile-agreements-page-config-dto';

@Injectable()
export class WorkerService {
  private readonly API_URL = this.config.resourceApiURI;
  private readonly workerPath = '/workers';

  private get workerUrl() {
    return this.API_URL + this.workerPath;
  }

  constructor(private http: HttpClient, private cachedHttp: CachedHttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  addExternalWorker(externalWorker: ExternalWorker): Observable<CreateExternalWorkerResult> {
    const url = `${this.workerUrl}/externalworker`;
    return this.http.post<CreateExternalWorkerResult>(url, externalWorker);
  }

  addInternalWorker(internalWorker: InternalWorkerDto): Observable<ApiResult<CreateInternalWorkerResult>> {
    const url = `${this.workerUrl}/internalworker`;
    return this.http.post<ApiResult<CreateInternalWorkerResult>>(url, internalWorker);
  }

  addWorkerFormPersonalData(workerPersonalData: CreateWorkerFormPersonalData): Observable<any> {
    const url = `${this.workerUrl}/workerFormPersonalData`;
    return this.http.post<any>(url, workerPersonalData);
  }

  addWorkerAddress(workerFormId: number, workerAdress: WorkerAddress[]): Observable<any> {
    const url = `${this.workerUrl}/workerAddress/${workerFormId}`;
    return this.http.post<any>(url, workerAdress);
  }

  assignWorkerToEmployerObject(workerId: number, request: any): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/assignToEmployerObject`;
    return this.http.patch<any>(url, request);
  }

  addWorkerFamilyMemberFiles(workerId: number, memberId: number, request: AddWorkerFamilyMemberFilesRequest) {
    const url = `${this.workerUrl}/${workerId}/familyMembers/${memberId}/files`;
    return this.http.post<any>(url, request);
  }

  addEducationHistoryRecordFiles(workerId: number, recordId: number, request: AddEducationHistoryRecordFilesRequest) {
    const url = `${this.workerUrl}/${workerId}/educationHistory/${recordId}/files`;
    return this.http.post<any>(url, request);
  }

  createWorkerEmploymentHistoryRecord(workerId: number, request: CreateOrUpdateWorkerEmploymentHistoryRecordRequest): Observable<number> {
    const url = `${this.workerUrl}/${workerId}/employmentHistory`;
    return this.http.post<number>(url, request);
  }

  createWorkerEducationHistoryRecord(workerId: number, request: CreateOrUpdateWorkerEducationHistoryRecordRequest): Observable<number> {
    const url = `${this.workerUrl}/${workerId}/educationHistory`;
    return this.http.post<number>(url, request);
  }

  createWorkerFamilyMember(workerId: number, request: CreateOrUpdateWorkerFamilyMemberRequest) {
    const url = `${this.workerUrl}/${workerId}/familyMembers`;
    return this.http.post<number>(url, request);
  }

  getInternalWorker(workerId: number): Observable<ApiResult<InternalWorkerDto>> {
    const url = `${this.workerUrl}/internalWorker/${workerId}`;
    return this.http.get<ApiResult<InternalWorkerDto>>(url);
  }

  deleteWorkerFamilyMember(workerId: number, memberId: number): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/familyMembers/${memberId}`;
    return this.http.delete<any>(url);
  }

  getAllInternalWorkers(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<AllInternalWorkersGridDto>> {
    const url = `${this.workerUrl}/allInternalWorkers`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const httpOptions = {
      params: params,
    };

    return this.http.get<any>(url, httpOptions);
  }

  getAllExternalWorkers(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[]
  ): Observable<IPagedResult<ExternalWorkerGridItemDto>> {
    const url = `${this.workerUrl}/allExternalWorkers`;

    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)

    if (sortingField) {
      params = params.set('sortingField', sortingField)
        .set('sortingDirection', sortingDirection);
    }

    if (filters) {
      params = params.set('filterString', JSON.stringify(filters));
    }

    return this.http.get<any>(url, { params });
  }

  getsubordinatesBasicData(): Observable<DictionaryItem[]> {
    const url = `${this.workerPath}/subordinatesBasicData`;

    return this.cachedHttp
      .get<DictionaryItem[]>(url)
      .pipe(catchError((err) => of([])));
  }

  getActiveSubordinatesBasicData(): Observable<DictionaryItem[]> {
    const url = `${this.workerPath}/activeSubordinatesBasicData`;

    return this.cachedHttp
      .get<DictionaryItem[]>(url)
      .pipe(catchError((err) => of([])));
  }

  getExistingExternalWorker(request: GetExistingExternalWorkerRequest): Observable<ExternalWorker | null> {
    const url = `${this.workerUrl}/existingExternalWorker`;

    let params = new HttpParams()
      .set('pesel', request.Pesel)
      .set('documentSerial', request.DocumentSerial)
      .set('documentNumber', request.DocumentNumber);

    if (!!request.IdentityDocumentTypeId) {
      params = params.set('identityDocumentTypeId', request.IdentityDocumentTypeId.toString());
    }

    return this.http.get<any>(url, {
      params: params,
    });
  }

  getWorkerEmploymentHistory(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<EmploymentHistoryRecordDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const url = `${this.workerUrl}/${workerId}/employmentHistory`;
    return this.http.get<IPagedResult<EmploymentHistoryRecordDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<EmploymentHistoryRecordDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <EmploymentHistoryRecordDto>{
                  ...r,
                  EmploymentDateFrom: new Date(r.EmploymentDateFrom + 'Z'),
                  EmploymentDateTo: r.EmploymentDateTo ? new Date(r.EmploymentDateTo + 'Z') : null,
                },
            ),
          },
      ),
    );
  }

  getWorkerEducationHistory(
    workerId: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
  ): Observable<IPagedResult<EducationHistoryGridDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    const url = `${this.workerUrl}/${workerId}/educationHistory`;
    return this.http.get<IPagedResult<EducationHistoryGridDto>>(url, { params: params }).pipe(
      map(
        (res) =>
          <IPagedResult<EducationHistoryGridDto>>{
            ...res,
            Results: res.Results.map(
              (r) =>
                <EducationHistoryGridDto>{
                  ...r,
                  StartDate: r.StartDate ? new Date(r.StartDate + 'Z') : null,
                  EndDate: r.EndDate ? new Date(r.EndDate + 'Z') : null,
                },
            ),
          },
      ),
    );
  }

  getWorkerEducationHistoryRecordById(workerId: number, educationHistoryRecordId: number): Observable<EducationHistoryRecordDto> {
    const url = `${this.workerUrl}/${workerId}/educationHistory/${educationHistoryRecordId}`;
    return this.http.get<EducationHistoryRecordDto>(url).pipe(
      map((res) => ({
        ...res,
        StartDate: res.StartDate ? new Date(res.StartDate + 'Z') : undefined,
        EndDate: res.EndDate ? new Date(res.EndDate + 'Z') : undefined,
        PlannedEndDate: res.PlannedEndDate ? new Date(res.PlannedEndDate + 'Z') : undefined,
      })),
    );
  }

  getWorkerEducationDegree(workerId: number) {
    const url = `${this.workerUrl}/${workerId}/educationHistory/degree`;
    return this.http.get<number>(url);
  }

  getWorkerWorkEmail(workerId: number) {
    const url = `${this.workerUrl}/${workerId}/workEmail`;
    return this.http.get<string>(url);
  }

  getWorkerProfile(workerId: number): Observable<WorkerProfile> {
    const url = `${this.workerUrl}/workerProfile/${workerId}`;
    return this.http.get<WorkerProfile>(url).pipe(map(res => {
      if (res.DriverLicenceExpirationDate) {
        res.DriverLicenceExpirationDate = new Date(res.DriverLicenceExpirationDate + 'Z');
      }

      return res;
    }));
  }

  getWorkerLegalizationVisibility(workerId: number): Observable<boolean> {
    const url = `${this.workerPath}/${workerId}/hasLegalization`;
    return this.cachedHttp.get<boolean>(url);
  }

  getWorkerProfileStatementsSummary(workerId: number): Observable<WorkerFormStatementSummary[]> {
    const url = `${this.workerUrl}/workerProfile/${workerId}/statementsSummary`;
    return this.http.get<WorkerFormStatementSummary[]>(url).pipe(
      map((res) =>
        res.map(
          (s) =>
            <WorkerFormStatementSummary>{
              ...s,
              ModifiedOn: s.ModifiedOn && new Date(s.ModifiedOn + 'Z'),
              StatementRightsGranted: s.StatementRightsGranted && new Date(s.StatementRightsGranted),
              WorkerFormStatementHistory: s.WorkerFormStatementHistory.map(
                (h) =>
                  <WorkerFormStatementHistory>{
                    ...h,
                    ModifiedOn: h.ModifiedOn && new Date(h.ModifiedOn + 'Z'),
                    StatementRightsGranted: h.StatementRightsGranted && new Date(h.StatementRightsGranted),
                  },
              ),
            },
        ),
      ),
    );
  }

  getWorkerProfileDocuments(workerId: number): Observable<WorkerProfileDocumentsDto> {
    const url = `${this.workerUrl}/workerProfile/${workerId}/documents`;
    return this.http.get<WorkerProfileDocumentsDto>(url);
  }

  getWorkerFormQuestions(workerFormId: number): Observable<FormQuestion[]> {
    const url = `${this.workerUrl}/formQuestions/${workerFormId}`;
    return this.http.get<FormQuestion[]>(url);
  }

  getWorkerFullName(workerId: number): Observable<string> {
    const url = `${this.workerUrl}/fullName/${workerId}`;
    return this.http.get(url, { responseType: 'text' });
  }

  getWorkerFamilyMembers(
    workerId: number,
    page: number = 1,
    count: number = 20,
    sortingField: string = 'lastName',
    sortingDirection: string = 'desc',
    kinshipDegreeId: number = undefined,
    maxAge: number = undefined,
  ): Observable<IPagedResult<WorkerFamilyMemberGridDto>> {
    let params = new HttpParams()
      .set('page', `${page}`)
      .set('count', `${count}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection);

    if (kinshipDegreeId) {
      params = params.set('kinshipDegreeId', kinshipDegreeId);
    }
    if (maxAge) {
      params = params.set('maxAge', maxAge);
    }

    const url = `${this.workerUrl}/${workerId}/familyMembers`;
    return this.http.get<IPagedResult<WorkerFamilyMemberGridDto>>(url, { params: params }).pipe(
      map(
        (pagedResult) =>
          <IPagedResult<WorkerFamilyMemberGridDto>>{
            ...pagedResult,
            Results: pagedResult.Results.map(
              (r) =>
                <WorkerFamilyMemberGridDto>{
                  ...r,
                  InsuranceApplicationDate: r.InsuranceApplicationDate && new Date(r.InsuranceApplicationDate + 'Z'),
                },
            ),
          },
      ),
    );
  }

  getWorkerFamilyMemberById(workerId: number, memberId: number): Observable<WorkerFamilyMemberDto> {
    const url = `${this.workerUrl}/${workerId}/familyMembers/${memberId}`;
    return this.http.get<WorkerFamilyMemberDto>(url).pipe(
      map(
        (res) =>
          <WorkerFamilyMemberDto>{
            ...res,
            DateOfBirth: res.DateOfBirth && new Date(res.DateOfBirth + 'Z'),
          },
      ),
    );
  }

  getWorkerIdByAuthServerUserId(authServerUserId: string): Observable<number> {
    const url = `${this.workerPath}/getWorkerIdByAuthServerUserId/${authServerUserId}`;
    return this.cachedHttp.get<number>(url);
  }

  getWorkerSettings(workerId: number): Observable<WorkerSettingsDto> {
    const url = `${this.workerUrl}/${workerId}/settings`;

    return this.http.get<WorkerSettingsDto>(url);
  }

  getWorkerProfileAgreementsPageConfig(workerId: number): Observable<WorkerProfileAgreementsPageConfigDto> {
    const url = `${this.workerUrl}/${workerId}/getWorkerProfileAgreementsPageConfig`;
    return this.http.get<WorkerProfileAgreementsPageConfigDto>(url);
  }

  getInternalWorkerActiveAgreements(workerId: number): Observable<InternalWorkerActiveAgreementDto[]> {
    const url = `${this.workerUrl}/${workerId}/internalWorkerActiveAgreements`;
    return this.http.get<InternalWorkerActiveAgreementDto[]>(url).pipe(
      map((res) =>
        res.map(
          (a) =>
            <InternalWorkerActiveAgreementDto>{
              ...a,
              EmploymentDateFrom: new Date(a.EmploymentDateFrom + 'Z'), // 'Z' converts UTC to local time
              EmploymentDateTo: a.EmploymentDateTo ? new Date(a.EmploymentDateTo + 'Z') : null,
              TerminationEndDate: a.TerminationEndDate ? new Date(a.TerminationEndDate + 'Z') : null,
            },
        ),
      ),
    );
  }

  getWorkerContactInfo(workerId: number): Observable<WorkerContactInfoDto> {
    const url = `${this.workerUrl}/${workerId}/contactInfo`;
    return this.http.get<WorkerContactInfoDto>(url);
  }

  insureWorkerFamilyMember(workerId: number, memberId: number, request: InsureWorkerFamilyMemberRequest) {
    const url = `${this.workerUrl}/${workerId}/familyMembers/${memberId}/insure`;
    return this.http.post<any>(url, request);
  }

  updateWorkerContactInfo(workerId: number, request: WorkerContactInfoDto): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/contactInfo`;
    return this.http.put<any>(url, request);
  }

  saveWorkerFiles(files: WorkerFileDto[]): Observable<any> {
    const url = `${this.workerUrl}/saveWorkerFiles`;
    return this.http.put<any>(url, files);
  }

  saveLegalizationDocument(legalizationDocumentDto: LegalizationDocumentDto): Observable<any> {
    const url = `${this.workerUrl}/saveLegalizationDocument`;
    return this.http.put<any>(url, legalizationDocumentDto);
  }

  deleteFile(fileId: number): Observable<any> {
    const url = `${this.workerUrl}/deleteFile`;
    const httpParams = new HttpParams().set('workerfileId', fileId.toString());

    return this.http.delete<any>(url, { params: httpParams });
  }

  toggleToBeAdded(workerId: number, employmentHistoryRecordId: number, request: ToggleToBeAddedRequest): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/employmentHistory/${employmentHistoryRecordId}/toggleToBeAdded`;
    return this.http.put<any>(url, request);
  }

  updateWorkerEmploymentHistoryRecord(
    workerId: number,
    employmentHistoryRecordId: number,
    request: CreateOrUpdateWorkerEmploymentHistoryRecordRequest,
  ) {
    const url = `${this.workerUrl}/${workerId}/employmentHistory/${employmentHistoryRecordId}`;
    return this.http.patch<number>(url, request);
  }

  updateWorkerEducationHistoryRecord(workerId: number, educationHistoryRecordId: number, request: CreateOrUpdateWorkerEducationHistoryRecordRequest) {
    const url = `${this.workerUrl}/${workerId}/educationHistory/${educationHistoryRecordId}`;
    return this.http.patch<number>(url, request);
  }

  deleteWorkerEducationHistoryRecord(workerId: number, educationHistoryRecordId: number): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/educationHistory/${educationHistoryRecordId}`;
    return this.http.delete<any>(url);
  }

  updateWorkerEducationDegree(workerId: number, educationDegreeId: number) {
    const url = `${this.workerUrl}/${workerId}/educationHistory/degree/${educationDegreeId}`;
    return this.http.post<number>(url, null);
  }

  updateWorkerFamilyMember(workerId: number, memberId: number, request: CreateOrUpdateWorkerFamilyMemberRequest) {
    const url = `${this.workerUrl}/${workerId}/familyMembers/${memberId}`;
    return this.http.put<number>(url, request);
  }

  updateInternalWorker(workerId: number, internalWorker: InternalWorkerDto): Observable<ApiResult<any>> {
    const url = `${this.workerUrl}/internalworker/${workerId}`;
    return this.http.post<ApiResult<any>>(url, internalWorker);
  }

  updateInternalWorkerEmployerObjects(request: UpdateInternalWorkerEmployerObjectsRequest): Observable<ApiResult<any>> {
    const url = `${this.workerUrl}/updateInternalworkerEmployerObjects`;
    return this.http.post<ApiResult<any>>(url, request);
  }

  updateWorkerSettings(workerId: number, request: WorkerProfileSettingsRequest): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/settings`;
    return this.http.patch<any>(url, request);
  }

  getWorkerCompaniesList(workerId: number): Observable<DictionaryItem[]> {
    const url = `${this.workerUrl}/${workerId}/companies`;
    return this.http.get<DictionaryItem[]>(url);
  }

  getWorkerDirectSupervisor(workerId: number): Observable<any> {
    const url = `${this.workerUrl}/${workerId}/companies`;
    return this.http.get<any>(url);
  }

  getWorkerAddress(workerId: number): Observable<WorkerAddressResponse> {
    const url = `${this.workerUrl}/${workerId}/address`;
    return this.http.get<WorkerAddressResponse>(url);
  }

  getAccessToRegulationsAndProcedures(workerId: number): Observable<boolean> {
    const url = `${this.workerUrl}/accessToRegulationsAndProcedures/${workerId}`;
    return this.http.get<boolean>(url);
  }
}
