import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize, first, of, tap } from 'rxjs';
import { Messages } from 'src/app/common/enums/messages';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { AgreementToLogWork } from 'src/app/models/dtos/agreement-to-log-work';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { LogWorkRequest } from 'src/app/contracts/requests/log-work.request';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-log-work-modal',
  templateUrl: './log-work-modal.component.html',
  styleUrls: ['./log-work-modal.component.scss'],
  providers: [DatePipe],
})
export class LogWorkModalComponent implements OnInit {
  @Output() backdropClicked = new EventEmitter<void>();

  employerName: string;
  registrationText: string = '';
  countdownText: string = '';
  formGroup: UntypedFormGroup;
  selectedAgreement: AgreementToLogWork;

  private dateNow = new Date();
  private countdownInSeconds: number = 5;
  private oneSecondInMiliseconds: number = 1000;
  private cancelInterval: boolean = false;

  private date: string;
  private time: string;
  private interval: ReturnType<typeof setTimeout>;

  @ViewChild('matSelect') matSelect: MatSelect;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number; qrCode: string; agreements: AgreementToLogWork[] },
    private dialogRef: MatDialogRef<LogWorkModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private timesheetService: TimesheetService,
    private snackBarService: SnackBarService,
    private datePipe: DatePipe
  ) 
  { }

  private setDateAndTime() {
    this.date = this.datePipe.transform(this.dateNow, 'dd.MM.yyyy');
    this.time = this.datePipe.transform(this.dateNow, 'HH:mm');
  }

  ngOnInit(): void {
    this.setDateAndTime();
    this.buildFormGroup();
    this.handleAgreementList();
    this.onAgreementToLogWorkChanges();
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }

  ngAfterViewInit() {
    this.onAgreementToLogWorkChanges();
  }

  emitBackdropClick() {
    this.backdropClicked.emit();
  }

  private startCountdown(): void {
    this.clearInterval();
    this.cancelInterval = false;

    let countdown = this.countdownInSeconds;
    this.updateCountdownText(countdown);

    this.interval = setInterval(() => {
      countdown--;
      this.updateCountdownText(countdown);

      if (countdown === 0) {
        this.onCountdownFinish();
      }
    }, this.oneSecondInMiliseconds);
  } 

  private updateCountdownText(countdown: number) {
    this.countdownText = this.translateService.instant('LogWork.AutomaticRegistrationCounter', { countdown });
  }

  private onCountdownFinish() {
    this.clearInterval();
    this.closeDialog(true);

    if (!this.cancelInterval)
    {
      this.submit();
    }
  }

  submit(changeStartStop: boolean = false, cancelInterval: boolean = false) {
    this.cancelInterval = cancelInterval;
    clearInterval(this.interval);

    this.createOrUpdateTimesheetRecord(this.createRequest(changeStartStop));
  }

  createRequest(changeStartStop: boolean) : LogWorkRequest {
    return {
      WorkerId: this.data.workerId,
      AgreementId: this.selectedAgreement.Id,
      QrCode: this.data.qrCode,
      TimesheetRecordId: this.selectedAgreement.ActiveTimesheetRecordId,
      IsWorkStart: (!this.selectedAgreement.ActiveTimesheetRecordId && !changeStartStop) || (!!this.selectedAgreement.ActiveTimesheetRecordId && changeStartStop)
    }
  }

  private createOrUpdateTimesheetRecord(request: LogWorkRequest) {
    const action$ = !request.IsWorkStart && this.selectedAgreement.ActiveTimesheetRecordId ? 
      this.timesheetService.updateLogWorkEntry(request) : 
      this.timesheetService.createLogWorkEntry(request);
    
    const message = !request.IsWorkStart && this.selectedAgreement.ActiveTimesheetRecordId ? Messages.SuccessfullyUpdatedTimesheet : Messages.SuccessfullyCreatedTimesheet;

    this.spinner.show();
    this.closeDialog(true);

    action$
    .pipe(
      first(),
      tap(() => {
        this.snackBarService.openSuccessSnackBar(message);
      }),
      finalize(() => this.spinner.hide())
    )
    .subscribe();
  }

  private onAgreementToLogWorkChanges() {
    this.formGroup.get('activeAgreement').valueChanges.subscribe((agreementId) => {
      const selectedAgreement = this.data.agreements.find((agreement) => agreement.Id === agreementId);
      if (selectedAgreement) {
        this.onAgreementSelect(selectedAgreement);
      }
    });
  }

  private handleAgreementList() {
    const agreements = this.data.agreements;

    if (agreements.length === 0) {
      this.snackBarService.openErrorSnackBar(Messages.NoAgreementsToLogWorkTime);
      this.closeDialog(true); 
    } else if (agreements.length === 1) {
      this.formGroup.get('activeAgreement')?.setValue(agreements[0].Id, { emitEvent: true });
      this.onAgreementSelect(agreements[0]);
    } else if (agreements.length > 1) {
      setTimeout(() => {
        this.matSelect.focus();
        this.matSelect.open();
      }, 0);
    }
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      activeAgreement: [null, [Validators.required]],
    });
  }

  private onAgreementSelect(agreement: AgreementToLogWork) {
    this.startCountdown();
    this.selectedAgreement = agreement;
    let translationKey = agreement.ActiveTimesheetRecordId ? 'LogWork.RegistrateEndWorkText' : 'LogWork.RegistrateStartWorkText';
    this.employerName = agreement.EmployerName;

    this.registrationText = this.translateService.instant(translationKey, { date: this.date, time: this.time });
  }

  cancel() {
    this.cancelInterval = true;
    this.clearInterval();
    this.closeDialog(false);
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private closeDialog(redirectToTimesheets : boolean): void {
    this.dialogRef.close(redirectToTimesheets);
  }
}
