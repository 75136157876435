<div fxLayout="row" class="container" fxFlex="100" fxLayoutAlign="space-between" [formGroup]="formGroup">
  <!-- First column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Company' | translate }}</mat-label>
      <input
        matInput
        formControlName="company"
        placeholder="{{ 'Company' | translate }}"
        [matAutocomplete]="autocompleteEmployer"
        [errorStateMatcher]="matcher"
        tabindex="1"
      />
      <mat-autocomplete #autocompleteEmployer="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let company of companies$ | async" [value]="company">
          {{ company.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentDateFrom' | translate }}</mat-label>
        <input matInput [matDatepicker]="employmentDateFromPicker" formControlName="employmentDateFrom" autocomplete="date" tabindex="5" />
        <mat-datepicker-toggle [for]="employmentDateFromPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #employmentDateFromPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PresencePerMonthListFilters.HasCorrectionWorkingHours' | translate }}</mat-label>
        <mat-select formControlName="hasCorrectionWorkingHours" multiple tabindex="9">
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Second column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
      <input
        matInput
        formControlName="employerObject"
        [placeholder]="'EmployerObject' | translate"
        [matAutocomplete]="autocompleteEmployerObject"
        [errorStateMatcher]="matcher"
        tabindex="2"
      />
      <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
        <mat-option *ngFor="let employerObject of employerObjects$ | async" [value]="employerObject">
          {{ employerObject.Name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EmploymentDateTo' | translate }}</mat-label>
        <input matInput [matDatepicker]="employmentDateToPicker" formControlName="employmentDateTo" autocomplete="date" tabindex="6" />
        <mat-datepicker-toggle [for]="employmentDateToPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #employmentDateToPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PresencePerMonthListFilters.HasCorrectionDayWorkingHours' | translate }}</mat-label>
        <mat-select formControlName="hasCorrectionDayWorkingHours" multiple tabindex="10">
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Third column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FirstName' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" tabindex="3" />
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PresencePerMonthListFilters.HasHomeOfficeOnDemand' | translate }}</mat-label>
        <mat-select formControlName="hasHomeOfficeOnDemand" multiple tabindex="7">
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PresencePerMonthListFilters.HasCorrectionNightWorkingHours' | translate }}</mat-label>
        <mat-select formControlName="hasCorrectionNightWorkingHours" multiple tabindex="11">
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Four column -->
  <div class="column" fxLayout="column" fxFlex="24">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'LastName' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" tabindex="4" />
    </mat-form-field>

    <div class="control">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'PresencePerMonthListFilters.HasConcludedHomeOfficeRemote' | translate }}</mat-label>
        <mat-select formControlName="hasConcludedHomeOfficeRemote" multiple tabindex="8">
          <mat-option [value]="true">{{ 'Yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'No' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="control">
      <ng-content></ng-content>
    </div>
  </div>
</div>
