<div class="mandate-agreement-worked-day-list container">
  <div class="content">
    <div class="content-card filters">
      <div class="top">
        <div class="top__right">
          <mat-form-field *ngIf="showFilterPresetSelector" [@inOutAnimation] appearance="outline" class="filter-preset-selector">
            <mat-label>Filtr</mat-label>
            <mat-select [value]="selectedFilterPresetId" (valueChange)="setSelectedFilterPresetId($event)">
              <ng-container *ngFor="let preset of filterPresets">
                <mat-option [value]="preset.Id">{{
                  preset.Name === defaultFilterPresetName ? ('DefaultFilterPresetName' | translate) : preset.Name
                }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <button (click)="resetFilters()" class="top__button top__button-clear">
            <mat-icon>close</mat-icon>
            {{ 'Reset' | translate }}
          </button>
          <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
            <mat-icon>filter_alt</mat-icon>
            {{ 'Filter' | translate }}
          </button>
        </div>
      </div>
      <app-mandate-agreement-worked-day-list-filters *ngIf="areFiltersExpanded" [formGroup]="filtersFormGroup" (filterGridData)="filterData($event)">
        <div class="favorite-button-row-container">
          <button (click)="saveFilterPresetButtonClick()" class="favorite-button">
            <ng-container *ngIf="isSavedFilterPreset; then unsaveBtn; else saveBtn"></ng-container>
            <ng-template #saveBtn>
              <mat-icon>bookmark_border</mat-icon>
              {{ 'SaveFilter' | translate }}
            </ng-template>
            <ng-template #unsaveBtn>
              <mat-icon>bookmark</mat-icon>
              {{ 'UnsaveFilter' | translate }}
            </ng-template>
          </button>
        </div>
      </app-mandate-agreement-worked-day-list-filters>
    </div>

    <div class="content-card" [ngClass]="{ 'filters-opened': areFiltersExpanded }">
      <div #tableContainer class="wrapper custom-table">
        <cdk-virtual-scroll-viewport gridTableVirtualScroll (scrolledIndexChange)="nextBatch($event)">
          <mat-table
            class="table"
            matSort
            matSortActive="CreatedOn"
            matSortDirection="desc"
            matSortDisableClear
            [dataSource]="dataSource"
            (matSortChange)="onSortChange()"
            [trackBy]="trackBy"
          >
            <ng-container matColumnDef="CompanyName">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.CompanyName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.CompanyName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmployerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.EmployerName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmployerName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="WorkerFullName">
              <mat-header-cell *matHeaderCellDef mat-sort-header="LastName" class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.WorkerFullName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><a
                  class="user"
                  [routerLink]="['/profile/' + element.WorkerId]"
                  [matTooltip]="'WL-ShowWokerProfile' | translate"
                  matTooltipPosition="above"
                  >{{ element.LastName }} {{ element.FirstName }}</a
                >
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmploymentDateFrom">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.EmploymentDateFrom' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmploymentDateFrom | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmploymentDateTo">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.EmploymentDateTo' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmploymentDateTo | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="ShareDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.ShareDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.ShareDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="FirstDownloadDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'MandateAgreementWorkedDayList.FirstDownloadDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.FirstDownloadDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef>{{ 'WAD-Actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-button class="button-text" [matMenuTriggerFor]="menuRow">
                  <span>{{ 'Actions' | translate }}</span>
                  <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                </button>
                <mat-menu #menuRow="matMenu" panelClass="actions-menu">
                  <button mat-button (click)="onShowDocumentClick(element)">
                    <mat-icon class="material-icons-outlined">find_in_page</mat-icon>
                    <span class="button-text">{{ 'MandateAgreementWorkedDayList.ShowDocument' | translate }}</span>
                  </button>

                  <button mat-button (click)="onDownloadDocumentClick(element)">
                    <mat-icon class="material-icons-outlined">file_download</mat-icon>
                    <span class="button-text">{{ 'MandateAgreementWorkedDayList.DownloadDocument' | translate }}</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="progress">
              <mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
                <mat-progress-bar
                  [mode]="dataSource.isLoading ? 'indeterminate' : 'determinate'"
                  class="table-progress"
                  [class.show]=""
                  color="accent"
                >
                </mat-progress-bar>
              </mat-header-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky" [style.top.px]="offset$ | async"></mat-header-row>
            <mat-header-row *matHeaderRowDef="['progress']; sticky: sticky" class="progress" [style.top.px]="(offset$ | async) + 56">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <app-item-counter class="item-counter" [position]="scrollItemPosition" [length]="dataSource.absoluteDataLength"></app-item-counter>
  </div>
</div>
