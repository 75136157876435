import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PresenceService } from 'src/app/data/presence.service';
import { Filter } from 'src/app/models/common/filter';
import { PresencePerDayDto } from 'src/app/models/dtos/presence-per-day-dto';

interface PagingRequest {
  Year: number;
  Month: number;
  Day: number;
  Page: number;
  PageSize: number;
  SortingDirection: string;
  SortingField: string;
  Filters: Filter[]
}

@Injectable({
  providedIn: 'root',
})
export class PresencePerDayListDataSource {
  countSubject = new BehaviorSubject<number>(0);
  count$: Observable<number> = this.countSubject.asObservable();
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  presencesSubject = new Subject<PagingRequest>();

  presences$: Observable<PresencePerDayDto[]> = this.presencesSubject.pipe(
    tap(() => this.isLoadingSubject.next(true)),
    switchMap((request) =>
      this.presenceService.getPresencesPerDay(request.Year, request.Month, request.Day, request.Page, request.PageSize, request.SortingField, request.SortingDirection, request.Filters).pipe(
        tap((res) => this.countSubject.next(res.Count)),
        map((res) => res.Results),
        takeUntil(this.cancelationSubject),
        finalize(() => this.isLoadingSubject.next(false))
      )
    )
  );

  private cancelationSubject = new Subject();

  constructor(private presenceService: PresenceService) { }
}
