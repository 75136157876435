import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../common/enums/permissions';
import { AuthGuard } from '../core/authentication/auth.guard';
import { PermissionGuard } from '../core/authentication/permission.guard';
import { PresenceListComponent } from './presence-list/presence-list.component';
import { HasPermissionToModuleGuard, ModuleName } from '../subscription-package';
import { PresencePerMonthListComponent } from './presence-per-month-list/presence-per-month-list.component';
import { PresencePerDayListComponent } from './presence-per-day-list/presence-per-day-list.component';
import { DateTime } from 'luxon';

const routes: Routes = [
  {
    path: 'presences',
    component: PresenceListComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard,
      HasPermissionToModuleGuard
    ],
    data: {
      permissions: [
        Permission.Supervisor,
        Permission.ViewAllPresencesPerDay,
        Permission.ViewMyExternalWorkerPresencesPerDay,
        Permission.ViewAllPresencesPerMonth,
        Permission.ViewMyExternalWorkerPresencesPerMonth
      ],
      moduleName: ModuleName.Presences
    },
    children: [
      {
        path: '',
        redirectTo: DateTime.now().toFormat('yyyy/MM/dd'),
        pathMatch: 'full',
      },
      {
        path: ':year/:month/:day',
        component: PresencePerDayListComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: [Permission.ViewAllPresencesPerDay, Permission.ViewMyExternalWorkerPresencesPerDay, Permission.Supervisor] },
      },
      {
        path: ':year/:month',
        component: PresencePerMonthListComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: [Permission.ViewAllPresencesPerMonth, Permission.ViewMyExternalWorkerPresencesPerMonth, Permission.Supervisor] },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class PresencesRoutingModule { }
