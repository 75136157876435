import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PresenceListComponent } from './presence-list/presence-list.component';
import { PresencePerDayListComponent } from './presence-per-day-list/presence-per-day-list.component';
import { PresencePerMonthListComponent } from './presence-per-month-list/presence-per-month-list.component';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { PresencePerDayListFiltersComponent } from './presence-per-day-list-filters/presence-per-day-list-filters.component';
import { PresencePerMonthListFiltersComponent } from './presence-per-month-list-filters/presence-per-month-list-filters.component';

@NgModule({
  imports: [
    SharedModule,
    CustomControlsModule
  ],
  declarations: [
    PresenceListComponent,
    PresencePerDayListComponent,
    PresencePerMonthListComponent,
    PresencePerDayListFiltersComponent,
    PresencePerMonthListFiltersComponent
  ],
  exports: [PresenceListComponent],
})
export class PresencesModule { }
