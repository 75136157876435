import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { FilterOperators } from 'src/app/common/enums';
import { PropertyFilterOperator } from 'src/app/common/interfaces/property-filter-operator';
import { buildFilterArray } from 'src/app/common/utils';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { Filter } from 'src/app/models/common/filter';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { DisableErrorStateMatcher } from 'src/app/shared/error-state-matchers/disable-error-state-matcher';

@Component({
  selector: 'app-presence-per-day-list-filters',
  templateUrl: './presence-per-day-list-filters.component.html',
  styleUrl: './presence-per-day-list-filters.component.scss'
})
export class PresencePerDayListFiltersComponent implements OnInit, OnDestroy {
  private readonly timeBetweenInput = 300;

  @Input() formGroup: UntypedFormGroup;
  @Output() filterGridData = new EventEmitter<Filter[]>();

  companies$: Observable<DictionaryItem[]>;
  employerObjects$: Observable<DictionaryItem[]>;
  workModes$: Observable<DictionaryItem[]> = this.dictionaryService.getWorkModes();

  private readonly unsubscribe$ = new Subject<void>();
  public readonly matcher = new DisableErrorStateMatcher();

  public static readonly operatorsMap: Map<string, PropertyFilterOperator> = new Map<string, PropertyFilterOperator>([
    ['firstName', { property: 'FirstName', operator: FilterOperators.Contains }],
    ['lastName', { property: 'LastName', operator: FilterOperators.Contains }],
    ['employerObjectId', { property: 'EmployerObjectId', operator: FilterOperators.Equal }],
    ['companyId', { property: 'CompanyId', operator: FilterOperators.Equal }],
    ['timeStart', { property: 'TimeStart', operator: FilterOperators.Custom }],
    ['timeEnd', { property: 'TimeEnd', operator: FilterOperators.Custom }],
    ['workModeId', { property: 'WorkModeIds', operator: FilterOperators.Custom }]
  ]);

  constructor(private dictionaryService: DictionaryService) { }

  get employerObject(): UntypedFormControl {
    return this.formGroup.get('employerObject') as UntypedFormControl;
  }
  get employerObjectId(): UntypedFormControl {
    return this.formGroup.get('employerObjectId') as UntypedFormControl;
  }
  get company(): UntypedFormControl {
    return this.formGroup.get('company') as UntypedFormControl;
  }
  get companyId(): UntypedFormControl {
    return this.formGroup.get('companyId') as UntypedFormControl;
  }
  get timeStart(): UntypedFormControl {
    return this.formGroup.get('timeStart') as UntypedFormControl;
  }
  get timeEnd(): UntypedFormControl {
    return this.formGroup.get('timeEnd') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.onEmployerObjectChange();
    this.onCompanyChange();

    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(this.timeBetweenInput)).subscribe(() => {
      if (this.formGroup.invalid) {
        return;
      }

      this.filterGridData.emit(buildFilterArray(this.formGroup, PresencePerDayListFiltersComponent.operatorsMap));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  resetFilters(): void {
    this.formGroup.reset();
  }

  displayValue(value: DictionaryItem): string | undefined {
    return value?.Name;
  }

  private onCompanyChange() {
    this.companies$ = this.company.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => {
        if ((value?.Id)) {
          this.formGroup.patchValue({ companyId: value?.Id });
        } else if (!value) {
          this.company.reset(undefined, { emitEvent: false });
          this.companyId.reset();
        }
      }),
      switchMap((value: string) => (value ? this.dictionaryService.getCompanies(value) : of([]))),
    );
  }

  private onEmployerObjectChange() {
    this.employerObjects$ = this.employerObject.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.timeBetweenInput),
      tap((value: any) => {
        if ((value?.Id)) {
          this.formGroup.patchValue({ employerObjectId: value?.Id });
        } else if (!value) {
          this.employerObject.reset(undefined, { emitEvent: false });
          this.employerObjectId.reset();
        }
      }),
      switchMap((value: string) => (value ? this.dictionaryService.getEmployerObjects(value) : of([]))),
    );
  }
}
