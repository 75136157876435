<div fxLayout="column" fxFlex="100" fxLayoutGap="20px">
  <div class="container" fxLayout="column" fxFlex="100" fxLayoutGap="20px">
    <h1 class="header">{{ 'PresenceList.PresenceList' | translate }}</h1>
    <div class="content-card">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)" mat-stretch-tabs="false">
        <mat-tab *ngFor="let link of navigation" [label]="'PresenceList.' + link.listType | translate" [visibleWithPermissions]="link.permissions">
        </mat-tab>
      </mat-tab-group>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
