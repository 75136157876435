import { LegalizationService } from 'src/app/data/legalization.service';
import { EmploymentAgreementService } from 'src/app/data/employment-agreement.service';
import { AgreementTypeEnum } from '../../../../models/enums/worker-agreement-type-enum';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { delay, finalize, first, switchMap, tap } from 'rxjs/operators';
import { download } from 'src/app/common/utils/downloadFile';
import { DownloadService } from 'src/app/data/download.service';
import { WorkerAgreementService } from 'src/app/data/worker-agreement.service';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { WorkerAgreementsGridDataSource } from './agreements.datasource';
import { AlertDialogComponent } from 'src/app/shared/messages/alert-dialog/alert-dialog.component';
import { WorkerFormStatusEnum } from 'src/app/models/enums/WorkerFormStatusEnum';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Permission } from 'src/app/common/enums/permissions';
import { WorkerAgreementGridDto } from 'src/app/models/dtos/worker-agreement-grid-dto';
import { TerminateAgreementModalComponent } from 'src/app/worker-agreement-termination/terminate-agreement-modal/terminate-agreement-modal.component';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerService } from 'src/app/data/worker.service';
import { WorkerSettingsDto } from 'src/app/models/dtos/worker-settings-dto';
import { isAgreementFileReady } from 'src/app/common/utils/agreement-utils';
import { DatePipe } from '@angular/common';
import { PdfViewerService } from 'src/app/shared/services/pdf-viewer.service';
import { LegalizationStatusEnum } from 'src/app/models/enums/legalization-status-enum';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
})
export class AgreementsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'EmployerName',
    'EmploymentTypeId',
    'AgreementType',
    'AgreementStartDate',
    'AgreementEndDate',
    'AgreementConclusionDate',
    'Status',
    'Actions',
  ];

  dataSource: WorkerAgreementsGridDataSource;
  profile: WorkerProfile;

  settings: WorkerSettingsDto;

  EmploymentType = EmploymentType;

  public readonly workerAgreementStatusEnum = WorkerAgreementStatusEnum;

  private readonly notAllowedToAddNewWorkerAgreementMessage = 'NotAllowedToAddNewWorkerAgreementMessage';
  private readonly notAllowedToEditWorkerAgreementMessage = 'NotAllowedToEditWorkerAgreementMessage';
  private readonly employeeIsInLegalizationProcess: string = 'EmployeeIsInLegalizationProcess';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private downloadService: DownloadService,
    private workerService: WorkerService,
    private workerAgreementService: WorkerAgreementService,
    private employmentAgreementService: EmploymentAgreementService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private pdfViewerService: PdfViewerService,
    private legalizationService: LegalizationService
  ) {
    this.profile = this.route.snapshot.data.workerProfile;
  }

  get authServerUserId(): string {
    return this.profile?.AuthServerUserId;
  }

  ngOnInit(): void {
    this.dataSource = new WorkerAgreementsGridDataSource(this.workerAgreementService, this.route.snapshot.params.workerId);

    this.fetchWorkerSettings();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.translateService.onLangChange)
      .pipe(
        tap(() =>
          this.dataSource.agreementsSubject.next({
            page: this.paginator.pageIndex + 1,
            count: this.paginator.pageSize,
            sortingField: this.sort.active,
            sortingDirection: this.sort.direction,
          }),
        ),
      )
      .subscribe();

    this.dataSource.loading$
      .pipe(
        delay(0),
        tap((x) => {
          x ? this.spinner.show() : this.spinner.hide();
        }),
      )
      .subscribe();
  }

  fetchWorkerSettings(): void {
    if (
      !this.authService.hasPermission(Permission.ManageAllExternalWorkersList) &&
      !this.authService.hasPermission(Permission.ManageMyExternalWorkersList)
    )
      return;

    this.workerService
      .getWorkerSettings(this.profile.WorkerId)
      .pipe(first())
      .subscribe((settings) => (this.settings = settings));
  }

  onTerminateClick(
    workerId: number,
    employer: string,
    agreementId: number,
    noticePeriodId: number,
    employmentDateFrom: Date,
    employmentDateTo: Date,
    employmentTypeId: number,
    hasAgreementTerminationReason: boolean
  ) {
    this.dialog.open(TerminateAgreementModalComponent, {
      data: {
        WorkerId: workerId,
        WorkerFullName: this.profile.FullName,
        Employer: employer,
        WorkerAgreementId: agreementId,
        NoticePeriodId: noticePeriodId,
        EmploymentDateFrom: employmentDateFrom,
        EmploymentDateTo: employmentDateTo,
        EmploymentTypeId: employmentTypeId,
        HasAgreementTerminationReason: hasAgreementTerminationReason
      },
    });
  }

  onExtendAgreementClick(agreementId: number, workerId: number, employmentTypeId: number) {
    this.spinner.show();

    const action$ = employmentTypeId === EmploymentType.MandateAgreement ?
      this.workerAgreementService.extendWorkerMandateAgreement(agreementId) :
      this.employmentAgreementService.extendEmploymentAgreement(agreementId);

    this.legalizationService
      .verifyWorkerLegalizationStatus(this.profile.WorkerId)
      .pipe(
        first(),
        switchMap(() =>
          action$
            .pipe(
              first()
            )
        ),
        finalize(() => this.spinner.hide())
      )
      .subscribe((agreementId) => this.router.navigate(['/workers', this.profile.WorkerId, 'employmentType', employmentTypeId, 'agreements', agreementId]));
  }

  replaceAgreement(agreementId: number, workerId: number, employmentTypeId: number) {
    this.spinner.show();

    this.legalizationService
      .verifyWorkerLegalizationStatus(this.profile.WorkerId)
      .pipe(
        first(),
        switchMap(() =>
          this.workerAgreementService.replaceWorkerAgreement(agreementId)
            .pipe(
              first(),
            )
        ),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((agreementId) => this.router.navigate(['/workers', workerId, 'employmentType', employmentTypeId, 'agreements', agreementId]));
  }

  isReplaceAgreementButtonVisible(agreementStatusId: number, employmentTypeId: number): boolean {
    return (
      (agreementStatusId === WorkerAgreementStatusEnum.Accepted || agreementStatusId === WorkerAgreementStatusEnum.Active) &&
      employmentTypeId === EmploymentType.MandateAgreement
    );
  }

  onShowAgreementClick(agreementId: number) {
    this.pdfViewerService.show({
      Endpoint: 'workerAgreements/workerAgreementFile',
      FileId: agreementId,
      FileName: this.setFileName(this.profile.FullName)
    });
  }

  isAddAgreementButtonEnabled = (employmentTypeId: EmploymentType) => this.settings?.EmploymentTypeIds?.some((id) => id == employmentTypeId);

  onAddAgreementClick(employmentTypeId: EmploymentType): void {
    if (this.profile.WorkerFormStatusId != WorkerFormStatusEnum.Approved) {
      this.openAlertDialog(this.notAllowedToAddNewWorkerAgreementMessage);
      return;
    }

    this.legalizationService
      .verifyWorkerLegalizationStatus(this.profile.WorkerId)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(() => this.router.navigate(['workers', this.profile.WorkerId, 'employmentType', employmentTypeId, 'agreements']));
  }

  onEditAgreementClick(workerAgreement: WorkerAgreementGridDto): void {
    if (!!this.profile.LegalizationStatusId && this.profile.LegalizationStatusId !== LegalizationStatusEnum.Approved) {
      this.openAlertDialog(this.employeeIsInLegalizationProcess);
      return;
    }

    if (this.profile.WorkerFormStatusId != WorkerFormStatusEnum.Approved) {
      this.openAlertDialog(this.notAllowedToEditWorkerAgreementMessage);
      return;
    }

    if (workerAgreement.Status !== WorkerAgreementStatusEnum.WaitingForGeneration) {
      return;
    }

    this.router.navigate([
      '/workers',
      workerAgreement.WorkerId,
      'employmentType',
      workerAgreement.EmploymentTypeId,
      'agreements',
      workerAgreement.AgreementId,
    ]);
  }

  onDownloadAgreementClick(agreementId: number) {
    this.downloadService
      .getFileAsBlob('workerAgreements/workerAgreementFile', agreementId, this.setFileName(this.profile.FullName))
      .pipe(first())
      .subscribe((srcUrl) => download(srcUrl, this.setFileName(this.profile.FullName)));
  }

  isEditAgreementButtonVisible(status: WorkerAgreementStatusEnum): boolean {
    return (
      (this.authService.hasPermission(Permission.ManageAllExternalWorkersList) ||
        this.authService.hasPermission(Permission.ManageMyExternalWorkersList)) &&
      status == WorkerAgreementStatusEnum.WaitingForGeneration
    );
  }

  isExtendAgreementButtonVisible = (status: WorkerAgreementStatusEnum, agreementTypeId: number): boolean =>
    (status == WorkerAgreementStatusEnum.Accepted || status == WorkerAgreementStatusEnum.Active) && agreementTypeId !== AgreementTypeEnum.Permanent;

  isTerminateButtonVisible = (status: number, employmentType: EmploymentType): boolean =>
    ((this.authServerUserId == this.authService.authServerUserId && this.authService.hasPermission(Permission.TerminateMyAgreement)) || this.authService.hasPermission(Permission.TerminateMandateAgreement)) &&
    status === WorkerAgreementStatusEnum.Active &&
    employmentType === EmploymentType.MandateAgreement;

  isDownloadAgreementVisible = (status: WorkerAgreementStatusEnum, isImported: boolean): boolean =>
    isAgreementFileReady(status) && !isImported;

  isWaitingForGenerationStatus(status: number): boolean {
    return status == WorkerAgreementStatusEnum.WaitingForGeneration;
  }

  isShowAgreementButtonVisible(status: number, isImported: boolean): boolean {
    return !this.isWaitingForGenerationStatus(status) && !isImported
  }

  createAgreementEndDateTooltipText(element: WorkerAgreementGridDto) {
    const datepipe: DatePipe = new DatePipe('en-US');

    let tooltipText = `${this.translateService.instant('TerminationSubmissionDate')}: ${datepipe.transform(element.AgreementTerminationSubmissionDate, 'yyyy-MM-dd',)}
    ${this.translateService.instant('TerminationStartDate')}: ${datepipe.transform(element.AgreementTerminationStartDate, 'yyyy-MM-dd')}
    ${this.translateService.instant('AgreementTerminationEndDate')}: ${datepipe.transform(element.AgreementTerminationEndDate, 'yyyy-MM-dd')}`;

    if (!!element.LastDayOfWork) {
      tooltipText += `\n${this.translateService.instant('LastDayOfWork')}: ${datepipe.transform(element.LastDayOfWork, 'yyyy-MM-dd')}`
    }

    return tooltipText
  }

  private setFileName(fullName: string): string {
    return `Umowa ${fullName}.pdf`;
  }

  private openAlertDialog(message: string): void {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: message,
      },
    });
  }
}
