import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PresenceDto } from '../models/dtos/presence-dto';
import { Filter } from '../models/common/filter';
import { IPagedResult } from '../shared/models/PagedResult';
import { APP_CONFIG } from '../app-config.token';
import { AppConfig } from '../app-config';
import { PresencePerDayDto } from '../models/dtos/presence-per-day-dto';
import { PresencePerMonthDto } from '../models/dtos/presence-per-month-dto';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  private readonly API_URL = this.config.resourceApiURI + '/presences';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  getPresences(
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<PresenceDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.API_URL}`;
    return this.http.get<IPagedResult<PresenceDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          StartDate: a.StartDate && new Date(a.StartDate + 'Z'),
          EndDate: a.EndDate && new Date(a.EndDate + 'Z'),
        })),
      })),
    );
  }

  getPresencesPerDay(
    year: number,
    month: number,
    day: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<PresencePerDayDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.API_URL}/${year}/${month}/${day}`;

    return this.http.get<IPagedResult<PresencePerDayDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          Periods: a.Periods.sort((left, right) => left.StartDate && right.StartDate ? new Date(left.StartDate).getTime() - new Date(right.StartDate).getTime() : -1).map(p => ({
            ...p,
            StartDate: p.StartDate && new Date(p.StartDate + 'Z'),
            EndDate: p.EndDate && new Date(p.EndDate + 'Z'),
          }))
        }))
      }))
    );
  }

  getPresencesPerDay1(
    year: number,
    month: number,
    day: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<PresencePerDayDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.API_URL}/a/${year}/${month}/${day}`;

    return this.http.get<IPagedResult<PresencePerDayDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          Periods: a.Periods.sort((left, right) => left.StartDate && right.StartDate ? new Date(left.StartDate).getTime() - new Date(right.StartDate).getTime() : -1).map(p => ({
            ...p,
            StartDate: p.StartDate && new Date(p.StartDate + 'Z'),
            EndDate: p.EndDate && new Date(p.EndDate + 'Z'),
          }))
        }))
      }))
    );
  }

  getPresencesPerMonth(
    year: number,
    month: number,
    page: number,
    count: number,
    sortingField: string,
    sortingDirection: string = 'desc',
    filters: Filter[],
  ): Observable<IPagedResult<PresencePerMonthDto>> {
    let params = new HttpParams()
      .set('page', `${page || 1}`)
      .set('count', `${count || 10}`)
      .set('sortingField', sortingField)
      .set('sortingDirection', sortingDirection)
      .set('filterString', JSON.stringify(filters));

    const url = `${this.API_URL}/${year}/${month}`;

    return this.http.get<IPagedResult<PresencePerMonthDto>>(url, { params: params }).pipe(
      map((pagedResult) => ({
        ...pagedResult,
        Results: pagedResult.Results.map((a) => ({
          ...a,
          EmploymentDateFrom: a.EmploymentDateFrom && new Date(a.EmploymentDateFrom + 'Z'),
          EmploymentDateTo: a.EmploymentDateTo && new Date(a.EmploymentDateTo + 'Z')
        }))
      }))
    );
  }
}
