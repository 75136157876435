import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnInit } from '@angular/core';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import { SignalRService } from './signalr.service';
import { Observable, Subject, map, scan, switchMap } from 'rxjs';
import { QrCodeDto } from '../models/dtos/qr-code-dto';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  private readonly API_URL = this.config.resourceApiURI + '/qrCodes';
  private subject = new Subject();
  private resetSubject = new Subject<void>();
  public qRcode = this.resetSubject.pipe(
    switchMap(() => this.subject.pipe(
      scan((acc, curr) => acc += curr, '')
    ))
  );

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig, private signalRService: SignalRService) {
  }

  getQrCodes(workerId: number): Observable<QrCodeDto> {
    const url = `${this.API_URL}/qrCode?workerId=${workerId}`;
    return this.http.get<QrCodeDto>(url).pipe(
      map((res: QrCodeDto) => <QrCodeDto>{
          ...res,
          ActivityStartDate: res.ActivityStartDate && new Date(res.ActivityStartDate + 'Z'),
          ActivityEndDate: res.ActivityEndDate && new Date(res.ActivityEndDate + 'Z'),
        })
    );
  }
}