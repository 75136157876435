<div class="worker-agreements-list container">
  <div class="content">
    <div class="content-card filters">
      <div class="top">
        <div class="top__left">
          <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">{{ 'WL-Actions' | translate }}</button>
          <mat-menu #menu="matMenu" class="multiple-agreement-actions-btn">
            <button
              mat-menu-item
              (click)="generateMultipleAgreements()"
              [disabled]="!isGenerationOnMultipleAgreementsEnabled()"
              [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
            >
              <span [matTooltip]="'GenerateMultipleAgreementsTooltip' | translate" matTooltipClass="worker-agreements-list-tooltip">{{
                'GenerateMultipleAgreements' | translate
              }}</span>
            </button>
            <button
              mat-menu-item
              (click)="extendMultipleAgreements()"
              [disabled]="!isExtensionOnMultipleAgreementsEnabled()"
              [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
            >
              <span [matTooltip]="'ExtendMultipleAgreementsTooltip' | translate" matTooltipClass="worker-agreements-list-tooltip">{{
                'ExtendMultipleAgreements' | translate
              }}</span>
            </button>
            <button
              mat-menu-item
              (click)="sendMultipleAgreementsToApproval()"
              [disabled]="!isSendingMultipleAgreementsToApprovalEnabled()"
              [visibleWithPermissions]="['SendMultipleWorkerAgreementsToApproval']"
            >
              <span [matTooltip]="'SendToApprovalMultipleAgreementsTooltip' | translate" matTooltipClass="worker-agreements-list-tooltip">{{
                'SendToApprovalMultipleAgreements' | translate
              }}</span>
            </button>
            <button
              mat-menu-item
              [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
              [disabled]="!isSendAgreementExtensionConsentButtonEnabled()"
              (click)="sendAgreementExtensionConsent()"
            >
              <span matTooltip="{{ 'SendAgreementExtensionConsentTooltip' | translate }}" matTooltipClass="worker-agreements-list-tooltip">{{
                'SendAgreementExtensionConsent' | translate
              }}</span>
            </button>
            <button
              mat-menu-item
              [visibleWithPermissions]="['CreateEmploymentConditionsConfirmation']"
              [disabled]="!isCreateEmploymentConditionsConfirmationsButtonEnabled()"
              (click)="createEmploymentConditionsConfirmations()"
              [matTooltip]="'CreateEmploymentConditionsConfirmationsTooltip' | translate"
              matTooltipClass="worker-agreements-list-tooltip"
            >
              <span>{{ 'CreateEmploymentConditionsConfirmations' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="top__right">
          <mat-form-field *ngIf="showFilterPresetSelector" [@inOutAnimation] appearance="outline" class="filter-preset-selector">
            <mat-label>Filtr</mat-label>
            <mat-select [value]="selectedFilterPresetId" (valueChange)="setSelectedFilterPresetId($event)">
              <ng-container *ngFor="let preset of filterPresets">
                <mat-option [value]="preset.Id">{{
                  preset.Name === defaultFilterPresetName ? ('DefaultFilterPresetName' | translate) : preset.Name
                }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <button
            [matTooltip]="'WL-RaportInfo' | translate"
            matTooltipClass="worker-agreements-list-tooltip"
            [visibleWithPermissions]="['GenerateEmployeesAssignedToMyObjectsReport']"
            mat-raised-button
            (click)="onReportGenerateButtonClick()"
            color="accent"
            class="generate-report-button"
          >
            {{ 'WL-GenerateReport' | translate }}
          </button>
          <button (click)="resetFilters()" class="top__button top__button-clear">
            <mat-icon>close</mat-icon>
            {{ 'Reset' | translate }}
          </button>
          <button (click)="toggleFiltersPanel()" class="top__button top__button-filter">
            <mat-icon>filter_alt</mat-icon>
            {{ 'Filter' | translate }}
          </button>
        </div>
      </div>
      <app-worker-agreements-list-filters
        *ngIf="areFiltersExpanded"
        [formGroup]="filtersFormGroup"
        (filterGridData)="filterData($event)"
        [hasLegalization]="hasLegalization"
      >
        <div class="favorite-button-row-container">
          <button (click)="saveFilterPresetButtonClick()" class="favorite-button">
            <ng-container *ngIf="isSavedFilterPreset; then unsaveBtn; else saveBtn"></ng-container>
            <ng-template #saveBtn>
              <mat-icon>bookmark_border</mat-icon>
              {{ 'SaveFilter' | translate }}
            </ng-template>
            <ng-template #unsaveBtn>
              <mat-icon>bookmark</mat-icon>
              {{ 'UnsaveFilter' | translate }}
            </ng-template>
          </button>
        </div>
      </app-worker-agreements-list-filters>
    </div>

    <div class="content-card" [ngClass]="{ 'filters-opened': areFiltersExpanded }">
      <div #tableContainer class="wrapper custom-table">
        <cdk-virtual-scroll-viewport gridTableVirtualScroll (scrolledIndexChange)="nextBatch($event)">
          <mat-table
            class="table"
            matSort
            matSortActive="CreatedOn"
            matSortDirection="desc"
            matSortDisableClear
            [dataSource]="dataSource"
            (matSortChange)="onSortChange()"
            [trackBy]="trackBy"
          >
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  appearance="outline"
                  (change)="onSelectAllChxChange()"
                  [checked]="dataSource.selection.hasValue() && isAllSelected()"
                  [indeterminate]="dataSource.selection.hasValue() && !isAllSelected()"
                  [matTooltip]="'SelectAll' | translate"
                  matTooltipPosition="above"
                  matTooltipClass="worker-agreements-list-tooltip"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="onRowChxChange($event, row)"
                  [checked]="dataSource.selection.isSelected(row)"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>
            <!-- WorkerId Column -->
            <ng-container matColumnDef="WorkerId">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-WorkerId' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.WorkerId }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="LastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-Name' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><a
                  class="user"
                  [routerLink]="['/profile/' + element.WorkerId]"
                  matTooltip="{{ 'WL-ShowWokerProfile' | translate }}"
                  matTooltipPosition="above"
                  matTooltipClass="worker-agreements-list-tooltip"
                  >{{ element.LastName }} {{ element.FirstName }}</a
                >
                <div
                  [visibleWithPermissions]="['ShowLegalizations']"
                  *ngIf="hasLegalization && element.LegalizationStatusId"
                  class="legalization-status"
                >
                  <mat-icon
                    class="legalization-fill"
                    [inline]="true"
                    [matTooltip]="getLegalizationTooltip(element.LegalizationStatusId) | translate"
                    matTooltipClass="worker-agreements-list-tooltip"
                    [svgIcon]="'legalization-status-icon'"
                    [class.fill--orange]="hasLegalizationStatus(legalizationStatusEnum.ReadyForLegalization, element.LegalizationStatusId)"
                    [class.fill--yellow]="hasLegalizationStatus(legalizationStatusEnum.InProgress, element.LegalizationStatusId)"
                    [class.fill--green]="hasLegalizationStatus(legalizationStatusEnum.Approved, element.LegalizationStatusId)"
                    [class.fill--red]="hasLegalizationStatus(legalizationStatusEnum.Rejected, element.LegalizationStatusId)"
                  >
                  </mat-icon>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmployerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-EmployerName' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.EmployerName" matTooltipClass="worker-agreements-list-tooltip">{{ element.EmployerName }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmploymentTypeId">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-EmploymentType' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.EmploymentTypeId | employmentTypeString | translate" matTooltipClass="worker-agreements-list-tooltip">{{
                  element.EmploymentTypeId | employmentTypeString | translate
                }}</span></mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="AgreementType">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-AgreementType' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element"
                ><span [matTooltip]="element.AgreementType" matTooltipClass="worker-agreements-list-tooltip">{{
                  element.AgreementType
                }}</span></mat-cell
              >
            </ng-container>
            <ng-container *ngIf="employmentConditionConfirmationStatusVisible()" matColumnDef="EmploymentConditionsConfirmationStatusId">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [matTooltip]="'WL-EmploymentConditionsConfirmationStatus' | translate"
                matTooltipPosition="above"
                matTooltipClass="worker-agreements-list-tooltip"
                class="hidden-sort"
              >
                {{ 'WL-EmploymentConditionsConfirmationStatusShort' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div *ngIf="!!element.EmploymentConditionsConfirmationStatusId">
                  <div
                    *ngIf="
                      element.EmploymentConditionsConfirmationStatusId === employmentConditionsConfirmationStatusEnum.PendingGeneration;
                      else statusTemplate
                    "
                  >
                    <mat-icon
                      [matTooltip]="getEmploymentConditionsConfirmationStatusEnum(element.EmploymentConditionsConfirmationStatusId) | translate"
                      matTooltipClass="worker-agreements-list-tooltip"
                      >update</mat-icon
                    >
                  </div>
                  <ng-template #statusTemplate>
                    <mat-icon
                      *ngIf="element.EmploymentConditionsConfirmationStatusId !== employmentConditionsConfirmationStatusEnum.NoConfirmation"
                      class="employment-conditions-confirmation-status"
                      [inline]="true"
                      [matTooltip]="getEmploymentConditionsConfirmationStatusEnum(element.EmploymentConditionsConfirmationStatusId) | translate"
                      [matTooltipClass]="getEmploymentConditionsConfirmationTooltipClass(element.EmploymentConditionsConfirmationStatusId)"
                      [svgIcon]="'employment-conditions-confirmation-status-icon'"
                      [class.fill--pink]="element.EmploymentConditionsConfirmationStatusId === employmentConditionsConfirmationStatusEnum.Generated"
                      [class.fill--blue]="element.EmploymentConditionsConfirmationStatusId === employmentConditionsConfirmationStatusEnum.Signed"
                      [class.fill--darkgreen]="element.EmploymentConditionsConfirmationStatusId === employmentConditionsConfirmationStatusEnum.Read"
                    >
                    </mat-icon>
                  </ng-template>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementStartDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-AgreementStartDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.AgreementStartDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementEndDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-AgreementEndDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AgreementEndDate | date : 'yyyy-MM-dd' }}
                <mat-icon
                  *ngIf="element.AgreementTerminationEndDate"
                  class="material-icons-outlined"
                  [matTooltip]="createAgreementEndDateTooltipText(element)"
                  matTooltipClass="worker-agreements-list-tooltip"
                  >info</mat-icon
                >
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementConclusionDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'AgreementConclusionDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.AgreementConclusionDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementTerminationEndDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'AgreementTerminationEndDate' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.AgreementTerminationEndDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="WorkerStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-WorkerStatus' | translate }} <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="label"
                  [class.label--green]="element.WorkerStatusId === workerStatusEnum.Activated"
                  [class.label--orange]="element.WorkerStatusId === workerStatusEnum.ActivationInProgress"
                  [class.label--yellow]="element.WorkerStatusId === workerStatusEnum.Created"
                >
                  <span class="label__dot"></span>
                  <span class="label__name">{{ element.WorkerStatus }}</span>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="WorkerFormStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-sort">
                {{ 'WL-WorkerFormStatus' | translate }}
                <app-table-header-sort></app-table-header-sort>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="label"
                  [class.label--green]="element.WorkerFormStatusId === workerFormStatusEnum.Approved"
                  [class.label--orange]="element.WorkerFormStatusId === workerFormStatusEnum.Draft"
                >
                  <span class="label__dot"></span>
                  <span class="label__name">{{ element.WorkerFormStatus }}</span>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="AgreementStatus">
              <mat-header-cell *matHeaderCellDef> {{ 'WL-AgreementStatus' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="label"
                  [class.label--green]="
                    element.AgreementStatusId === workerAgreementStatusEnum.Accepted || element.AgreementStatusId === workerAgreementStatusEnum.Active
                  "
                  [class.label--yellow]="element.AgreementStatusId === workerAgreementStatusEnum.PendingApproval"
                  [class.label--orange]="element.AgreementStatusId === workerAgreementStatusEnum.WaitingForGeneration"
                  [class.label--blue]="element.AgreementStatusId === workerAgreementStatusEnum.Annex"
                >
                  <span class="label__dot"></span>
                  <span class="label__name">{{ element.AgreementStatus }}</span>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef>{{ 'WAD-Actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button *ngIf="hasAnyAction(element)" mat-button class="button-text" [matMenuTriggerFor]="menuRow">
                  <span>{{ 'Actions' | translate }}</span>
                  <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                </button>
                <mat-menu #menuRow="matMenu" panelClass="actions-menu">
                  <ng-container *ngIf="isEditAgreementButtonVisible(element.AgreementStatusId)">
                    <button mat-button (click)="onEditAgreementClick(element)">
                      <mat-icon class="material-icons-outlined">create</mat-icon>
                      <span class="button-text">{{ 'WL-EditWorkerAgreement' | translate }}</span>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isDetailsButtonVisible(element.AgreementStatusId)">
                    <ng-container *ngTemplateOutlet="detailsButton"></ng-container>

                    <ng-template #detailsButton>
                      <button
                        [routerLink]="[
                          '/workers',
                          element.WorkerId,
                          'employmentType',
                          element.EmploymentTypeId,
                          'agreements',
                          element.AgreementId,
                          'details'
                        ]"
                        mat-button
                      >
                        <mat-icon class="material-icons-outlined">visibility</mat-icon>
                        <span class="button-text">{{ 'WL-WorkerAgreementDetails' | translate }}</span>
                      </button>
                    </ng-template>
                  </ng-container>

                  <ng-container *ngIf="isExtendAgreementButtonVisible(element.AgreementStatusId, element.AgreementTypeId)">
                    <button
                      *ifHasPermissions="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
                      mat-button
                      (click)="onExtendAgreementClick(element.AgreementId, element.WorkerId, element.EmploymentTypeId)"
                    >
                      <mat-icon class="material-icons-outlined">published_with_changes</mat-icon>
                      <span class="button-text">{{ 'ExtendAgreement' | translate }}</span>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isReplaceAgreementButtonVisible(element.AgreementStatusId, element.EmploymentTypeId)">
                    <button *ifHasPermissions="['ReplaceAgreement']" mat-button (click)="replaceAgreement(element)">
                      <mat-icon class="material-icons-outlined">swap_horiz</mat-icon>
                      <span class="button-text">{{ 'ReplaceWorkerAgreement' | translate }}</span>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="isAgreementFileButtonVisible(element)">
                    <button
                      *ifHasPermissionsOnWorkerProfile="
                        ['AgreementPreview', 'AgreementPreviewAllExternalWorkers', 'AgreementPreviewMyExternalWorkers'];
                        authServerUserId: element.AuthServerUserId
                      "
                      mat-button
                      (click)="onShowAgreementClick(element.AgreementId, element.FirstName, element.LastName)"
                    >
                      <mat-icon class="material-icons-outlined">find_in_page</mat-icon>
                      <span class="button-text">{{ 'WL-ShowAgreementButton' | translate }}</span>
                    </button>

                    <button
                      *ifHasPermissionsOnWorkerProfile="
                        ['AgreementPreview', 'AgreementPreviewAllExternalWorkers', 'AgreementPreviewMyExternalWorkers'];
                        authServerUserId: element.AuthServerUserId
                      "
                      mat-button
                      (click)="onDownloadAgreementClick(element.AgreementId, element.FirstName, element.LastName)"
                    >
                      <mat-icon class="material-icons-outlined">file_download</mat-icon>
                      <span class="button-text">{{ 'WL-DownloadAgreementButton' | translate }}</span>
                    </button>

                    <button
                      mat-button
                      *ngIf="isTerminateButtonVisible(element)"
                      (click)="
                        onTerminateClick(
                          element.WorkerId,
                          element.FirstName + ' ' + element.LastName,
                          element.EmployerName,
                          element.AgreementId,
                          element.NoticePeriodId,
                          element.AgreementStartDate,
                          element.AgreementEndDate,
                          element.EmploymentTypeId,
                          element.HasAgreementTerminationReason
                        )
                      "
                    >
                      <mat-icon>highlight_off</mat-icon>
                      <span class="button-text">{{ 'TerminateAgreement' | translate }}</span>
                    </button>
                  </ng-container>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="progress">
              <mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
                <mat-progress-bar
                  [mode]="dataSource.isLoading ? 'indeterminate' : 'determinate'"
                  class="table-progress"
                  [class.show]=""
                  color="accent"
                >
                </mat-progress-bar>
              </mat-header-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky" [style.top.px]="offset$ | async"></mat-header-row>
            <mat-header-row *matHeaderRowDef="['progress']; sticky: sticky" class="progress" [style.top.px]="(offset$ | async) + 56">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <app-item-counter class="item-counter" [position]="scrollItemPosition" [length]="dataSource.absoluteDataLength"></app-item-counter>
  </div>
</div>
