<div class="container">
  <div class="content-card">
    <h1 class="bold">{{ externalWorkerFullName$ | async }}</h1>
    <h2 class="content-card__header">{{ 'AF-EmploymentDetails' | translate }}</h2>
    <ul class="header__nav">
      <li class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'contactInfo']" class="header__link">{{ 'WF-PersonalData' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'additionalInfo']" class="header__link">{{ 'WF-AdditionalData' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'statements']" class="header__link">{{ 'WF-Statements' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'agreements']" class="header__link header__link--active">{{ 'WP-Agreements' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'documents']" class="header__link">{{ 'WP-Documents' | translate }}</a>
      </li>
      <li class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'declarations']" class="header__link">{{ 'WP-Declarations' | translate }}</a>
      </li>
      <ng-container>
        <li *hasPermissionToModule="moduleNames.Delegations; workerId: externalWorkerId" class="header__item">
          <a [routerLink]="['/profile', externalWorkerId, 'delegations']" class="header__link">{{ 'WF-Delegations' | translate }}</a>
        </li>
      </ng-container>
      <li *hasPermissionToModule="moduleNames.Timesheets; workerId: externalWorkerId" class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'timesheets']" class="header__link">{{ 'WP-Timesheets' | translate }}</a>
      </li>
      <li *hasPermissionToModule="moduleNames.EmploymentHistory; workerId: externalWorkerId" class="header__item">
        <a [routerLink]="['/profile', externalWorkerId, 'employmentHistory']" class="header__link">{{ 'WF-EmploymentHistory' | translate }}</a>
      </li>
      <li *hasPermissionToModule="moduleNames.EmploymentSettings; workerId: externalWorkerId" class="header__item">
        <a
          [visibleWithPermissions]="['ManageAllExternalWorkersList', 'ManageMyExternalWorkersList']"
          [routerLink]="['/profile', externalWorkerId, 'settings']"
          class="header__link"
          >{{ 'WP-Settings' | translate }}</a
        >
      </li>
    </ul>
  </div>

  <form [formGroup]="agreementFormGroup" autocomplete="off">
    <div class="content-card">
      <h2 class="content-card__header">{{ 'AW-BasicData' | translate }}</h2>

      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Company' | translate }}</mat-label>
            <mat-select formControlName="company">
              <mat-option #matOption *ngFor="let company of listOfCompanies$ | async" [value]="company.Id">{{ company.Name | translate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="company.invalid && company.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Employer' | translate }}</mat-label>
            <mat-select formControlName="employer" #employerSelect>
              <mat-option #matOption *ngFor="let employer of listOfEmployers$ | async" [value]="employer.Id">{{
                employer.Name | translate
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="employer.invalid && employer.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'EmployerObject' | translate }}</mat-label>
            <input
              #employerObjectInput
              matInput
              formControlName="employerObject"
              placeholder="{{ 'EmployerObject' | translate }}"
              [matAutocomplete]="autocompleteEmployerObject"
            />
            <mat-autocomplete #autocompleteEmployerObject="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let employerObject of listOfEmployerObjects$ | async" [value]="employerObject">
                {{ employerObject.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="employerObject.invalid && employerObject.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="selectedEmployerObject?.HasWorkEmails" class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'WorkEmail' | translate }}</mat-label>
            <input matInput type="Email" placeholder="{{ 'WorkEmail' | translate }}" formControlName="workEmail" />
            <mat-error *ngIf="workEmail.invalid && workEmail.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-12">
          <mat-form-field *ngIf="isMpkActive" appearance="outline">
            <mat-label>{{ 'MPK' | translate }}</mat-label>
            <input matInput placeholder="{{ 'MPK' | translate }}" formControlName="mpk" />
          </mat-form-field>

          <ng-container *ngIf="selectedEmployerObject?.RequireSupervisorOnMultiAgreementsForm">
            <mat-form-field *hasPermissionToModule="moduleNames.Supervisors" appearance="outline">
              <mat-label>{{ 'Supervisor' | translate }}</mat-label>
              <input
                matInput
                formControlName="supervisor"
                placeholder="{{ 'Supervisor' | translate }}"
                [matAutocomplete]="autocompleteSupervisor"
                required
              />
              <mat-autocomplete #autocompleteSupervisor="matAutocomplete" [displayWith]="displayValue">
                <mat-option *ngFor="let supervisor of listOfSupervisors$ | async" [value]="supervisor">
                  {{ supervisor.Name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="supervisor.invalid && supervisor.touched">
                {{ 'InvalidField' | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>

        <div class="col-12" *ngIf="selectedEmployerObject?.HasLocalization">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Location' | translate }}</mat-label>
            <input matInput formControlName="location" placeholder="{{ 'Location' | translate }}" [matAutocomplete]="autocompleteLocation" required />
            <mat-autocomplete #autocompleteLocation="matAutocomplete" [displayWith]="displayValue">
              <mat-option *ngFor="let location of listOfLocations$ | async" [value]="location">
                {{ location.Name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="location.invalid && location.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="content-card">
      <h2 class="content-card__header">{{ 'ContractType' | translate }}</h2>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'AgreementType' | translate }}</mat-label>
            <mat-select formControlName="agreementType">
              <mat-option *ngFor="let agreementType of listOfAgreementTypes$ | async" [value]="agreementType.Id">
                {{ agreementType.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="agreementType.invalid && agreementType.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'PaymentDeadline' | translate }}</mat-label>
            <mat-select formControlName="paymentDeadline">
              <mat-option *ngFor="let paymentDeadline of listOfPaymentDeadlines$ | async" [value]="paymentDeadline.Id">
                {{ paymentDeadline.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="paymentDeadline.invalid && paymentDeadline.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'JobResponsibilities' | translate }}</mat-label>
            <mat-select formControlName="responsibilities" multiple>
              <mat-option #matOption *ngFor="let responsibility of listOfResponsibilities$ | async" [value]="responsibility.Id">
                {{ responsibility.Name | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="responsibilities.invalid && responsibilities.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'AgreementConclusionDate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickConclusionDate"
              formControlName="conclusionDate"
              [matDatepickerFilter]="conclusionDateFilter"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="pickConclusionDate"></mat-datepicker-toggle>
            <mat-datepicker #pickConclusionDate></mat-datepicker>
            <mat-error *ngIf="conclusionDate.invalid && conclusionDate.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Data obowiązywania umowy</mat-label>
            <mat-date-range-input
              [rangePicker]="campaignOnePicker"
              [comparisonStart]="agreementFormGroup.value.pickEmploymentDateFrom"
              [comparisonEnd]="agreementFormGroup.value.pickEmploymentDateTo"
            >
              <input matStartDate placeholder="Start date" formControlName="employmentDateFrom" />
              <input matEndDate placeholder="End date" formControlName="employmentDateTo" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
        </div> -->

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'EmploymentDateFrom' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickEmploymentDateFrom"
              formControlName="employmentDateFrom"
              [matDatepickerFilter]="employmentDateFromFilter"
              (dateChange)="checkDays('change', $event)"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="pickEmploymentDateFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickEmploymentDateFrom></mat-datepicker>
            <mat-error *ngIf="employmentDateFrom.invalid && employmentDateFrom.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-checkbox formControlName="isForIndefinitePeriod">
            {{ 'AgreementForAnIndefinitePeriod' | translate }}
          </mat-checkbox>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'EmploymentDateTo' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="pickEmploymentDateTo"
              formControlName="employmentDateTo"
              [matDatepickerFilter]="employmentDateToFilter"
              (dateChange)="checkDays('change', $event)"
              [required]="!isForIndefinitePeriod.value"
            />
            <mat-datepicker-toggle matSuffix [for]="pickEmploymentDateTo"></mat-datepicker-toggle>
            <mat-datepicker #pickEmploymentDateTo></mat-datepicker>
            <mat-error *ngIf="employmentDateTo.invalid && employmentDateTo.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="selectedEmployerObject?.HasWorkPattern ? 'col-lg-3' : 'col-lg-6'">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'AF-NoticePeriod' | translate }}</mat-label>
            <mat-select formControlName="noticePeriod" required>
              <mat-option *ngFor="let period of listOfNoticePeriods$ | async" [value]="period.Id">
                {{ period.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="noticePeriod.invalid && noticePeriod.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="selectedEmployerObject?.HasWorkPattern" class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'AF-WorkPattern' | translate }}</mat-label>
            <mat-select formControlName="workPattern" required>
              <mat-option *ngFor="let pattern of listOfWorkPatterns$ | async" [value]="pattern.Id">
                {{ pattern.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="workPattern.invalid && workPattern.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="wages-wrapper" *ngIf="hasAnyDescriptiveWageType$ | async; then withDescriptiveWageType; else defaultWage"></div>

      <button mat-raised-button color="primary" type="button" (click)="addNewWage()">
        <mat-icon>add_box</mat-icon>
        <span>{{ 'AF-AddWage' | translate }}</span>
      </button>
    </div>

    <div class="content-card" *ngIf="selectedEmployerObject?.HasPreliminaryMedicalExaminationRequirements">
      <h2 class="content-card__header">{{ 'OccupationalMedicineResearch' | translate }}</h2>

      <div class="row row--checkbox">
        <div class="col-12">
          <mat-checkbox formControlName="preliminaryMedicalExamination">
            {{ 'AF-PreliminaryMedicalExaminationRequirements' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="content-card">
      <h2 class="content-card__header">{{ 'AF-TheSetOfDocumentsVersion' | translate }}</h2>

      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'AF-TheSetOfDocumentsVersion' | translate }}</mat-label>
            <mat-select formControlName="agreementTemplate" required>
              <mat-option *ngFor="let template of listOfAgreementTemplates$ | async" [value]="template.Id">
                {{ template.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="agreementTemplate.invalid && agreementTemplate.touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6 pt-10">
          <button
            mat-raised-button
            color="primary"
            [disabled]="agreementFormGroup.invalid || (isWagesCountValid$ | async)"
            (click)="onGenerateDocument()"
          >
            {{ 'AF-GenerateDocument' | translate }}
          </button>
        </div>
      </div>
    </div>

    <ng-template #defaultWage>
      <div class="row" *ngFor="let wage of dataSource.data; let i = index" formArrayName="wages">
        <div class="col-3" [formGroupName]="i">
          <mat-form-field fxFill appearance="outline">
            <mat-label>{{ 'Wage' | translate }}</mat-label>
            <input matInput type="number" class="text-center" formControlName="value" min="0" required />
            <mat-error *ngIf="wages.controls[i].get('value').invalid && wages.controls[i].get('value').touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-2" [formGroupName]="i">
          <mat-form-field fxFill appearance="outline">
            <mat-label>{{ 'Currency' | translate }}</mat-label>
            <mat-select formControlName="currencyId">
              <mat-option *ngFor="let currency of listOfCurrencies$ | async" [value]="currency.Id">
                {{ currency.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="wages.controls[i].get('currencyId').invalid && wages.controls[i].get('currencyId').touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-3" [formGroupName]="i">
          <mat-form-field fxFill appearance="outline">
            <mat-label>{{ 'WageType' | translate }}</mat-label>
            <mat-select formControlName="wageTypeId">
              <mat-option
                *ngFor="let wageType of listOfWageTypes$ | async | filterWageType : wages.value : i | filterWageTypeMaxCount : wages.value : i"
                [value]="wageType.Id"
              >
                {{ wageType.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="wages.controls[i].get('wageTypeId').invalid && wages.controls[i].get('wageTypeId').touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-3" [formGroupName]="i">
          <mat-form-field fxFill appearance="outline">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <mat-select formControlName="wageDescriptionId">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let wageDescription of filterWageDescriptions(listOfWageDescriptions$ | async, i)" [value]="wageDescription.Id">
                {{ wageDescription.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="wages.controls[i].get('wageDescriptionId').invalid && wages.controls[i].get('wageDescriptionId').touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-1 wage-action-column">
          <button mat-icon-button color="primary" class="trash-button" (click)="deleteWage(i)" [disabled]="wageCounter <= miminumWages">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #withDescriptiveWageType>
      <div class="row" *ngFor="let wage of dataSource.data; let i = index" formArrayName="wages">
        <div class="col-3 const-height-col" [formGroupName]="i">
          <mat-form-field fxFill appearance="outline">
            <mat-label>{{ 'WageType' | translate }}</mat-label>
            <mat-select formControlName="wageTypeId">
              <mat-option
                *ngFor="let wageType of listOfWageTypes$ | async | filterWageType : wages.value : i | filterWageTypeMaxCount : wages.value : i"
                [value]="wageType.Id"
              >
                {{ wageType.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="wages.controls[i].get('wageTypeId').invalid && wages.controls[i].get('wageTypeId').touched">
              {{ 'InvalidField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <ng-container *ngIf="wages.controls[i].get('wageTypeId').value">
          <ng-container *ngIf="isDescriptiveType(wages.controls[i].get('wageTypeId').value); then descriptiveWageType; else valueWageType">
          </ng-container>

          <ng-template #valueWageType>
            <div class="col-3" [formGroupName]="i">
              <mat-form-field fxFill appearance="outline">
                <mat-label>{{ 'Wage' | translate }}</mat-label>
                <input matInput type="number" class="text-center" formControlName="value" min="0" required />
                <mat-error *ngIf="wages.controls[i].get('value').invalid && wages.controls[i].get('value').touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-2" [formGroupName]="i">
              <mat-form-field fxFill appearance="outline">
                <mat-label>{{ 'Currency' | translate }}</mat-label>
                <mat-select formControlName="currencyId">
                  <mat-option *ngFor="let currency of listOfCurrencies$ | async" [value]="currency.Id">
                    {{ currency.Name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="wages.controls[i].get('currencyId').invalid && wages.controls[i].get('currencyId').touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3" [formGroupName]="i">
              <mat-form-field fxFill appearance="outline">
                <mat-label>{{ 'Description' | translate }}</mat-label>
                <mat-select formControlName="wageDescriptionId">
                  <mat-option [value]="null"></mat-option>
                  <mat-option *ngFor="let wageDescription of filterWageDescriptions(listOfWageDescriptions$ | async, i)" [value]="wageDescription.Id">
                    {{ wageDescription.Name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="wages.controls[i].get('wageDescriptionId').invalid && wages.controls[i].get('wageDescriptionId').touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </ng-template>

          <ng-template #descriptiveWageType>
            <div class="col-4" [formGroupName]="i">
              <mat-form-field fxFill appearance="outline" class="dynamic-height-form">
                <mat-label>{{ getWageTypeName(wages.controls[i].get('wageTypeId').value) }}</mat-label>
                <textarea matInput formControlName="name" maxLength="2000" rows="1"></textarea>
                <mat-error *ngIf="wages.controls[i].get('name').invalid && wages.controls[i].get('name').touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-4" [formGroupName]="i">
              <mat-form-field fxFill appearance="outline" class="dynamic-height-form">
                <mat-label>{{ 'CalculationDescription' | translate }}</mat-label>
                <textarea matInput formControlName="calculationDescription" maxLength="2000" rows="1"></textarea>
                <mat-error *ngIf="wages.controls[i].get('calculationDescription').invalid && wages.controls[i].get('calculationDescription').touched">
                  {{ 'InvalidField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </ng-template>
        </ng-container>

        <div class="col-1 wage-action-column">
          <button mat-icon-button color="primary" class="trash-button" (click)="deleteWage(i)" [disabled]="wageCounter <= miminumWages">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</div>
