import { WorkModeDto } from './../../../models/dtos/work-mode-dto';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DayOfWeek } from 'src/app/common/enums/day-of-week';
import { Messages } from 'src/app/common/enums/messages';
import { Permission } from 'src/app/common/enums/permissions';
import {
  GetTimesheetRecordsForSpecificMonthResponse,
  TimesheetGridRecordDto,
} from 'src/app/contracts/responses/get-timesheet-records-for-specific-month-reponse';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { WorkerService } from 'src/app/data/worker.service';
import { InternalWorkerActiveAgreementDto } from 'src/app/models/dtos/InternalWorkerActiveAgreementDto';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerProfile } from 'src/app/models/WorkerProfile';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/shared/messages/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { TimesheetModalComponent, TimesheetModalOutput } from './timesheet-modal/timesheet-modal.component';
import { WorkModeEnum } from 'src/app/models/enums/work-mode-enum';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { TimesheetDateEditLevelEnum } from '../../../models/enums/timesheet-date-edit-level';

export interface GetTimesheetRecordsForSpecificMonthRequest {
  AgreementId: number;
  Month: number;
  Year: number;
}

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit, AfterViewInit, OnDestroy {
  private _displayedColumns: string[] = ['date', 'absence', 'start', 'end', 'timespan', 'timespanDay', 'timespanNight', 'workMode', 'actions'];
  workModeEnum = WorkModeEnum;
  activeAgreementFormGroup: UntypedFormGroup;
  selectedRow: TimesheetGridRecordDto;
  workModes: WorkModeDto[];
  timesheetDateEditLevel: number;
  canSaveOnlyPresentDay: boolean;
  hasPermissionToManageAnyDay: boolean;
  concludedHomeOfficeRemoteMonthAmount: number;
  homeOfficeOnDemandMonthAmount: number;
  homeOfficeOnDemandYearAmount: number;
  isHomeOfficeOnDemandAvailable: boolean;
  isConcludedHomeOfficeRemoteAvailable: boolean;
  EmploymentType = EmploymentType;

  get displayedColumns(): string[] {
    return this._displayedColumns
      .filter(col => col !== 'actions' || !this.isReadonly())
      .filter(col => col !== 'timespanDay' || this.activeAgreement?.EmploymentTypeId === EmploymentType.MandateAgreement)
      .filter(col => col !== 'timespanNight' || this.activeAgreement?.EmploymentTypeId === EmploymentType.MandateAgreement);
  }

  private readonly unsubscribe$ = new Subject<void>();

  private workerIdSubject = new Subject<number>();
  workerIdAction$ = this.workerIdSubject.asObservable();

  private agreementSubject = new BehaviorSubject<InternalWorkerActiveAgreementDto[]>([]);
  agreements$ = this.workerIdAction$.pipe(
    switchMap((id) =>
      this.workerService.getInternalWorkerActiveAgreements(id).pipe(
        tap((agreements) => {
          this.agreementSubject.next(agreements);
          this.setActiveAgreement(agreements);
        }),
      ),
    ),
    tap((_) => {
      if (this.agreementSubject.value.length) {
        this.monthSelectedSubject.next({
          AgreementId: this.activeAgreement?.Id || 0,
          Month: this.currentStartDate.getMonth() + 1,
          Year: this.currentStartDate.getFullYear(),
        });
      }
    }),
  );

  private monthSelectedSubject = new Subject<GetTimesheetRecordsForSpecificMonthRequest>();
  monthSelectedAction$ = this.monthSelectedSubject.asObservable();

  private totalTimespanSubject = new BehaviorSubject<number>(0);
  totalTimespan$ = this.totalTimespanSubject.asObservable();

  private totalTimespanDaySubject = new BehaviorSubject<number>(0);
  totalTimespanDay$ = this.totalTimespanDaySubject.asObservable();

  private totalTimespanNightSubject = new BehaviorSubject<number>(0);
  totalTimespanNight$ = this.totalTimespanNightSubject.asObservable();

  private isApprovedSubject = new BehaviorSubject<boolean>(false);
  isApproved$ = this.isApprovedSubject.asObservable();

  private isApprovedByWorkerSubject = new BehaviorSubject<boolean>(false);
  isApprovedByWorker$ = this.isApprovedByWorkerSubject.asObservable();

  timesheetRecords$: Observable<TimesheetGridRecordDto[]> = this.monthSelectedAction$.pipe(
    switchMap((request) => this.getTimesheetRecords(request.AgreementId, request.Month, request.Year)),
  );

  vm$ = combineLatest([this.agreements$, this.timesheetRecords$]).pipe(map(([agreements, timesheetRecords]) => ({ agreements, timesheetRecords })));

  private modalWidth: string = '550px';
  private currentStartDate: Date = new Date();
  private profile: WorkerProfile;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private timesheetService: TimesheetService,
    private workerService: WorkerService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService,
    private dialog: MatDialog
  ) {
    this.activeAgreementFormGroup = this.formBuilder.group({
      activeAgreement: [null, Validators.required],
    });
  }

  get activeAgreement(): InternalWorkerActiveAgreementDto {
    return this.activeAgreementFormGroup.get('activeAgreement').value;
  }
  get startMonthDate(): Date {
    return new Date(this.currentStartDate.getFullYear(), this.currentStartDate.getMonth(), 1);
  }
  get endMonthDate(): Date {
    return new Date(this.currentStartDate.getFullYear(), this.currentStartDate.getMonth() + 1, 0);
  }
  get earliestAgreementStartDate(): Date {
    return this.agreementSubject.value.sort((a, b) => a.EmploymentDateFrom.getTime() - b.EmploymentDateFrom.getTime())[0].EmploymentDateFrom;
  }
  get latestAgreementEndDate(): Date {
    const twoYearsFromEarliest = this.earliestAgreementStartDate.addMonthsImmutable(240);
    const latest = this.agreementSubject.value
      .map((a) => a.EmploymentDateTo)
      .filter((a) => !!a)
      .sort((a, b) => b.getTime() - a.getTime())[0];
    return latest > twoYearsFromEarliest ? latest : twoYearsFromEarliest;
  }
  get authServerUserId(): string {
    return this.profile?.AuthServerUserId;
  }

  ngOnInit(): void {
    this.route.parent.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.profile = data['workerProfile'];
      this.workerIdSubject.next(this.profile.WorkerId);
    });


    this.activeAgreementFormGroup.get('activeAgreement').valueChanges.subscribe(async activeAgreement => {
      if (activeAgreement) {
        this.hasPermissionToManageAnyDay = await firstValueFrom(this.timesheetService.getPermissionToManageAnyDay(activeAgreement?.Id));
      }

      this.fetchTimesheetRecords();
      this.fetchWorkModes();
    });

    this.vm$.pipe(
      tap(() => this.spinner.hide()),
      takeUntil(this.unsubscribe$)
    ).subscribe(); 
  }

  ngAfterViewInit(): void {
    this.workerIdSubject.next(this.profile.WorkerId);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  setActiveAgreement(agreements: InternalWorkerActiveAgreementDto[]): void {
    if (this.currentStartDate < this.earliestAgreementStartDate) this.currentStartDate = this.earliestAgreementStartDate.resetDate();
    if (this.currentStartDate > this.latestAgreementEndDate) this.currentStartDate = this.latestAgreementEndDate.resetDate();

    let filteredAgreements = agreements.filter((a) => this.filterAgreements(a));

    if (!filteredAgreements.some((a) => a.Id === this.activeAgreement?.Id) && !!filteredAgreements.length) {
      this.activeAgreementFormGroup.patchValue({
        activeAgreement: filteredAgreements[0],
      });
    }
  }

  filterAgreements = (a: InternalWorkerActiveAgreementDto) =>
    a.EmploymentDateFrom <= this.endMonthDate && (!a.EmploymentDateTo || this.startMonthDate <= a.EmploymentDateTo);

  nextMonth() {
    if (this.currentStartDate.addMonthsImmutable(1) >= this.latestAgreementEndDate) return;
    this.updateCurrentStartDate(this.currentStartDate.addMonths(1));
    this.setActiveAgreement(this.agreementSubject.value);
  }

  previousMonth() {
    if (this.earliestAgreementStartDate >= this.currentStartDate) return;
    this.updateCurrentStartDate(this.currentStartDate.addMonths(-1));
    this.setActiveAgreement(this.agreementSubject.value);
  }

  onMouseEnterSelectedRow = (row: TimesheetGridRecordDto) => (this.selectedRow = this.selectedRow === row ? null : row);
  onMouseLeaveSelectedRow = (row: TimesheetGridRecordDto) => (this.selectedRow = this.selectedRow === row ? null : row);

  addRecord = () => this.openDialog(this.createEmptyTimesheetRecord(this.selectedRow.StartDate.resetTime()));
  editRecord = () => this.openDialog(this.selectedRow);

  copyRecord() {
    this.openDialog({
      ...this.selectedRow,
      Id: 0,
      StartDate: this.selectedRow.StartDate.addDays(1),
      EndDate: this.selectedRow.EndDate.addDays(1),
    });
  }

  deleteRecord(recordId: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: this.modalWidth,
        data: new ConfirmDialogData('DeleteRecordTitle', 'DeleteRecordMessage'),
      })
      .afterClosed()
      .pipe(
        first(),
        concatMap((isConfirmed) => (isConfirmed ? this.delete(recordId) : EMPTY)),
      )
      .subscribe();
  }

  approveTimesheet() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: new ConfirmDialogData('TimesheetApprovalDialogTitle', 'TimesheetApprovalDialogMessage'),
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap((isConfirmed: boolean) => (isConfirmed ? this.approve() : EMPTY)),
      )
      .subscribe();
  }

  isCreateButtonVisible = (date: Date) =>
    !this.isReadonly() &&
    this.isBetweenAgreementDates(date) &&
    this.isBeforeTerminationDate(date) &&
    this.activeAgreement.WorkerAgreementStatusId !== WorkerAgreementStatusEnum.Void &&
    this.canSaveOnlyOnPresentDay(date);

  isCopyButtonVisible = (tr: TimesheetGridRecordDto) =>
    !this.isReadonly() &&
    !this.canSaveOnlyPresentDay &&
    this.isBetweenAgreementDates(tr.Date) &&
    this.isBeforeTerminationDate(tr.Date) &&
    this.areTimesheetRecordDatesValid(tr) &&
    this.activeAgreement.WorkerAgreementStatusId !== WorkerAgreementStatusEnum.Void;

  isEditButtonVisible = (date: Date) =>
    !this.isReadonly() &&
    this.activeAgreement.WorkerAgreementStatusId !== WorkerAgreementStatusEnum.Void &&
    this.canSaveOnlyOnPresentDay(date);

  isDeleteButtonVisible = (date: Date) =>
    !this.isReadonly() &&
    this.activeAgreement.WorkerAgreementStatusId !== WorkerAgreementStatusEnum.Void &&
    this.canSaveOnlyOnPresentDay(date);

  isActiveAgreementsListEmpty = (): boolean => Array.isArray(this.agreementSubject.value) && !this.agreementSubject.value.length;

  canSaveOnlyOnPresentDay(date: Date) {
    if (this.canSaveOnlyPresentDay)
      return this.isCurrentDate(date);
    return true;
  }

  isCurrentDate = (date: Date): boolean => date.compareOnlyDates(new Date());
  isSaturday = (date: Date): boolean => date.getDay() === DayOfWeek.Saturday;
  isSunday = (date: Date): boolean => date.getDay() === DayOfWeek.Sunday;

  isReadonly = () => (!this.authService.hasAnyPermission([Permission.ManageMyExternalWorkersTimesheet, Permission.ManageAllExternalWorkersTimesheet]) &&
    this.profile.AuthServerUserId !== this.authService.authServerUserId) || 
    !this.canEditDates();

  canEditDates = () => this.activeAgreement?.TimesheetDateEditLevel == TimesheetDateEditLevelEnum.All || 
    (this.activeAgreement?.TimesheetDateEditLevel == TimesheetDateEditLevelEnum.OnlyOthers && 
      this.authService.hasAnyPermission([Permission.ManageMyExternalWorkersTimesheet, Permission.ManageAllExternalWorkersTimesheet]));

  canManageTimesheet = () => this.activeAgreement?.TimesheetDateEditLevel != TimesheetDateEditLevelEnum.None;

  private delete(recordId: number) {
    this.spinner.show();

    return this.timesheetService
      .deleteTimesheetRecord({
        TimesheetRecordId: recordId,
        WorkerId: this.profile.WorkerId,
        WorkerAgreementId: this.activeAgreement.Id,
      })
      .pipe(
        tap(() => this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyDeletedTimesheet)),
        finalize(() => {
          this.spinner.hide();
          this.fetchTimesheetRecords();
        }),
      );
  }

  private approve() {
    this.spinner.show();

    return this.timesheetService.approveAsWorker(this.profile.WorkerId, this.activeAgreement.Id, this.getCurrentYear(), this.getCurrentMonth()).pipe(
      first(),
      tap((_) => {
        this.isApprovedByWorkerSubject.next(true);
        this.snackbarService.openSuccessSnackBar(Messages.SuccessfullyApprovedTimesheet);
      }),
      finalize(() => this.spinner.hide()),
    );
  }

  private updateCurrentStartDate(date: Date) {
    this.currentStartDate = date;
    this.fetchTimesheetRecords();
  }

  private openDialog(timesheetRecord: TimesheetGridRecordDto): void {
    const dialogRef = this.dialog.open(TimesheetModalComponent, {
      width: this.modalWidth,
      maxHeight: '90vh',
      data: {
        WorkerId: this.profile.WorkerId,
        WorkerAgreement: this.activeAgreement,
        TimesheetRecord: timesheetRecord,
        WorkModes: this.workModes,
        TimesheetDateEditLevel: this.timesheetDateEditLevel,
        CanSaveOnlyPresentDay: this.canSaveOnlyPresentDay
      },
    });

    dialogRef.componentInstance.onStartDateChange.pipe(takeUntil(this.unsubscribe$)).subscribe((date: Date) => {
      if (this.currentStartDate.getMonth() !== date.getMonth() || this.currentStartDate.getFullYear() !== date.getFullYear())
        this.updateCurrentStartDate(date);
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((output: TimesheetModalOutput) => {
        if (output?.IsSuccess) {
          this.fetchTimesheetRecords();
        }
        if (
          output?.OpenAgain &&
          ((!!this.activeAgreement.EmploymentDateTo &&
            this.activeAgreement.EmploymentDateTo.resetTime() > output.RecentStartDate.addDays(1).resetTime()) ||
            !this.activeAgreement.EmploymentDateTo)
        ) {
          this.openDialog(this.createEmptyTimesheetRecord(output.RecentStartDate.addDays(1), output.RecentEndDate.addDays(1), output.WorkModeIds));
        }
      });
  }

  private fetchTimesheetRecords() {
    if (!this.activeAgreement) return;

    this.spinner.show()

    this.monthSelectedSubject.next({
      AgreementId: this.activeAgreement?.Id,
      Month: this.currentStartDate.getMonth() + 1,
      Year: this.currentStartDate.getFullYear(),
    });
  }

  private getTotalMonthTimespan = (records: TimesheetGridRecordDto[]) => records.map((t) => t.Timespan).reduce((acc, value) => acc + value, 0);
  private getTotalMonthTimespanDay = (records: TimesheetGridRecordDto[]) => records.map((t) => t.TimespanDay).reduce((acc, value) => acc + value, 0);
  private getTotalMonthTimespanNight = (records: TimesheetGridRecordDto[]) =>
    records.map((t) => t.TimespanNight).reduce((acc, value) => acc + value, 0);

  private getCurrentMonth = () => this.currentStartDate.getMonth() + 1;
  private getCurrentYear = () => this.currentStartDate.getFullYear();

  private areTimesheetRecordDatesValid = (tr: TimesheetGridRecordDto): boolean => !!tr.StartDate && !!tr.EndDate && tr.StartDate <= tr.EndDate;
  private isBeforeTerminationDate = (date: Date): boolean => !this.activeAgreement.TerminationEndDate || date <= this.activeAgreement.TerminationEndDate; 
  private isBetweenAgreementDates = (date: Date): boolean =>
    !!this.activeAgreement &&
    this.activeAgreement.EmploymentDateFrom.resetTime() <= date.resetTime() &&
    (!this.activeAgreement.EmploymentDateTo || date.resetTime() <= this.activeAgreement.EmploymentDateTo.resetTime());

  private getEmptyTimesheetRecordsForSpecificMonthResponse = (): Observable<GetTimesheetRecordsForSpecificMonthResponse> =>
    of({
      TimesheetRecords: [],
      IsApproved: false,
      IsApprovedByWorker: false,
      Absences: [],
      TimesheetDateEditLevel: this.timesheetDateEditLevel,
      CanSaveOnlyPresentDay: this.canSaveOnlyPresentDay,
      ConcludedHomeOfficeRemoteMonthAmount: this.concludedHomeOfficeRemoteMonthAmount,
      HomeOfficeOnDemandMonthAmount: this.homeOfficeOnDemandMonthAmount,
      HomeOfficeOnDemandYearAmount: this.homeOfficeOnDemandYearAmount,
      IsHomeOfficeOnDemandAvailable: this.isHomeOfficeOnDemandAvailable,
      IsConcludedHomeOfficeRemoteAvailable: this.isConcludedHomeOfficeRemoteAvailable
    });

  private getTimesheetRecords(agreementId: number, month: number, year: number) {
    const action$ = !!agreementId
      ? this.timesheetService.getTimesheetRecordsForSpecificMonth(agreementId, month, year)
      : this.getEmptyTimesheetRecordsForSpecificMonthResponse();

    return action$.pipe(
      tap(res => {
        this.isApprovedSubject.next(res.IsApproved);
        this.isApprovedByWorkerSubject.next(res.IsApprovedByWorker);
        this.timesheetDateEditLevel = res.TimesheetDateEditLevel;
        this.canSaveOnlyPresentDay = res.CanSaveOnlyPresentDay && !this.hasPermissionToManageAnyDay;
        this.concludedHomeOfficeRemoteMonthAmount = res.ConcludedHomeOfficeRemoteMonthAmount;
        this.homeOfficeOnDemandMonthAmount = res.HomeOfficeOnDemandMonthAmount;
        this.homeOfficeOnDemandYearAmount = res.HomeOfficeOnDemandYearAmount;
        this.isHomeOfficeOnDemandAvailable = res.IsHomeOfficeOnDemandAvailable,
        this.isConcludedHomeOfficeRemoteAvailable = res.IsConcludedHomeOfficeRemoteAvailable
      }),
      map(res => res.TimesheetRecords),
      tap(timesheetRecords => {
        this.totalTimespanSubject.next(this.getTotalMonthTimespan(timesheetRecords));
        this.totalTimespanDaySubject.next(this.getTotalMonthTimespanDay(timesheetRecords));
        this.totalTimespanNightSubject.next(this.getTotalMonthTimespanNight(timesheetRecords));
      }),
    );
  }

  private createEmptyTimesheetRecord = (
    startDate: Date = new Date(),
    endDate: Date = startDate,
    workModeIds: number[] = null
  ): TimesheetGridRecordDto => ({
    Id: 0,
    TimesheetId: 0,
    Date: new Date(startDate.getTime()),
    StartDate: new Date(startDate.getTime()),
    EndDate: new Date(endDate.getTime()),
    Timespan: startDate.calculateTimespanInHours(endDate),
    TimespanDay: 0,
    TimespanNight: 0,
    Absence: null,
    WorkModes: workModeIds === null ? null : workModeIds.map((x) => ({ Id: x })),
  });

  fetchWorkModes() {
    this.timesheetService
      .getWorkModes(this.activeAgreement?.Id)
      .pipe(first())
      .subscribe((workModes: WorkModeDto[]) => {
        if (workModes) {
          this.workModes = workModes;
        }
      });
  }
}
