import { AddAuthorizationCodeRequest } from './../contracts/requests/add-authorization-code.request';
import { SignService } from 'src/app/sign/sign.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, first } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { Permission } from '../common/enums/permissions';
import { AuthService } from '../core/authentication/auth.service';
import { HomeService } from '../data/home.service';
import { ApiResult } from '../models/ApiResult';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AnotherUserLoginComponent } from '../shared/messages/another-user-login/another-user-login.component';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  error: boolean;

  readonly homeUrl = '/home';
  readonly workerAgreementsUrl = '/workerAgreements';
  readonly qrCodesUrl = '/qrCode';

  constructor(
    private authService: AuthService,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private signService: SignService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    // check for error
    if (this.route?.snapshot?.fragment?.indexOf('error') >= 0) {
      this.error = true;
      this.router.navigate(['/']);
      return;
    }

    //TODO - do ogarnięcia przy Simply Sign
    if (false && this.route?.snapshot?.queryParamMap?.get('code')) {
      let code = this.route?.snapshot?.queryParamMap?.get('code')
      this.signService.addAuthorizationCode(<AddAuthorizationCodeRequest>{ Code: code }).pipe(
        first(),
      )
        .subscribe();

      return;
    }
    else if (!this.authService.isSignedIn()) {
      this.spinner.show();

      await firstValueFrom(this.authService.checkAuth().pipe(catchError(err => this.spinner.hide())));
      await this.authService.completeAuthentication();

      this.authService.completeAuthentication();
    }

    if (!this.spinner.spinnerObservable.value || !this.spinner.spinnerObservable.value.show) {
      this.spinner.show();
    }

    const returnUrl = sessionStorage.getItem('returnUrl') ?? null;
    const queryParams = sessionStorage.getItem('queryParams') ?? null;

    if (!!queryParams) {
      this.router.navigateByUrl(returnUrl.concat('?').concat(queryParams)).then((_) => this.spinner.hide());
      return;
    }

    if (!!returnUrl) {
      this.router.navigate([returnUrl]).then((_) => this.spinner.hide());
      return;
    }

    this.homeService
      .redirectTo()
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe(async (res: ApiResult<string>) => {
        let redirectTo: string = res.Value;
        if (
          redirectTo === this.homeUrl && this.authService.hasPermission(Permission.QrCode)
        ) {
          redirectTo = this.qrCodesUrl;
        }
        if (
          redirectTo === this.homeUrl &&
          (this.authService.hasPermission(Permission.ManageMyExternalWorkersList) ||
            this.authService.hasPermission(Permission.ViewMyExternalWorker) ||
            this.authService.hasPermission(Permission.ManageAllExternalWorkersList))
        ) {
          redirectTo = this.workerAgreementsUrl;
        }

        await this.router.navigate([res.IsSuccess ? redirectTo : this.homeUrl]);

        if (this.route?.snapshot?.queryParamMap?.get('AnotherUserLogin')) {
          await this.showAnotherUserLoginDialog();
        }
      });
  }

  async signin() {
    await this.authService.login();
  }

  private async showAnotherUserLoginDialog() {
    var logoutRequest = await firstValueFrom(this.dialog
      .open(AnotherUserLoginComponent, { data: { currentLogin: this.authService.email } })
      .afterClosed());

    if (logoutRequest) {
      await this.authService.signout();
    }
  }
}
