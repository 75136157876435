import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, interval, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { buildFilterArray } from 'src/app/common/utils/build-filter-array';
import { Filter } from 'src/app/models/common/filter';
import { AllWorkerAgreementGridDto } from 'src/app/models/dtos/AllWorkerAgreementGridDto';
import { EmploymentType } from 'src/app/models/enums/employment-type-enum';
import { WorkerAgreementStatusEnum } from 'src/app/models/enums/worker-agreement-status-enum';
import { WorkerFormStatusEnum } from 'src/app/models/enums/WorkerFormStatusEnum';
import { WorkerStatusEnum } from 'src/app/models/enums/WorkerStatusEnum';
import { DatePipe } from '@angular/common';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { LegalizationStatusEnum } from 'src/app/models/enums/legalization-status-enum';
import { WorkersListDataSource } from './workers-list.datasource';
import { WorkersListFiltersComponent } from '../workers-list-filters/workers-list-filters.component';
import { WorkerService } from 'src/app/data/worker.service';
import { UserService } from 'src/app/data/user.service';
import { FilterTypeEnum } from 'src/app/models/enums/filter-type-enum';
import { FilterPresetDto } from 'src/app/models/dtos/filter-preset-dto';
import Comparator from 'src/app/common/comparators/comparator';
import { FilterPresetNameFormDialogComponent } from 'src/app/shared/components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { animate, style, transition, trigger } from '@angular/animations';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';

const legalizationStatusTranslaionPrefix = 'Legalization.Status'

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class WorkersListComponent implements OnInit, OnDestroy {
  sticky = false;
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('tableContainer', { static: true }) tableContainerRef: ElementRef;

  height = 0;
  visibleColumns: any[];
  employmentType = EmploymentType;

  page = 1;
  pageSize = 30;
  offset$: Observable<number>;

  WorkerAgreementStatusEnum = WorkerAgreementStatusEnum;
  WorkerStatusEnum = WorkerStatusEnum;

  filtersFormGroup: UntypedFormGroup;
  displayedColumns: string[] = [
    'FirstName',
    'LastName',
    'Citizenship',
    'Pesel',
    'Email',
    'PhoneNumber',
    'WorkerStatus',
    'Actions'
  ];

  private _areFiltersExpanded: boolean;
  public get areFiltersExpanded(): boolean {
    return this._areFiltersExpanded;
  }
  public set areFiltersExpanded(value: boolean) {
    if (this._areFiltersExpanded !== value) {
      this._areFiltersExpanded = value;
      this.checkViewportSize();
    }
  }

  dataSource: WorkersListDataSource;
  hasLegalization: boolean;

  isRefreshing$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultFilterPresetName = 'default';
  public filterPresets: FilterPresetDto[];
  private _selectedFilterPresetId: number;
  public set selectedFilterPresetId(value: number) {
    if (this._selectedFilterPresetId !== value) {
      this._selectedFilterPresetId = value;

      if (value) {
        this.applyFilterPreset(this.filterPresets.find(fp => fp.Id === value));
      }
    }
  }
  public get selectedFilterPresetId(): number {
    return this._selectedFilterPresetId;
  }
  public get selectedFilterPreset(): FilterPresetDto {
    return this.filterPresets.find(fp => fp.Id === this.selectedFilterPresetId);
  }
  public get showFilterPresetSelector(): boolean {
    return this.filterPresets && (this.filterPresets.length > 1 || (this.filterPresets.length === 1 && this.filterPresets[0].Name !== this.defaultFilterPresetName));
  }
  public get isSavedFilterPreset(): boolean {
    return this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName;
  }
  public get scrollItemPosition(): number {
    return this.viewport.getRenderedRange().end;
  }

  public async setSelectedFilterPresetId(value: number): Promise<void> {
    this.selectedFilterPresetId = value;
    await this.filterData(buildFilterArray(this.filtersFormGroup, WorkersListFiltersComponent.operatorsMap));
    await firstValueFrom(this.userService.selectFilterPreset(value));
  }

  private readonly defaultPage: number = 1;
  private readonly defaultPageSize: number = 1000;
  public readonly defaultSortColumn: string = 'LastName';
  public readonly defaultSortDirection: string = 'asc';
  private readonly unsubscribe$ = new Subject<void>();

  private filters: Filter[] = [];

  public readonly workerAgreementStatusEnum = WorkerAgreementStatusEnum;
  public readonly workerStatusEnum = WorkerStatusEnum;
  public readonly workerFormStatusEnum = WorkerFormStatusEnum;
  public readonly legalizationStatusEnum = LegalizationStatusEnum;

  constructor(
    private workerService: WorkerService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.height = this.tableContainerRef.nativeElement.clientHeight;
    this.init();
    this.buildFormGroup();
    await this.restoreSavedFiltersPreset();
    this.initFiltersFormChangeObserver();
    this.filters = buildFilterArray(this.filtersFormGroup, WorkersListFiltersComponent.operatorsMap);
    this.fetchData();
    this.initDatRefresher();
    this.initLangChanegeObserver();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  filterData(filters: Filter[]) {
    this.filters = filters;
    this.resetData();
    this.fetchData();
  }

  onSortChange() {
    this.resetData();
    this.fetchData();
  }

  toggleFiltersPanel = () => (this.areFiltersExpanded = !this.areFiltersExpanded);

  async resetFilters(): Promise<void> {
    this.filtersFormGroup.reset(undefined, { emitEvent: false });
    this.filterData([]);

    if (this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName) {
      await this.deselectFilterPreset();
    }

    this.selectedFilterPresetId = undefined;
    this.upsertFilterPreset();
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      employerId: [null],
      employer: [null, [autocompleteValidator]],
      employerObjectId: [null],
      employerObject: [null, [autocompleteValidator]],
      city: [null],
      workerStatusId: [null],
      workerFormStatusId: [null],
      pesel: [null],
      document: [null],
      documentFilter: [null],
      isForeginer: [null],
      isStudent: [null],
      email: [null],
      postCode: [null],
      citizenshipId: [null],
      citizenship: [null, [autocompleteValidator]],
      phoneNumber: [null],
      mpk: [{ value: null, disabled: true }],
    });
  }

  private async restoreSavedFiltersPreset() {
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.WorkersList));

    if (this.filterPresets && this.filterPresets.length) {
      const selectedPreset = this.filterPresets.find(fp => fp.IsSelected);

      this.selectedFilterPresetId = selectedPreset?.Id;
    }
  }

  private initFiltersFormChangeObserver() {
    const comparator = new Comparator<any>();

    this.filtersFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe(async () => {
        if (this.filtersFormGroup.invalid) {
          return;
        }

        const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

        const selectedFilterPresetId = this.filterPresets.find(fp => comparator.equals(JSON.parse(fp.Object), filters))?.Id;

        if (!selectedFilterPresetId) {
          this.selectedFilterPresetId = this.filterPresets.find(fp => fp.Name === this.defaultFilterPresetName)?.Id;

          await this.upsertFilterPreset();
        } else {
          this.selectedFilterPresetId = selectedFilterPresetId;
        }
      });
  }

  private applyFilterPreset(filterPreset) {
    const filters = JSON.parse(filterPreset?.Object);

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.reset(undefined, { emitEvent: false });
      this.filtersFormGroup.patchValue(filters, { emitEvent: false });
      this.areFiltersExpanded = this.areFiltersExpanded || this.selectedFilterPreset.Name === this.defaultFilterPresetName;
    }
  }

  private initDatRefresher() {
    this.dataSource.isLoading$
      .pipe(filter(isLoading => !isLoading))
      .pipe(debounceTime(15000))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async _ => {
        var { page, pageSize } = this.getActualPage();

        this.fetchData(page, pageSize);
      });
  }

  private getActualPage() {
    const start = this.viewport.getRenderedRange().start + 1;
    const end = this.viewport.getRenderedRange().end;

    const pageStart = Math.ceil(start / this.pageSize);
    const pageEnd = Math.ceil(end / this.pageSize);

    const pageMultiplier = pageEnd - pageStart + 1;

    let pageSize = this.pageSize * pageMultiplier;

    const page = Math.ceil(pageStart / pageMultiplier);

    if (pageSize * page < end) {
      pageSize += Math.ceil((end - pageSize * page) / this.pageSize) * this.pageSize;
    }

    return { page, pageSize };
  }

  private initLangChanegeObserver() {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.fetchData());
  }

  async nextBatch(event) {
    if (!this.sticky) {
      this.sticky = true;
    }
    const buffer = 20;
    const range = this.viewport.getRenderedRange();
    const end = range.end;
    if (true) {
      if (!this.dataSource.isLoading && end + buffer > this.page * this.pageSize) {
        this.fetchData(++this.page);
      }
    }
  }

  private init() {
    if (this.dataSource) {
      return;
    }

    this.dataSource = new WorkersListDataSource(
      this.workerService,
      {
        viewport: this.viewport
      });

    this.offset$ = this.viewport.renderedRangeStream.pipe(
      map(() => -this.viewport.getOffsetToRenderedContentStart())
    );
  }

  trackBy(index: number, item: AllWorkerAgreementGridDto) {
    return `${item.AgreementId}${item.AgreementStatusId}${item.WorkerStatusId}${item.WorkerFormStatusId}`;
  }

  private fetchData(page?: number, pageSize?: number) {
    this.dataSource.fetchWorkers(
      page ?? this.page,
      pageSize ?? this.pageSize ?? this.defaultPageSize,
      this.sort?.active ?? this.defaultSortColumn,
      this.sort?.direction ?? this.defaultSortDirection,
      this.filters
    );
  }

  private resetData() {
    this.page = 1;
    this.dataSource.reset();
  }

  private checkViewportSize() {
    if (this.dataSource) {
      this.changeDetectorRef.detectChanges();
      this.viewport.checkViewportSize();
    }
  }

  private async upsertFilterPreset(): Promise<void> {
    const selectedPreset = this.filterPresets.find(fp => (this.selectedFilterPresetId && this.selectedFilterPresetId === fp.Id) || fp.Name === this.defaultFilterPresetName);

    const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

    if (selectedPreset) {
      if (filters) {
        await firstValueFrom(this.userService.updateFilterPreset(selectedPreset.Id, {
          Name: selectedPreset.Name,
          FilterPresetObject: filters
        }));
      } else {
        await firstValueFrom(this.userService.deleteFilterPreset(selectedPreset.Id));
      }
    } else if (filters) {
      await firstValueFrom(this.userService.addFilterPreset({
        Name: this.defaultFilterPresetName,
        FilterTypeId: FilterTypeEnum.WorkersList,
        FilterPresetObject: filters
      }));
    } else {
      return;
    }
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.WorkersList));
    this.selectedFilterPresetId = this.filterPresets?.find(fp => fp.IsSelected)?.Id;
  }

  private nonNullValues(obj): { [key: string]: unknown } {
    const properties = Object.entries(obj).filter(([key, value]) => value !== null && value && (!(value instanceof Array) || value.length));
    if (properties.length) {
      return Object.fromEntries(properties);
    }
  }

  public async saveFilterPresetButtonClick(): Promise<void> {
    if (this.isSavedFilterPreset) {

      const existingDefaultPreset = this.filterPresets.find(fp => fp.Name == this.defaultFilterPresetName && this.selectedFilterPresetId !== fp.Id);

      if (existingDefaultPreset) {
        await firstValueFrom(this.userService.deleteFilterPreset(existingDefaultPreset.Id));
      }

      this.selectedFilterPreset.Name = this.defaultFilterPresetName;
      await this.upsertFilterPreset();
    } else {
      var res = await firstValueFrom(this.dialog
        .open(FilterPresetNameFormDialogComponent, { panelClass: 'form-dialog' })
        .afterClosed());

      if (res) {
        if (this.selectedFilterPreset.Name === this.defaultFilterPresetName) {
          this.selectedFilterPreset.Name = res;
        }
        await this.upsertFilterPreset();
      }
    }
  }

  private async deselectFilterPreset(): Promise<void> {
    await firstValueFrom(this.userService.deselectFilterPreset(this.selectedFilterPresetId));
    this.selectedFilterPreset.IsSelected = false;
  }
}
