<div>
  <div fxLayout="row">
    <h2>{{ 'CreateFamilyMember' | translate }}</h2>
  </div>

  <form [formGroup]="familyMemberForm">
    <!-- IsForeigner -->
    <div class="row">
      <div class="col">
        <mat-checkbox formControlName="isForeigner">{{ 'Foreigner' | translate }}</mat-checkbox>
      </div>
    </div>

    <!-- FirstName, LastName -->
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'FirstName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'FirstName' | translate }}" formControlName="firstName" maxlength="250" required />
        </mat-form-field>
      </div>

      <div class="col-md-8">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'LastName' | translate }}</mat-label>
          <input matInput placeholder="{{ 'LastName' | translate }}" formControlName="lastName" maxlength="250" required />
        </mat-form-field>
      </div>
    </div>

    <!-- Pesel, DateOfBirth, KinshipDegree -->
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'Pesel' | translate }}</mat-label>
          <input matInput placeholder="{{ 'Pesel' | translate }}" formControlName="pesel" maxlength="11" />
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DateOfBirth' | translate }}</mat-label>
          <input matInput [matDatepicker]="birthDatePicker" formControlName="dateOfBirth" autocomplete="date" />
          <mat-datepicker-toggle [for]="birthDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'KinshipDegree' | translate }}</mat-label>
          <mat-select formControlName="kinshipDegreeId">
            <mat-option
              #matOption
              *ngFor="let kinshipDegree of kinshipDegrees$ | async"
              [value]="kinshipDegree.Id"
              [matTooltip]="kinshipDegree.Name"
              matTooltipClass="mat-tooltip--medium"
              >{{ kinshipDegree.Name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- IdentityDocumentTypeId, DocumentSerial, DocumentNumber -->

    <div class="row" *ngIf="isForeginerControl.value">
      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'IdentityDocumentType' | translate }}</mat-label>
          <mat-select formControlName="identityDocumentTypeId">
            <mat-option [value]="null"></mat-option>
            <mat-option #matOption *ngFor="let identityDocumentType of identityDocumentTypes$ | async" [value]="identityDocumentType.Id">{{
              identityDocumentType.Name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'DocumentSerial' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'DocumentSerial' | translate }}"
            formControlName="documentSerial"
            [maxlength]="serialLength"
            [(ngModel)]="familyMemberForm.value.documentSerial"
            toUppercaseInput
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'DocumentNumber' | translate }}</mat-label>
          <input matInput placeholder="{{ 'DocumentNumber' | translate }}" formControlName="documentNumber" [maxlength]="numberLength" />
        </mat-form-field>
      </div>
    </div>

    <!-- DisabilityLevel -->

    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" fxFlex="100">
          <mat-label>{{ 'DisabilityLevel' | translate }}</mat-label>
          <mat-select formControlName="disabilityLevelId">
            <mat-option [value]="null"></mat-option>
            <mat-option #matOption *ngFor="let disabilityLevel of disabilityLevels$ | async" [value]="disabilityLevel.Id">{{
              disabilityLevel.Name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <hr />

    <app-family-member-address-form [familyMemberAddressForm]="familyMemberAddressForm"></app-family-member-address-form>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-raised-button color="accent" (click)="close()">{{ 'Close' | translate }}</button>
      <button mat-raised-button color="primary" (click)="submit()">{{ 'Save' | translate }}</button>
    </div>
  </form>
</div>
