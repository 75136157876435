import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WorkerService } from 'src/app/data/worker.service';

@Injectable({
  providedIn: 'root',
})
export class MandateAgreementWorkedDaysGuard {
  constructor(private router: Router, private workerService: WorkerService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const workerId = +new RegExp('\\d+').exec(state.url)[0];

    return this.workerService.getWorkerProfileAgreementsPageConfig(workerId)
      .pipe(
        map((res) => res.HasAnyMandateAgreementWorkedDay),
        tap((canActivate) => {
          if (!canActivate) {
            this.router.navigate(['profile', workerId, 'CertificateNotAvailable']);
          }
        }),
      );
  }
}
