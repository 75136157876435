<div>
  <div class="content-card">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <div class="example-container">
        <div class="table-wrapper">
          <mat-table
            #table
            matSort
            matSortActive="SharingDate"
            matSortDirection="desc"
            matSortDisableClear
            [dataSource]="dataSource.mandateAgreementWorkedDays$ | async"
          >
            <ng-container matColumnDef="CompanyName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Company' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmployerName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmployerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Employer' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmployerName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmploymentDateFrom">
              <mat-header-cell *matHeaderCellDef mat-sort-header="EmploymentDateFrom"> {{ 'EmploymentDateFrom' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmploymentDateFrom | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="EmploymentDateTo">
              <mat-header-cell *matHeaderCellDef mat-sort-header="EmploymentDateTo"> {{ 'EmploymentDateTo' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.EmploymentDateTo | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="SharingDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'SharingDate' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.SharingDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="ReadingDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ReadingDate' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.ReadingDate | date : 'yyyy-MM-dd' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions" stickyEnd>
              <mat-header-cell *matHeaderCellDef>{{ 'WAD-Actions' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div fxLayout="row">
                  <button
                    mat-icon-button
                    (click)="onShowClick(element)"
                    matTooltip="{{ 'WL-ShowAgreementButton' | translate }}"
                    matTooltipPosition="above"
                    [visibleWithPermissionsOnWorkerProfile]="['AgreementPreview']"
                    [authServerUserId]="authServerUserId"
                  >
                    <mat-icon class="material-icons-outlined">find_in_page</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    (click)="onDownloadClick(element)"
                    matTooltip="{{ 'WL-DownloadAgreementButton' | translate }}"
                    matTooltipPosition="above"
                    [visibleWithPermissionsOnWorkerProfile]="['AgreementPreview']"
                    [authServerUserId]="authServerUserId"
                  >
                    <mat-icon class="material-icons-outlined">file_download</mat-icon>
                  </button>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
        <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
