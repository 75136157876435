import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Permission } from 'src/app/common/enums/permissions';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';
import { WorkerService } from 'src/app/data/worker.service';

@Injectable()
export class PermissionGuard {
  constructor(
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private workerService: WorkerService,
  ) { }
  isLoggedUserProfile: boolean;

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (!this.authService.isAuthenticated()) {
      return false;
    }

    const workerId = +route.paramMap.get('workerId');

    const permissions: Permission[] = route.data['permissions'];

    const loggedWorkerId = await firstValueFrom(this.workerService.getWorkerIdByAuthServerUserId(this.authService.authServerUserId));

    const isLoggedUserProfile = loggedWorkerId === workerId;

    if (this.authService.isAuthenticated() && (permissions.some((p) => this.authService.hasPermission(p)) || isLoggedUserProfile)) {
      return true;
    }

    this.snackbarService.openErrorSnackBar(ErrorCode.NoPermission);

    return false;
  }
}
