import { QrCodeService } from './../data/qr-code.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SignalRService } from '../data/signalr.service';
import { Subscription, firstValueFrom, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QrCodeDto } from '../models/dtos/qr-code-dto';
import { AuthService } from '../core/authentication/auth.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit, OnDestroy {
  @Input() size: number = 512;
  @Input() level: 'L' | 'M' | 'Q' | 'H' = 'L';

  @Input() startDate: Date;
  @Input() endDate: Date;

  private hubName: string = 'QrCodeHub';
  private oneMinuteMiliseconds: number = 60000;
  private tenMinutesMiliseconds: number = 600000;
  public loadingProgress: number = 0;
  public timeLeft: number = 0;

  public qrCode: string | undefined;
  public qrCodeUrl: string | undefined;
  
  private baseUrl: string;
  private workerId: number | undefined;
  private connectionCheckInterval: ReturnType<typeof setTimeout>;;
  private refreshInterval: ReturnType<typeof setTimeout>;;
  private countdownInterval: Subscription | undefined;

  constructor(
    private signalRService: SignalRService,
    private qrCodeService: QrCodeService,
    private authService: AuthService,
  ) {
    this.baseUrl  = environment.baseUrl + 'logwork/';
  }

  async ngOnInit(): Promise<void> {
    this.workerId = await firstValueFrom(this.authService.getWorkerIdByAuthServerUserId());

    if (this.workerId) {
      this.startSignalRConnection();

      this.connectionCheckInterval = setInterval(() => this.checkSignalRConnection(), this.oneMinuteMiliseconds);
      this.refreshInterval = setInterval(() => this.refresh(), this.tenMinutesMiliseconds);
    }
  }

  isProduction(): boolean {
    return environment.production;
  }

  ngOnDestroy(): void {
    if (this.connectionCheckInterval) {
      clearInterval(this.connectionCheckInterval);
    }

    if (this.countdownInterval) {
      this.countdownInterval.unsubscribe();
    }

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  private startSignalRConnection(): void {
    this.signalRService.startConnection(this.hubName, this.workerId.toString()).subscribe(() => {
      this.signalRService.receiveQrCode().subscribe((qr: QrCodeDto) => {
        this.updateQrCode(qr);
      });
      this.getActiveCode();
    });
  }
  
  private getActiveCode(): void {
    this.qrCodeService.getQrCodes(this.workerId).subscribe({
      next: (qr: QrCodeDto) => {
        this.updateQrCode(qr)

      },
      error: (err) => console.error('Failed to retrieve QR codes', err)
    });
  }

  private resetProgressBar() {
    this.loadingProgress = 0;
    this.timeLeft = 0;
  }
  
  private updateQrCode(qrCodeDto: QrCodeDto): void {
    if (!!qrCodeDto) {
      this.resetProgressBar();
      this.qrCode = qrCodeDto.Code;
      this.qrCodeUrl = `${this.baseUrl}${qrCodeDto.Code}`;
      this.startDate = qrCodeDto.ActivityStartDate;
      this.endDate = qrCodeDto.ActivityEndDate;
      this.startCountdown();
    }
  }

  refresh(): void {
    window.location.reload();
  }

  private checkSignalRConnection(): void {
    if (!this.signalRService.isConnected(this.hubName)) {
      this.startSignalRConnection();
    }
  }

  private startCountdown(): void {
    this.countdownInterval?.unsubscribe();
  
    const startTime = this.startDate.getTime();
    const endTime = this.endDate.getTime();
    const totalTime = (endTime - startTime) / 1000;
  
    this.timeLeft = Math.floor((endTime - Date.now()) / 1000);
    this.updateProgress(totalTime);
  
    this.countdownInterval = interval(1000).subscribe(() => {
      this.timeLeft--;
      this.updateProgress(totalTime);
  
      if (this.timeLeft <= 0) {
        this.countdownInterval?.unsubscribe();
        this.loadingProgress = 100; 
      }
    });
  }
  
  private updateProgress(totalTime: number): void {
    this.loadingProgress = ((totalTime - this.timeLeft) / totalTime) * 100;
  }
}