import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, finalize, first, map, takeUntil, tap } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { getBirthDateFromPesel } from 'src/app/common/utils/get-birth-date-from-pesel';
import { setFormControlValidators } from 'src/app/common/utils/set-form-control-validators';
import { CreateOrUpdateWorkerFamilyMemberRequest } from 'src/app/contracts/requests/create-or-update-worker-family-member-request';
import { DictionaryService } from 'src/app/data/dictionary.service';
import { WorkerService } from 'src/app/data/worker.service';
import { IdentityDocumentConstants } from 'src/app/models/constants/identity-document-validators-map';
import { Country } from 'src/app/models/Country';
import { DictionaryItem } from 'src/app/models/DictionaryItem';
import { WorkerFamilyMemberDto } from 'src/app/models/dtos/worker-family-member-dto';
import { WorkerFamilyMemberGridDto } from 'src/app/models/dtos/worker-family-member-grid-dto';
import { IdentityDocumentTypeEnum } from 'src/app/models/enums/IdentityDocumentTypeEnum';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { FirstNameValidator } from 'src/app/shared/validators/first-name.validator';
import { LastNameValidator } from 'src/app/shared/validators/last-name.validator';
import { maxDateValidator } from 'src/app/shared/validators/max-date.validator';
import { PostOfficeValidator } from 'src/app/shared/validators/post-office.validator';
import { peselValidator } from 'src/app/workers/worker-form/pesel.validator';

@Component({
  selector: 'app-family-member-form-modal',
  templateUrl: './family-member-form-modal.component.html',
  styleUrls: ['./family-member-form-modal.component.scss'],
})
export class FamilyMemberFormModalComponent implements OnInit, OnDestroy {
  peselValidators = [Validators.minLength(11), Validators.maxLength(11), Validators.pattern('^[0-9]{11}$'), peselValidator];
  dateOfBirthValidators = [maxDateValidator(new Date())];

  familyMemberForm: UntypedFormGroup = this.buildFamilyMemberFormGroup();
  familyMemberAddressForm: UntypedFormGroup = this.buildFamilyMemberAddressFormGroup();

  identityDocumentTypes$: Observable<DictionaryItem[]> = this.dictionaryService.getIdentityDocumentTypes();
  kinshipDegrees$: Observable<DictionaryItem[]> = this.dictionaryService.getKinshipDegrees();
  disabilityLevels$: Observable<DictionaryItem[]> = this.dictionaryService.getDisabilityLevels();

  serialLength: number;
  numberLength: number;

  isRequestPending = false;

  documentValidatorsMap = IdentityDocumentConstants.getDocumentValidatorsMap();

  private readonly unsubscribe$ = new Subject<void>();

  get documentSerialControl() {
    return this.familyMemberForm.get('documentSerial') as UntypedFormControl;
  }
  get documentNumberControl() {
    return this.familyMemberForm.get('documentNumber') as UntypedFormControl;
  }
  get peselControl() {
    return this.familyMemberForm.get('pesel') as UntypedFormControl;
  }
  get identityDocumentTypeControl() {
    return this.familyMemberForm.get('identityDocumentTypeId') as UntypedFormControl;
  }
  get isForeginerControl() {
    return this.familyMemberForm.get('isForeigner') as UntypedFormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { workerId: number; member: WorkerFamilyMemberGridDto },
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private dictionaryService: DictionaryService,
    private workerService: WorkerService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FamilyMemberFormModalComponent>,
  ) {
    this.onIsForeignerChange();
    this.onIdentityDocumentTypeIdChange();
    this.onPeselChange();
    this.onPassportDocumentSerialChange();
  }

  ngOnInit(): void {
    if (!this.data.member) return;

    setTimeout(() => this.fetchWorkerFamilyMember());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit() {
    if (this.isRequestPending) {
      return;
    }

    this.familyMemberAddressForm.markAllAsTouched();
    const isPolishAddress = this.familyMemberAddressForm.get('country').value === Country.Poland;
    const isStayingInTheSameHousehold = this.familyMemberAddressForm.get('isStayingInTheSameHousehold').value;

    if (
      !isStayingInTheSameHousehold &&
      ((isPolishAddress && this.familyMemberAddressForm.get('polishAddress').invalid) ||
        (!isPolishAddress && this.familyMemberAddressForm.get('abroadAddress').invalid))
    )
      return;

    if (this.familyMemberForm.invalid) return;

    this.spinner.show();
    this.isRequestPending = true;

    const request = this.createRequest();

    const action$ = !this.data.member
      ? this.workerService.createWorkerFamilyMember(this.data.workerId, request)
      : this.workerService.updateWorkerFamilyMember(this.data.workerId, this.data.member.Id, request);
    const successMessage = !this.data.member ? Messages.SuccesfullyCreatedWorkerFamilyMember : Messages.SuccesfullyUpdatedWorkerFamilyMember;

    action$
      .pipe(
        first(),
        finalize(() => {
          this.dialogRef.close(true);
          this.spinner.hide();
        }),
        catchError((err) => {
          const message = err.error === ErrorCode.WorkerMustHaveRegisteredAddress ? ErrorCode.WorkerMustHaveRegisteredAddress : ErrorCode.SomethingWentWrong;
          this.snackbar.openErrorSnackBar(message);
          this.dialogRef.close(true);
          return EMPTY;
        })
      )
      .subscribe((_) => {
        this.snackbar.openSuccessSnackBar(successMessage);
      });
  }

  close() {
    this.dialogRef.close();
  }

  private fetchWorkerFamilyMember() {
    this.spinner.show();

    this.workerService
      .getWorkerFamilyMemberById(this.data.workerId, this.data.member.Id)
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((memberDetails) => {
        this.patchFormGroupData(memberDetails);
      });
  }

  private buildFamilyMemberFormGroup() {
    return this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.maxLength(250), FirstNameValidator()]],
      lastName: [null, [Validators.required, Validators.maxLength(250), LastNameValidator()]],
      pesel: [null, [Validators.required].concat(this.peselValidators)],
      dateOfBirth: [null, [Validators.required].concat(this.dateOfBirthValidators)],
      kinshipDegreeId: [null, Validators.required],
      identityDocumentTypeId: [null],
      documentSerial: [null, [Validators.pattern('^[a-zA-Z0-9]+$')]],
      documentNumber: [null, [Validators.pattern('^[a-zA-Z0-9]+$')]],
      disabilityLevelId: [null],
      isForeigner: [false],
      isStayingInTheSameHousehold: [true],
    });
  }

  private buildFamilyMemberAddressFormGroup() {
    return this.formBuilder.group({
      isStayingInTheSameHousehold: [true],
      country: [Country.Poland, [Validators.required]],
      polishAddress: this.formBuilder.group({
        district: [null, [Validators.required]],
        poviat: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
        commune: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
        city: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
        street: [{ value: null, disabled: true }, [Validators.required, autocompleteValidator]],
        houseNumber: [null, [Validators.required, Validators.maxLength(10)]],
        apartmentNumber: [null, [Validators.maxLength(10)]],
        postcode: [null, [Validators.required, Validators.maxLength(6), Validators.pattern('^[0-9]{2}-[0-9]{3}$')]],
        postOffice: [null, [Validators.required, PostOfficeValidator()]],
      }),
      abroadAddress: this.formBuilder.group({
        districtName: [null, [Validators.required]],
        poviatName: [null, [Validators.required]],
        communeName: [null, [Validators.required]],
        cityName: [null, [Validators.required]],
        streetName: [null, [Validators.required]],
        houseNumber: [null, [Validators.required, Validators.maxLength(10)]],
        apartmentNumber: [null, [Validators.maxLength(10)]],
        postcode: [null, [Validators.required, Validators.maxLength(6), Validators.pattern('^[0-9]{2}-[0-9]{3}$')]],
        postOffice: [null, [Validators.required, PostOfficeValidator()]],
      }),
    });
  }

  private patchFormGroupData(member: WorkerFamilyMemberDto) {
    this.familyMemberForm.patchValue(
      {
        firstName: member.FirstName,
        lastName: member.LastName,
        pesel: member.Pesel,
        dateOfBirth: member.DateOfBirth,
        kinshipDegreeId: member.KinshipDegreeId,
        identityDocumentTypeId: member.IdentityDocumentTypeId,
        documentNumber: member.DocumentNumber,
        disabilityLevelId: member.DisabilityLevelId,
        isForeigner: member.IsForeigner,
      },
      { emitEvent: false },
    );

    this.familyMemberForm.patchValue({
      documentSerial: member.DocumentSerial,
      identityDocumentTypeId: member.IdentityDocumentTypeId
    });

    if (!!member.Pesel && !!member.DateOfBirth) this.familyMemberForm.get('dateOfBirth').disable();
    if (member.IsForeigner) this.updateInformationAccordingToIsForeignerField(member.IsForeigner);

    this.patchAddresFormGroupData(member);
  }

  private patchAddresFormGroupData(member: WorkerFamilyMemberDto) {
    this.familyMemberAddressForm.patchValue({
      isStayingInTheSameHousehold: member.IsStayingInTheSameHousehold,
      country: member.Address.CountryId,
    });

    if (member.Address.CountryId === Country.Poland) {
      this.familyMemberAddressForm.get('polishAddress').patchValue({
        district: member.Address.DistrictId,
        poviat: { Id: member.Address.PoviatId, Name: member.Address.PoviatName },
        commune: { Id: member.Address.CommuneId, Name: member.Address.CommuneName },
        city: { Id: member.Address.CityId, Name: member.Address.CityName },
        street: { Id: member.Address.StreetId, Name: member.Address.StreetName },
        houseNumber: member.Address.HouseNumber,
        apartmentNumber: member.Address.ApartmentNumber,
        postcode: member.Address.Postcode,
        postOffice: member.Address.PostOffice,
      });
    } else {
      this.familyMemberAddressForm.get('abroadAddress').patchValue({
        districtName: member.Address.DistrictName,
        poviatName: member.Address.PoviatName,
        communeName: member.Address.CommuneName,
        cityName: member.Address.CityName,
        streetName: member.Address.StreetName,
        houseNumber: member.Address.HouseNumber,
        apartmentNumber: member.Address.ApartmentNumber,
        postcode: member.Address.Postcode,
        postOffice: member.Address.PostOffice,
      });
    }
  }

  private onIsForeignerChange() {
    this.familyMemberForm
      .get('isForeigner')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isForeigner: boolean) => this.updateInformationAccordingToIsForeignerField(isForeigner));
  }

  private updateInformationAccordingToIsForeignerField(isForeigner: boolean) {
    const peselValidators = !isForeigner ? [Validators.required] : [];

    setFormControlValidators(this.peselControl, peselValidators.concat(this.peselValidators));
    setFormControlValidators(this.documentNumberControl, [Validators.required]);
    this.documentSerialControl.updateValueAndValidity();
    this.peselControl.updateValueAndValidity();
    this.documentNumberControl.updateValueAndValidity();
    this.identityDocumentTypeControl.updateValueAndValidity();
    this.identityDocumentTypeControl.setValue(null);
    this.documentNumberControl.setValue(null);
    this.documentSerialControl.setValue(null);
  }

  private onIdentityDocumentTypeIdChange() {
    this.identityDocumentTypeControl.valueChanges.pipe(
      map<number, IdentityDocumentTypeEnum>((value) => {
        return this.documentValidatorsMap.has(value) ? <IdentityDocumentTypeEnum>value : IdentityDocumentTypeEnum.Undefined;
      }),
    )
      .subscribe((identityDocumentType: IdentityDocumentTypeEnum) => {
        this.documentSerialControl.clearValidators();
        this.documentNumberControl.clearValidators();

        if (!this.isForeginerControl?.value) {
          this.documentSerialControl.setValidators(this.documentValidatorsMap.get(identityDocumentType).serialValidation);
        }
        this.documentNumberControl.setValidators(this.documentValidatorsMap.get(identityDocumentType).numberValidation);
        this.serialLength = this.documentValidatorsMap.get(identityDocumentType).serialLength;
        this.numberLength = this.documentValidatorsMap.get(identityDocumentType).numberLength;

        this.documentSerialControl.updateValueAndValidity();
        this.documentNumberControl.updateValueAndValidity();
      });
  }

  private onPassportDocumentSerialChange() {
    this.documentSerialControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((documentSerial: string) => {
      if (this.identityDocumentTypeControl.value === IdentityDocumentTypeEnum.Passport) {
        const length = documentSerial && documentSerial.length;
        this.numberLength = 9 - length;
        const validators = [Validators.maxLength(this.numberLength)];
        setFormControlValidators(this.documentNumberControl, validators.concat(Validators.required));
        this.documentNumberControl.updateValueAndValidity();
      }
    });
  }

  private onPeselChange() {
    this.familyMemberForm
      .get('pesel')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((pesel: string) => {
          const dateOfBirth = this.familyMemberForm.get('dateOfBirth');

          if (!pesel && dateOfBirth.disabled) dateOfBirth.enable();
          if (!!pesel && dateOfBirth.enabled) dateOfBirth.disable();

          if (pesel?.length < 6) return;

          dateOfBirth.setValue(getBirthDateFromPesel(pesel));

          this.familyMemberForm
            .get('pesel').updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }),
      )
      .subscribe();
  }

  private createRequest(): CreateOrUpdateWorkerFamilyMemberRequest {
    const formValues = this.familyMemberForm.getRawValue();

    return {
      FirstName: formValues.firstName,
      LastName: formValues.lastName,
      Pesel: formValues.pesel,
      KinshipDegreeId: formValues.kinshipDegreeId,
      DateOfBirth: formValues.dateOfBirth,
      IdentityDocumentTypeId: formValues.identityDocumentTypeId,
      DocumentSerial: formValues.documentSerial,
      DocumentNumber: formValues.documentNumber,
      DisabilityLevelId: formValues.disabilityLevelId,
      IsForeigner: formValues.isForeigner,
      Address: this.createAddressRequest(),
    };
  }

  private createAddressRequest() {
    const isStayingInTheSameHousehold = this.familyMemberAddressForm.get('isStayingInTheSameHousehold').value;

    if (isStayingInTheSameHousehold) {
      return {
        IsStayingInTheSameHousehold: isStayingInTheSameHousehold,
      };
    }

    const isPolishAddress: boolean = this.familyMemberAddressForm.get('country').value === Country.Poland;

    return {
      IsStayingInTheSameHousehold: isStayingInTheSameHousehold,
      CountryId: this.familyMemberAddressForm.get('country').value,
      DistrictId: isPolishAddress ? this.familyMemberAddressForm.get('polishAddress.district').value : null,
      DistrictName: !isPolishAddress ? this.familyMemberAddressForm.get('abroadAddress.districtName').value : null,
      PoviatId: isPolishAddress ? this.familyMemberAddressForm.get('polishAddress.poviat').value.Id : null,
      PoviatName: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.poviat').value.Name
        : this.familyMemberAddressForm.get('abroadAddress.poviatName').value,
      CommuneId: isPolishAddress ? this.familyMemberAddressForm.get('polishAddress.commune').value.Id : null,
      CommuneName: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.commune').value.Name
        : this.familyMemberAddressForm.get('abroadAddress.communeName').value,
      CityId: isPolishAddress ? this.familyMemberAddressForm.get('polishAddress.city').value.Id : null,
      CityName: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.city').value.Name
        : this.familyMemberAddressForm.get('abroadAddress.cityName').value,
      StreetId: isPolishAddress ? this.familyMemberAddressForm.get('polishAddress.street').value?.Id : null,
      StreetName: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.street').value?.Name
        : this.familyMemberAddressForm.get('abroadAddress.streetName').value,
      HouseNumber: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.houseNumber').value
        : this.familyMemberAddressForm.get('abroadAddress.houseNumber').value,
      ApartmentNumber: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.apartmentNumber').value
        : this.familyMemberAddressForm.get('abroadAddress.apartmentNumber').value,
      Postcode: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.postcode').value
        : this.familyMemberAddressForm.get('abroadAddress.postcode').value,
      PostOffice: isPolishAddress
        ? this.familyMemberAddressForm.get('polishAddress.postOffice').value
        : this.familyMemberAddressForm.get('abroadAddress.postOffice').value,
    };
  }
}
