import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, debounceTime, firstValueFrom, startWith, takeUntil, tap } from 'rxjs';
import { PresencePerDayListDataSource } from './presence-per-day-list.datasource';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Filter } from 'src/app/models/common/filter';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubSink } from 'SubSink';
import { FilterPresetDto } from 'src/app/models/dtos/filter-preset-dto';
import { UserService } from 'src/app/data/user.service';
import { FilterPresetNameFormDialogComponent } from 'src/app/shared/components/filters/filter-preset-name-form-dialog/filter-preset-name-form-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FilterTypeEnum } from 'src/app/models/enums/filter-type-enum';
import { autocompleteValidator } from 'src/app/shared/validators/autocomplete.validator';
import { PresencePerDayListFiltersComponent } from '../presence-per-day-list-filters/presence-per-day-list-filters.component';
import { buildFilterArray } from 'src/app/common/utils';
import Comparator from 'src/app/common/comparators/comparator';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-presence-per-day-list',
  templateUrl: './presence-per-day-list.component.html',
  styleUrl: './presence-per-day-list.component.scss',
  providers: [DatePipe],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class PresencePerDayListComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentDate = new Date();
  filtersFormGroup: UntypedFormGroup;
  filters: Filter[] = [];
  areFiltersExpanded: boolean = false;
  displayedColumns = [
    'company',
    'employerObject',
    'workerFullName',
    'startDate',
    'endDate',
    'totalTimespan',
    'workModes',
    'absenceType'
  ];

  private subs = new SubSink();

  public readonly defaultFilterPresetName = 'default';
  public filterPresets: FilterPresetDto[];
  private _selectedFilterPresetId: number;
  public set selectedFilterPresetId(value: number) {
    if (this._selectedFilterPresetId !== value) {
      this._selectedFilterPresetId = value;

      if (value) {
        this.applyFilterPreset(this.filterPresets.find(fp => fp.Id === value));
      }
    }
  }

  public get selectedFilterPresetId(): number {
    return this._selectedFilterPresetId;
  }
  public get selectedFilterPreset(): FilterPresetDto {
    return this.filterPresets?.find(fp => fp.Id === this.selectedFilterPresetId);
  }
  public get showFilterPresetSelector(): boolean {
    return this.filterPresets && (this.filterPresets.length > 1 || (this.filterPresets.length === 1 && this.filterPresets[0].Name !== this.defaultFilterPresetName));
  }
  public get isSavedFilterPreset(): boolean {
    return this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName;
  }

  constructor(
    public dataSource: PresencePerDayListDataSource,
    private translateService: TranslateService,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentDate = new Date(this.router.url.replace('presences/', ''));
    this.buildFormGroup();
    this.initFiltersFormChangeObserver();
    this.initLoader();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.restoreSavedFiltersPreset();
    this.filters = buildFilterArray(this.filtersFormGroup, PresencePerDayListFiltersComponent.operatorsMap);
    this.subs.sink = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subs.sink = this.translateService.onLangChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.subs.sink = combineLatest([
      this.sort.sortChange.pipe(startWith(null)),
      this.paginator.page.pipe(startWith(null)),
      this.translateService.onLangChange.pipe(startWith(null))
    ])
      .pipe(tap(() => this.fetchPresences()))
      .subscribe();
  }

  previousDay() {
    this.currentDate = new Date(this.currentDate.addDays(-1));
    this.setNavigation();
    this.fetchPresences();
  }

  nextDay() {
    this.currentDate = new Date(this.currentDate.addDays(1));
    this.setNavigation();
    this.fetchPresences();
  }

  onDateChange(event): void {
    this.currentDate = event.value.toDate();
    this.setNavigation();
    this.fetchPresences();
  }

  async resetFilters(): Promise<void> {
    this.filtersFormGroup.reset(undefined, { emitEvent: false });
    this.filterData([]);

    if (this.selectedFilterPreset && this.selectedFilterPreset.Name !== this.defaultFilterPresetName) {
      await this.deselectFilterPreset();
    }

    this.selectedFilterPresetId = undefined;
    this.upsertFilterPreset();
  }

  filterData(filters: Filter[]) {
    this.filters = filters;
    this.fetchPresences();
  }

  toggleFiltersPanel() {
    this.areFiltersExpanded = !this.areFiltersExpanded;
  }

  public async setSelectedFilterPresetId(value: number): Promise<void> {
    this.selectedFilterPresetId = value;
    await this.filterData(buildFilterArray(this.filtersFormGroup, PresencePerDayListFiltersComponent.operatorsMap));
    await firstValueFrom(this.userService.selectFilterPreset(value));
  }

  public async saveFilterPresetButtonClick(): Promise<void> {
    if (this.isSavedFilterPreset) {

      const existingDefaultPreset = this.filterPresets.find(fp => fp.Name == this.defaultFilterPresetName && this.selectedFilterPresetId !== fp.Id);

      if (existingDefaultPreset) {
        await firstValueFrom(this.userService.deleteFilterPreset(existingDefaultPreset.Id));
      }

      this.selectedFilterPreset.Name = this.defaultFilterPresetName;
      await this.upsertFilterPreset();
    } else {
      var res = await firstValueFrom(this.dialog
        .open(FilterPresetNameFormDialogComponent, { panelClass: 'form-dialog' })
        .afterClosed());

      if (res) {
        if (this.selectedFilterPreset?.Name === this.defaultFilterPresetName) {
          this.selectedFilterPreset.Name = res;
        }
        await this.upsertFilterPreset();
      }
    }
  }

  private initLoader() {
    this.subs.sink = this.dataSource.isLoading$
      .subscribe(isLoading => isLoading ? this.spinner.show() : this.spinner.hide());
  }

  private setNavigation() {
    this.router.navigateByUrl(`presences/${this.datePipe.transform(this.currentDate, 'yyyy/MM/dd')}`);
  }

  private fetchPresences(): void {
    this.dataSource.presencesSubject.next({
      Year: this.currentDate.getFullYear(),
      Month: this.currentDate.getMonth() + 1,
      Day: this.currentDate.getDate(),
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      SortingField: this.sort.active,
      SortingDirection: this.sort.direction,
      Filters: this.filters
    });
  }

  private applyFilterPreset(filterPreset) {
    const filters = JSON.parse(filterPreset?.Object);

    if (filters && Object.values(filters).some((v) => !!v)) {
      this.filtersFormGroup.reset(undefined, { emitEvent: false });
      this.filtersFormGroup.patchValue(filters, { emitEvent: false });
      this.areFiltersExpanded = this.areFiltersExpanded || this.selectedFilterPreset.Name === this.defaultFilterPresetName;
    }
  }

  private async deselectFilterPreset(): Promise<void> {
    await firstValueFrom(this.userService.deselectFilterPreset(this.selectedFilterPresetId));
    this.selectedFilterPreset.IsSelected = false;
  }

  private async upsertFilterPreset(): Promise<void> {
    const selectedPreset = this.filterPresets.find(fp => (this.selectedFilterPresetId && this.selectedFilterPresetId === fp.Id) || fp.Name === this.defaultFilterPresetName);

    const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

    if (selectedPreset) {
      if (filters) {
        await firstValueFrom(this.userService.updateFilterPreset(selectedPreset.Id, {
          Name: selectedPreset.Name,
          FilterPresetObject: filters
        }));
      } else {
        await firstValueFrom(this.userService.deleteFilterPreset(selectedPreset.Id));
      }
    } else if (filters) {
      await firstValueFrom(this.userService.addFilterPreset({
        Name: this.defaultFilterPresetName,
        FilterTypeId: FilterTypeEnum.PresencePerDayList,
        FilterPresetObject: filters
      }));
    } else {
      return;
    }
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.PresencePerDayList));
    this.selectedFilterPresetId = this.filterPresets?.find(fp => fp.IsSelected)?.Id;
  }

  private nonNullValues(obj): { [key: string]: unknown } {
    const properties = Object.entries(obj).filter(([key, value]) => value !== null && value && (!(value instanceof Array) || value.length));
    if (properties.length) {
      return Object.fromEntries(properties);
    }
  }

  private async restoreSavedFiltersPreset() {
    this.filterPresets = await firstValueFrom(this.userService.getFilterPresets(FilterTypeEnum.PresencePerDayList));

    if (this.filterPresets && this.filterPresets.length) {
      const selectedPreset = this.filterPresets.find(fp => fp.IsSelected);

      this.selectedFilterPresetId = selectedPreset?.Id;
    }
  }

  private buildFormGroup(): void {
    this.filtersFormGroup = this.formBuilder.group({
      companyId: [null],
      company: [null, [autocompleteValidator]],
      employerObjectId: [null],
      employerObject: [null, [autocompleteValidator]],
      firstName: [null],
      lastName: [null],
      timeStart: [null],
      timeEnd: [null],
      workModeId: [null],
      absenceTypeId: [null]
    });
  }

  private initFiltersFormChangeObserver() {
    const comparator = new Comparator<any>();

    this.subs.sink = this.filtersFormGroup.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(async () => {
        if (this.filtersFormGroup.invalid) {
          return;
        }

        const filters = this.nonNullValues(this.filtersFormGroup.getRawValue());

        const selectedFilterPresetId = this.filterPresets.find(fp => comparator.equals(JSON.parse(fp.Object), filters))?.Id;

        if (!selectedFilterPresetId) {
          this.selectedFilterPresetId = this.filterPresets.find(fp => fp.Name === this.defaultFilterPresetName)?.Id;

          await this.upsertFilterPreset();
        } else {
          this.selectedFilterPresetId = selectedFilterPresetId;
        }
      });
  }
}