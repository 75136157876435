export enum ModuleName {
  FamilyMembers = 'FamilyMembers',
  Presences = 'Presences',
  Absences = 'Absences',
  Settlements = 'Settlements',
  PaySlips = 'PaySlips',
  Delegations = 'Delegations',
  EmploymentHistory = 'EmploymentHistory',
  EducationHistory = 'EducationHistory',
  WorkingDays = 'WorkingDays',
  Supervisors = 'Supervisors',
  Subordinates = 'Subordinates',
  Timesheets = 'Timesheets',
  Recruiters = 'Recruiters',
  PermanentDisabled = 'PermanentDisabled',
  ApplicationsAndCertificates = 'ApplicationsAndCertificates',
  EmploymentSettings = 'EmploymentSettings',
  SupervisorOnAgreementForm = 'SupervisorOnAgreementForm',
  Legalizations = 'Legalizations',
  Declarations = 'Declarations',
  LogWork = 'LogWork'
}
