import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { EmploymentConditionsConfirmationService } from 'src/app/data/employment-conditions-confirmation.service';
import { Filter } from 'src/app/models/common/filter';
import { EmploymentConditionsConfirmationGridDto } from 'src/app/models/dtos/employment-conditions-confirmation-grid-dto';
import { IPagedResult } from 'src/app/shared/models/PagedResult';
import { GridTableDataSource } from 'src/app/virtual-scroll/data-source';

export class EmploymentConditionsConfirmationListDataSource extends GridTableDataSource<EmploymentConditionsConfirmationGridDto> {
  isLoading$ = new BehaviorSubject<boolean>(false);
  selection = new SelectionModel<EmploymentConditionsConfirmationGridDto>(true, []);
  private cancelationSubject = new Subject();
  private countSubject = new BehaviorSubject<number>(0);
  private _absoluteDataLength: number;

  count$ = this.countSubject.asObservable();

  get isLoading() {
    return this.isLoading$.getValue();
  }

  get absoluteDataLength() {
    return this._absoluteDataLength;
  }

  public get isAllSelected() {
    return this.allData?.length && this.selection.selected?.length === this.allData.length;
  }

  public isSelected(item: EmploymentConditionsConfirmationGridDto): boolean {
    return this.selection.isSelected(item);
  }

  public selectAll(): void {
    this.selection.select(...this.allData);
  }

  public deselectAll(): void {
    this.selection.clear();
  }

  constructor(
    private employmentConditionsConfirmationService: EmploymentConditionsConfirmationService,
    { viewport }: { viewport?: CdkVirtualScrollViewport } = {}
  ) {
    super([], { viewport });
  }

  disconnect() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
  }

  reset() {
    this.cancelationSubject.next(undefined);
    this.isLoading$.next(false);
    this.deselectAll();
    this.allData = [];
  }

  fetch(page: number, count: number, sortingField: string, sortingDirection: string, filters: Filter[]) {
    this.isLoading$.next(true);
    this.employmentConditionsConfirmationService
      .getAll(page, count, sortingField, sortingDirection, filters)
      .pipe(
        takeUntil(this.cancelationSubject),
        catchError(() => of([])),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response: IPagedResult<EmploymentConditionsConfirmationGridDto>) => {
        this._absoluteDataLength = response.Count;
        const isAllSelected = this.isAllSelected;
        this.merge(response.Results, page, count);
        this.keepSelectionState(isAllSelected);
        this.countSubject.next(response.Count);
      });
  }

  private keepSelectionState(isAllSelected: boolean) {
    let old = [...this.selection.selected];
    this.selection.clear();
    this.allData?.forEach((a) => (isAllSelected || old.some((r) => r.EmploymentConditionsConfirmationId === a.EmploymentConditionsConfirmationId)) && this.selection.select(a));
  };

  private merge(data: EmploymentConditionsConfirmationGridDto[], page: number, count: number) {
    const tmp = this.allData.filter((item, index) => {
      return (index < count * (page - 1) || index >= count * page) || data.some(incoming => incoming.EmploymentConditionsConfirmationId === item.EmploymentConditionsConfirmationId)
    });

    data?.map(item => {
      const index = tmp.findIndex(td => td.EmploymentConditionsConfirmationId === item.EmploymentConditionsConfirmationId)
      if (index > -1) {
        tmp[index] = item;
      } else {
        const index = count * (page - 1) + data.indexOf(item);

        if (index > -1) {
          tmp.splice(index, 0, item);
        } else {
          tmp.push(item);
        }
      }
    },
      (err: any) => console.log(err));

    this.allData = tmp;
  }
}
