import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app-config.token';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { AuthService } from '../core/authentication/auth.service';
import { HttpClient } from '@angular/common/http';
import { QrCodeDto } from '../models/dtos/qr-code-dto';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnections: { [key: string]: HubConnection } = {};
  private readonly API_URL = this.config.resourceApiURI + '/signalR';

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  startConnection(hubName: string, groupId?: string): Observable<void> {
    return new Observable<void>((observer) => {
      let hubUrl = `${this.config.resourceApiURI}/${hubName.toLowerCase()}`;

      if (groupId) {
        hubUrl += `?groupId=${groupId}`;
      }

      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl, {
          withCredentials: true,
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();
  
      this.hubConnections[hubName] = hubConnection;
  
      hubConnection
      .start()
      .then(() => {
        const connectionId = hubConnection.connectionId;
        this.saveConnectionId(connectionId, hubName, groupId).subscribe(() => {
          observer.next();
          observer.complete();
        });

        hubConnection.onclose(() => {
          this.removeConnection(hubName, connectionId);
        });
      })
      .catch((error) => {
        observer.error(error);
      });
    });
  }

  removeConnection(hubName: string, connectionId: string): void {
    const hubConnection = this.hubConnections[hubName];
    if (hubConnection) {
      hubConnection.invoke('RemoveConnection', connectionId)
        .then(() => hubConnection.stop())
        .catch(err => console.error(err));
    }
  }

  receiveMessage(): Observable<string> {
    return new Observable<string>((observer) => {
      const chatHub = this.hubConnections['ChatHub'];
      if (chatHub) {
        chatHub.on('TalentinaAnswer', (message: string) => {
          observer.next(message);
        });
      } else {
        observer.error('ChatHub connection not found.');
      }
    });
  }

  sendMessageToChatHub(message: string): void {
    const chatHub = this.hubConnections['ChatHub'];
    if (chatHub) {
      chatHub.invoke('SendMessage', message).catch((err) => console.error(err));
    }
  }

  receiveQrCode(): Observable<QrCodeDto> {
    return new Observable<QrCodeDto>((observer) => {
      const qrCodeHub = this.hubConnections['QrCodeHub'];
      if (qrCodeHub) {
        qrCodeHub.on('QrCode', (qrCode) => {
          const mappedQrCode: QrCodeDto = {
            Code: qrCode.code,
            ActivityStartDate: new Date(qrCode.activityStartDate),
            ActivityEndDate: new Date(qrCode.activityEndDate)
          };
          observer.next(mappedQrCode);
        });
      } else {
        observer.error('QrCodeHub connection not found.');
      }
    });
  }

  isConnected(hubName: string): boolean {
    const hubConnection = this.hubConnections[hubName];
    return hubConnection ? hubConnection.state === HubConnectionState.Connected : false;
  }

  private saveConnectionId(connectionId: string, hubName: string, groupId?: string): Observable<any> {
    let url = `${this.API_URL}/${connectionId}/saveConnectionId?hub=${hubName}`;

    if (groupId) {
      url += `&groupId=${groupId}`;
    }

    return this.http.post<any>(url, { connectionId });
  }
}
