import { WorkModeDto } from './../../../../models/dtos/work-mode-dto';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Subject } from 'rxjs';
import { finalize, first, map, startWith, takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/common/enums/messages';
import { ErrorCode } from 'src/app/common/error-codes/ErrorCode';
import { CreateOrUpdateTimesheetRecordRequest, TimesheetRecordRequest } from 'src/app/contracts/requests/CreateOrUpdateTimesheetRecordRequest';
import { TimesheetService } from 'src/app/data/timesheet.service';
import { ApiResult } from 'src/app/models/ApiResult';
import { InternalWorkerActiveAgreementDto } from 'src/app/models/dtos/InternalWorkerActiveAgreementDto';
import { TimesheetRecord } from 'src/app/models/TimesheetRecord';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { timespanEqualZero, requireCheckboxesToBeCheckedValidator } from './timesheet-modal.validator';
import { WorkModeEnum } from 'src/app/models/enums/work-mode-enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TimesheetDateEditLevelEnum } from '../../../../models/enums/timesheet-date-edit-level';
import { Permission } from 'src/app/common/enums';
import { AuthService } from 'src/app/core/authentication/auth.service';

interface TimesheetModalData {
  WorkerId: number;
  WorkerAgreement: InternalWorkerActiveAgreementDto;
  TimesheetRecord: TimesheetRecord;
  WorkModes: WorkModeDto[];
  TimesheetDateEditLevel: number;
  CanSaveOnlyPresentDay: boolean;
}

export interface TimesheetModalOutput {
  IsSuccess: boolean;
  OpenAgain: boolean;
  RecentStartDate: Date;
  RecentEndDate: Date;
  WorkModeIds: number[];
  TimesheetDateEditLevel: number;
  CanSaveOnlyPresentDay: boolean;
}

@Component({
  selector: 'app-timesheet-modal',
  templateUrl: './timesheet-modal.component.html',
  styleUrls: ['./timesheet-modal.component.scss'],
})
export class TimesheetModalComponent implements OnInit, OnDestroy {
  workModeEnum = WorkModeEnum;
  timesheetRecord: UntypedFormGroup;
  initStartTime: string = '00:00';
  initEndTime: string = '23:59';
  timesheetRecordId: number = 0;
  wokrModeIds: number[] = [];

  private isWorkModeCheckBoxChanged = false;
  private readonly remoteWorkOnDemandDateStart = new Date('2023-04-07T00:00:00');

  @Output() onStartDateChange: EventEmitter<Date> = new EventEmitter();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private timesheetService: TimesheetService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<TimesheetModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TimesheetModalData,
  ) {
    this.timesheetRecordId = this.data.TimesheetRecord.Id;
    if (this.data.TimesheetRecord.StartDate) {
      this.initStartTime = this.getTimeFormat(this.data.TimesheetRecord.StartDate);
    }
    if (this.data.TimesheetRecord.EndDate) {
      this.initEndTime = this.getTimeFormat(this.data.TimesheetRecord.EndDate);
    }
  }

  get workerAgreementId(): number {
    return this.data.WorkerAgreement.Id;
  }
  get startDate(): Date {
    return new Date(this.timesheetRecord.get('startDate').value);
  }
  get startTime(): string {
    return this.timesheetRecord.get('startTime').value;
  }
  get endDate(): Date {
    return new Date(this.timesheetRecord.get('endDate').value);
  }
  get endTime(): string {
    return this.timesheetRecord.get('endTime').value;
  }
  get copyForWorkingMonth(): boolean {
    return this.timesheetRecord.get('copyForWorkingMonth').value;
  }
  get copyForWholeMonth(): boolean {
    return this.timesheetRecord.get('copyForWholeMonth').value;
  }
  get timespanHours() {
    return this.timesheetRecord.get('timespanHours') as UntypedFormControl;
  }
  get timespanMinutes() {
    return this.timesheetRecord.get('timespanMinutes') as UntypedFormControl;
  }
  get homeOfficeOnDemandControl() {
    return this.timesheetRecord.get(`workMode_${WorkModeEnum.HomeOfficeOnDemand}`) as UntypedFormControl;
  }
  get workModes() {
    if (this.startDate < this.remoteWorkOnDemandDateStart && this.data.WorkModes.length <= 2 && this.data.WorkModes.every(workMode => workMode.Id === WorkModeEnum.HomeOfficeOnDemand || workMode.Id === WorkModeEnum.ConcludedHomeOfficeRemote))
      return this.data.WorkModes.filter(workMode => workMode.Id !== WorkModeEnum.HomeOfficeOnDemand && workMode.Id !== WorkModeEnum.ConcludedHomeOfficeRemote);

    return this.data.WorkModes;
  }
  get areWorkModesProvided() {
    return this.workModes && this.workModes.length > 0;
  }
  get areOnlyRemoteWorkModesProvided() {
    return this.workModes.length <= 2 && this.workModes.every(workMode => workMode.Id === WorkModeEnum.HomeOfficeOnDemand || workMode.Id === WorkModeEnum.ConcludedHomeOfficeRemote);
  }
  get hasMultipleWorkModes() {
    return this.workModes && this.workModes.length > 1;
  }
  get timesheetDateEditLevel() {
    return this.data?.TimesheetDateEditLevel;
  }
  get canSaveOnlyPresentDay() {
    return this.data?.CanSaveOnlyPresentDay;
  }

  private _homeOfficeOnDemandLimit: number;
  get homeOfficeOnDemandLimit(): number {
    return this._homeOfficeOnDemandLimit;
  }
  set homeOfficeOnDemandLimit(value: number) {
    this._homeOfficeOnDemandLimit = value;
  }

  async ngOnInit(): Promise<void> {
    await this.buildFormGroup();
    this.addOnStartDateChange();
    this.addOnTimespanChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  submit(openAgain: boolean) {
    if (this.timesheetRecord.hasError('requireCheckboxesToBeChecked')) {
      this.snackBarService.openErrorSnackBar(ErrorCode.ChooseWorkMode);
      return;
    }
    else if (this.timesheetRecord.hasError('timespanZero')) {
      this.snackBarService.openErrorSnackBar(ErrorCode.TimespanCannotBeEqualZero);
      return;
    }

    if (this.timesheetRecord.invalid) return;

    this.spinner.show();

    const callback = (reload: boolean) =>
      this.dialogRef.close({ IsSuccess: reload, OpenAgain: openAgain, RecentStartDate: this.startDate, RecentEndDate: this.endDate, WorkModeIds: this.wokrModeIds });

    const request = this.createTimesheetRecordRequest();

    this.createOrUpdateTimesheetRecord(request, callback);
  }

  onCancel = () => this.dialogRef.close({ IsSuccess: true, OpenAgain: false });

  onWorkModeCheckBoxChange(change: MatCheckboxChange, workMode: WorkModeEnum) {
    if (workMode === WorkModeEnum.HomeOfficeOnDemand && !change.checked && !this.isWorkModeCheckBoxChanged)
      this.homeOfficeOnDemandLimit++;

    this.isWorkModeCheckBoxChanged = true;
  }

  startTimeChanged(input: any = '') {
    this.handleOvernight();
    const startDate = new Date(this.startDate.getTime()).setStringTime(input);
    this.timesheetRecord.patchValue(
      {
        startDate: startDate,
      },
      { onlySelf: true, emitEvent: false },
    );
    this.updateTimespan();
  }

  endTimeChanged(input: any = '') {
    this.handleOvernight();
    const endDate = new Date(this.endDate.getTime()).setStringTime(input);
    this.timesheetRecord.patchValue(
      {
        endDate: endDate,
      },
      { onlySelf: true, emitEvent: false },
    );
    this.updateTimespan();
  }

  isBetweenAgreementDates = (d: Date | null): boolean => {
    const day = new Date(d).resetTime();
    const employmentDateTo = this.data.WorkerAgreement.EmploymentDateTo?.resetTime()

    let isLowerThanEmploymentDateTo = true;
    if (d && !!employmentDateTo) {
      const isAddedForThisDay = !!this.data.TimesheetRecord.Id && this.data.TimesheetRecord.Date.getTime() === new Date(d).getTime();
      employmentDateTo.resetTime().addDays(+isAddedForThisDay);

      isLowerThanEmploymentDateTo = day <= employmentDateTo;
    }

    return day >= this.data.WorkerAgreement.EmploymentDateFrom.resetTime() && isLowerThanEmploymentDateTo;
  };

  toggleCopyForWorkingMonth() {
    this.timesheetRecord.patchValue(
      {
        copyForWorkingMonth: false,
      },
      { emitEvent: false },
    );
  }

  toggleCopyForWholeMonth() {
    this.timesheetRecord.patchValue(
      {
        copyForWholeMonth: false,
      },
      { emitEvent: false },
    );
  }

  canEditTimesheetDates() {
    return this.timesheetDateEditLevel === TimesheetDateEditLevelEnum.All ||
      this.timesheetDateEditLevel === TimesheetDateEditLevelEnum.OnlyOthers &&
      (this.authService.hasPermission(Permission.ManageMyExternalWorkersTimesheet) || this.authService.hasPermission(Permission.ManageAllExternalWorkersTimesheet));
  }

  private createOrUpdateTimesheetRecord(request: CreateOrUpdateTimesheetRecordRequest, callback: (reload: boolean) => void) {
    const action$ =
      this.timesheetRecordId === 0 ? this.timesheetService.createTimesheetRecord(request) : this.timesheetService.updateTimesheetRecord(request);
    const message = this.timesheetRecordId === 0 ? Messages.SuccessfullyCreatedTimesheet : Messages.SuccessfullyUpdatedTimesheet;

    action$
      .pipe(
        first(),
        finalize(() => this.spinner.hide()),
      )
      .subscribe((results: ApiResult<any>[]) => {
        if (results.some((r) => r.IsFailure)) {
          this.processErrorMessages(results);
        } else {
          this.snackBarService.openSuccessSnackBar(message);
        }
        callback(results.some((r) => r.IsSuccess));
      });
  }

  private processErrorMessages(results: ApiResult<any>[]) {
    const onlyLetters = new RegExp('^[a-zA-Z]+$');
    const errors = results.filter((r) => r.IsFailure).map((r: ApiResult<any>) => r.Error.split(','));
    const distinctErrors = [...new Set(errors.reduce((a, b) => a.concat(b), []))];
    this.snackBarService.openErrorMultipleSnackBarInRow(distinctErrors.filter(e => onlyLetters.test(e)));
  }

  private updateTimespan() {
    const hours = this.startDate.calculateTimespanInHours(this.endDate);
    const minutes = this.startDate.calculateTimespanInHours(this.endDate) % 1;

    this.timesheetRecord.patchValue(
      {
        timespanHours: Math.floor(hours),
        timespanMinutes: Math.round(minutes * 60),
      },
      { onlySelf: true, emitEvent: false },
    );
  }

  private handleOvernight() {
    const start = new Date().setStringTime(this.startTime);
    const end = new Date().setStringTime(this.endTime);

    const isOvernight = start > end;

    if (isOvernight && this.startDate.getDate() === this.endDate.getDate()) {
      const endDate = this.endDate.addDays(1);

      this.timesheetRecord.patchValue(
        {
          endDate: endDate,
        },
        { onlySelf: true, emitEvent: false },
      );
    }

    if (!isOvernight && this.startDate.getDate() !== this.endDate.getDate()) {
      const endDate = this.endDate.addDays(-1);

      this.timesheetRecord.patchValue(
        {
          endDate: endDate,
        },
        { onlySelf: true, emitEvent: false },
      );
    }
  }

  private async buildFormGroup() {
    this.timesheetRecord = this.formBuilder.group(
      {
        startDate: [new Date(), Validators.required],
        startTime: [this.initStartTime, Validators.required],
        endDate: [{ value: new Date(), disabled: true }, Validators.required],
        endTime: [this.initEndTime, Validators.required],
        timespanHours: [0, [Validators.required, Validators.min(0), Validators.max(23)]],
        timespanMinutes: [0, [Validators.required, Validators.min(0), Validators.max(59)]],
        copyForWorkingMonth: [false],
        copyForWholeMonth: [false],
      }
    );

    if (this.data.TimesheetRecord) {
      this.timesheetRecord.patchValue({
        startDate: this.data.TimesheetRecord.StartDate ?? this.data.TimesheetRecord.Date,
        startTime: this.initStartTime,
        endDate: this.data.TimesheetRecord.EndDate ?? this.data.TimesheetRecord.Date,
        endTime: this.initEndTime,
        timespanHours: Math.floor(this.data.TimesheetRecord.Timespan),
        timespanMinutes: ((this.data.TimesheetRecord.Timespan % 1) * 60).toFixed(0),
      });
      await this.buildCheckBoxFormGroup();
    }
  }

  private async buildCheckBoxFormGroup() {
    this.timesheetRecord.removeValidators(requireCheckboxesToBeCheckedValidator);
    this.timesheetRecord.removeValidators(timespanEqualZero);
    this.removeRemoteWorkFormGroup();

    if (this.areWorkModesProvided) {
      this.workModes?.forEach((wm) => {
        const c = this.formBuilder.control(false);
        this.timesheetRecord.addControl(`workMode_${wm.Id}`, c);
      });

      this.initWorkModes();
      await this.getHomeOfficeOnDemandLimit();
      this.timesheetRecord.addValidators(requireCheckboxesToBeCheckedValidator);
    }

    if (!this.areWorkModesProvided || this.areOnlyRemoteWorkModesProvided) {
      this.timesheetRecord.addValidators(timespanEqualZero);
    }

    this.setRemoteWorkOnDemandState();
  }

  private removeRemoteWorkFormGroup() {
    const workModes = Object.values(WorkModeEnum);
    for (let workMode in workModes)
      this.timesheetRecord.removeControl(`workMode_${workMode}`);
  }

  private addOnStartDateChange() {
    this.timesheetRecord.get('startDate').valueChanges.subscribe((value) => {
      const startDate = new Date(value).setStringTime(this.startTime);
      let endDate = startDate.setStringTime(this.endTime);
      const isOvernight = startDate > endDate;
      endDate = endDate.addDays(isOvernight ? 1 : 0);

      this.timesheetRecord.patchValue(
        {
          startDate: startDate,
          endDate: endDate,
        },
        { emitEvent: false },
      );

      this.buildCheckBoxFormGroup();
      this.onStartDateChange.emit(startDate);
    });
  }

  private addOnTimespanChange() {
    combineLatest([
      this.timespanHours.valueChanges.pipe(startWith(this.timespanHours.value)),
      this.timespanMinutes.valueChanges.pipe(startWith(this.timespanMinutes.value)),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([timespanHours, timespanMinutes]) => [+timespanHours, +timespanMinutes]),
      )
      .subscribe(([timespanHours, timespanMinutes]) => {
        const date = new Date(this.startDate.getTime());

        date.setHours(date.getHours() + timespanHours);
        date.setMinutes(date.getMinutes() + timespanMinutes);

        this.timesheetRecord.patchValue({
          endDate: date,
          endTime: this.getTimeFormat(date),
        });
      });
  }

  private createTimesheetRecordRequest(): CreateOrUpdateTimesheetRecordRequest {
    const arr: TimesheetRecordRequest[] = [];
    const loopStop = this.copyForWorkingMonth || this.copyForWholeMonth ? this.startDate.getLastDayOfMonth() : this.startDate;

    let counter = this.startDate.resetTime();
    let start = new Date(this.startDate.getTime());
    let end = new Date(this.endDate.getTime());

    const increment = () => {
      start = start.addDays(1);
      end = end.addDays(1);
      counter = counter.addDays(1);
    };

    if (this.canSaveOnlyPresentDay) {
      arr.push(this.createSingleTimesheetRecordRequest(this.timesheetRecordId, start, end));
    }
    else {
      while (counter <= loopStop) {
        if (this.copyForWorkingMonth && (counter.getDay() === 0 || counter.getDay() == 6) && counter.getDate() !== this.startDate.getDate()) {
          increment();
          continue;
        }

        if (start.getDate() === end.getDate()) {
          arr.push(this.createSingleTimesheetRecordRequest(this.timesheetRecordId, start, end));
        } else {
          const midnight = end.resetTime();
          arr.push(this.createSingleTimesheetRecordRequest(this.timesheetRecordId, start, midnight));

          if (midnight.getTime() !== end.getTime()) {
            arr.push(this.createSingleTimesheetRecordRequest(null, midnight, end));
          }
        }

        increment();
      }
    }


    return {
      WorkerId: this.data.WorkerId,
      WorkerAgreementId: this.data.WorkerAgreement.Id,
      TimesheetRecordRequests: arr,
    };
  }

  private createSingleTimesheetRecordRequest(timesheetRecordId: number, start: Date, end: Date): TimesheetRecordRequest {
    this.getWorkModesRequest()

    return {
      TimesheetRecordId: timesheetRecordId,
      StartDate: start,
      EndDate: end,
      Month: start.getMonth() + 1,
      Year: start.getFullYear(),
      WorkModeIds: this.wokrModeIds
    };
  }

  private getTimeFormat(date: Date) {
    return `${date.getHours()}:${date.getMinutes()}`;
  }

  private getWorkModesRequest(): void {
    this.workModes?.forEach((wm) => {
      if (this.timesheetRecord.get(`workMode_${wm.Id}`)?.value) {
        if (!this.wokrModeIds.includes(wm.Id))
          this.wokrModeIds.push(wm.Id)
      };
    });
  }

  private async getHomeOfficeOnDemandLimit() {
    if (this.workModes.some(wm => wm.Id === WorkModeEnum.HomeOfficeOnDemand) && this.homeOfficeOnDemandLimit === undefined)
      this.homeOfficeOnDemandLimit = await this.timesheetService.getHomeOfficeOnDemandLimit(this.workerAgreementId, this.startDate.getFullYear()).toPromise();
  }

  private setRemoteWorkOnDemandState() {
    if (!this.homeOfficeOnDemandControl)
      return;

    if (this.homeOfficeOnDemandLimit || this.homeOfficeOnDemandControl.value)
      this.homeOfficeOnDemandControl.enable();
    else
      this.homeOfficeOnDemandControl.disable();
  }

  initWorkModes(): void {
    if (!this.timesheetRecord) return;

    this.data.TimesheetRecord?.WorkModes?.forEach((wm) => {
      this.timesheetRecord.get(`workMode_${wm.Id}`)?.setValue(true, { emitEvent: false });
    });
  }
}